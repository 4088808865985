// @ts-ignore
import linkifyHtml from 'linkify-html';
import xss from 'xss';
import slugify from "slugify";


export const replaceStringLineBreakByBr = (input: string): string => {
    return input?.replace(/(?:\r\n|\r|\n)/g, '<br/>');
};
export const htmliFy = (value: string,noBrReplace:boolean=false): string => {
    let html = linkifyHtml(value, {
        defaultProtocol: 'https',
    });
    if(!noBrReplace) {
        html = replaceStringLineBreakByBr(html);
    }
    return xss(html, {
        whiteList: {
            a: ['href', 'title', 'target'],
            br: []
        }
    });
};

export const formatDate=(date:Date)=>{
    return date.toLocaleDateString(undefined, {year: 'numeric', month: 'numeric', day: 'numeric'});
}
export function stringToURL(s:string, defaultString: string):string {
  const result = slugify(s);
  return result ? result : defaultString;
}
