import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
/** Composant qui affiche les boutons de navigation */
import { Component, Prop, Vue } from "vue-facing-decorator";
let StandardButton = class StandardButton extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "label", void 0);
    _defineProperty(this, "disabled", void 0);
    _defineProperty(this, "outlined", void 0);
    _defineProperty(this, "loading", void 0);
    _defineProperty(this, "verySmallMargins", void 0);
    _defineProperty(this, "expanded", void 0);
  }
  click() {
    if (!this.disabled) {
      this.$emit("action");
    }
  }
  get buttonSubClass() {
    let subClass = '';
    if (this.disabled) {
      subClass += '_disabled';
    }
    if (this.outlined) {
      subClass += ' _buttonLight';
    } else {
      subClass += ' _themeButton';
    }
    if (this.expanded) {
      subClass += ' _fullWidth';
    }
    return subClass;
  }
  get marginsClass() {
    if (this.verySmallMargins) {
      return '_withVerySmallMargin';
    } else if (this.expanded) {
      return '_withFlexibleMargin';
    } else {
      return '_withSmallMargin';
    }
  }
};
__decorate([Prop({
  required: false
})], StandardButton.prototype, "label", void 0);
__decorate([Prop({
  default: false
})], StandardButton.prototype, "disabled", void 0);
__decorate([Prop({
  default: false
})], StandardButton.prototype, "outlined", void 0);
__decorate([Prop({
  default: false
})], StandardButton.prototype, "loading", void 0);
__decorate([Prop({
  default: false
})], StandardButton.prototype, "verySmallMargins", void 0);
__decorate([Prop({
  default: false
})], StandardButton.prototype, "expanded", void 0);
StandardButton = __decorate([Component({
  emits: ["action"]
})], StandardButton);
export default StandardButton;