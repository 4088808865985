import {ElLoading, ElMessage, ElMessageBox} from "element-plus";


export const confirmation = (message: string, title: string, confirmText: string, cancelText: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    ElMessageBox.confirm(
        message,
        title,
        {
          confirmButtonText: confirmText,
          cancelButtonText: cancelText,
        }
    ).then(() => {
      resolve();
    }).catch(() => {
      reject()
    })
  });
}

export const alertError= (message: string):void => {
    ElMessage.error(message);
}

export const loadingFullscreen=()=>{
  ElLoading.service({
    lock: true,
    text: '',
    background: 'rgba(255, 255, 255, 0.7)',
  })
}
