import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-61dd464d"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = ["id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$graphs$, _ctx$graphs$2;
  const _component_DashBoardLineChart = _resolveComponent("DashBoardLineChart");
  const _component_empty_chart = _resolveComponent("empty-chart");
  return _openBlock(), _createElementBlock("div", null, [_withDirectives(_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
    id: _ctx.legendTooltipId,
    class: "legendTooltip"
  }, null, 8, _hoisted_2), _createVNode(_component_DashBoardLineChart, {
    graphs: _ctx.graphs,
    height: _ctx.height,
    "legend-tooltip-id": _ctx.legendTooltipId,
    title: _ctx.title,
    width: _ctx.width,
    cumulative: _ctx.cumulative
  }, null, 8, ["graphs", "height", "legend-tooltip-id", "title", "width", "cumulative"])], 512), [[_vShow, (_ctx$graphs$ = _ctx.graphs[0]) === null || _ctx$graphs$ === void 0 || (_ctx$graphs$ = _ctx$graphs$.data) === null || _ctx$graphs$ === void 0 ? void 0 : _ctx$graphs$.length]]), _withDirectives(_createVNode(_component_empty_chart, {
    loading: _ctx.loading,
    url: _ctx.big ? require('/public/images/charts/Line-Large.png') : require('/public/images/charts/Line-Small.png')
  }, null, 8, ["loading", "url"]), [[_vShow, !((_ctx$graphs$2 = _ctx.graphs[0]) !== null && _ctx$graphs$2 !== void 0 && (_ctx$graphs$2 = _ctx$graphs$2.data) !== null && _ctx$graphs$2 !== void 0 && _ctx$graphs$2.length)]])]);
}