export interface DashboardTableModel {
  column: string;
  key: string;
  width: string;
}


export interface Graph {
  legend: string;
  tooltipLegend: string;
  rgbaColor: string;
  data: TimeSerie[];
}

export interface NameValue {
  name:string;
  value:number;
}

export interface TimeSerie{
  startDate: Date;
  endDate: Date;
  value:string;
}

export interface SpeachValue{
  publishDate:Date;
  speachTitle:string;
  publishDateFormatted:string;
}
export interface SpeachWatch extends SpeachValue{
  nbWatch:number;
}
export interface SpeachRating extends SpeachValue{
  note:number;
}
export interface SpeachMakerValue{
  firstName:string;
  lastName:string;
  speachmaker:string;
}
export interface SpeachMakerWatch extends SpeachMakerValue{
  nbWatch:number;
  nbSpeach:number;
}
export interface SpeachMakerNote extends SpeachMakerValue{
  note:number;
  nbNote:number;
}

/**
 * Enumération permettant de savoir le nombre de semaines à aggreger
 * en fonction de la période (3,6,12 mois) à afficher.
 */
 export enum Period {
  ONE_MONTH='ONE_MONTH', THREE_MONTHS='THREE_MONTHS', SIX_MONTHS='SIX_MONTHS', TWELVE_MONTHS='TWELVE_MONTHS', TWO_YEARS='TWO_YEARS'
 }

 export enum Metric{
   READ='READ',
    UNIQUE_READ='UNIQUE_READ',
    UNIQUE_ACTIVITY='UNIQUE_ACTIVITY',
    PUBLICATION='PUBLICATION',
    CREATION='CREATION',
    GRADE='GRADE',
    COMMENT='COMMENT'
 }
