import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-087bed3c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "dashboard-container",
  "data-test": "dashboard-container"
};
const _hoisted_2 = {
  class: "topContainer"
};
const _hoisted_3 = {
  class: "selectors"
};
const _hoisted_4 = {
  class: "periodSelection"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_radio_button = _resolveComponent("el-radio-button");
  const _component_el_radio_group = _resolveComponent("el-radio-group");
  const _component_w_select = _resolveComponent("w-select");
  const _component_standard_button = _resolveComponent("standard-button");
  const _component_export_csv = _resolveComponent("export-csv");
  const _component_w_dialog = _resolveComponent("w-dialog");
  const _component_dashboard_speaches_tab = _resolveComponent("dashboard-speaches-tab");
  const _component_dashboard_users_tab = _resolveComponent("dashboard-users-tab");
  const _component_dashboard_social_tab = _resolveComponent("dashboard-social-tab");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", null, [_createVNode(_component_el_radio_group, {
    modelValue: _ctx.activeRadio,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.activeRadio = $event),
    fill: "var(--themeMainColor)"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_radio_button, {
      value: _ctx.DashboardEnum.SPEACHES
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('admin.dashboard.radio.speaches')), 1)]),
      _: 1
    }, 8, ["value"]), _createVNode(_component_el_radio_button, {
      value: _ctx.DashboardEnum.USERS
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('admin.dashboard.radio.users')), 1)]),
      _: 1
    }, 8, ["value"]), _ctx.showSocial ? (_openBlock(), _createBlock(_component_el_radio_button, {
      key: 0,
      value: _ctx.DashboardEnum.SOCIAL
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('admin.dashboard.radio.social')), 1)]),
      _: 1
    }, 8, ["value"])) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["modelValue"])]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_w_select, {
    "value-key": "id",
    options: _ctx.periodOptions,
    size: "small",
    onChange: _ctx.changeSelectedPeriod,
    value: _ctx.selectedPeriod,
    "data-test": "dashboard-period-selection"
  }, null, 8, ["options", "onChange", "value"])])]), _createElementVNode("div", null, [_createVNode(_component_standard_button, {
    class: "downloadButton",
    label: _ctx.$t('admin.dashboard.download.csv'),
    onAction: _cache[1] || (_cache[1] = $event => _ctx.exportCsv = true)
  }, null, 8, ["label"]), _createVNode(_component_w_dialog, {
    visible: _ctx.exportCsv,
    "onUpdate:visible": _cache[4] || (_cache[4] = $event => _ctx.exportCsv = $event),
    width: "600px",
    title: _ctx.$t('admin.dashboard.download.title'),
    onOnDialogClose: _cache[5] || (_cache[5] = $event => _ctx.exportCsv = false)
  }, {
    footer: _withCtx(() => [_createVNode(_component_standard_button, {
      label: _ctx.$t('admin.dashboard.download.cancel'),
      onAction: _cache[3] || (_cache[3] = $event => _ctx.exportCsv = false),
      outlined: true
    }, null, 8, ["label"])]),
    default: _withCtx(() => [_createVNode(_component_export_csv, {
      "enable-scroll": true,
      onClosePopup: _cache[2] || (_cache[2] = $event => _ctx.exportCsv = false)
    })]),
    _: 1
  }, 8, ["visible", "title"])])]), _ctx.activeRadio == _ctx.DashboardEnum.SPEACHES ? (_openBlock(), _createBlock(_component_dashboard_speaches_tab, {
    key: 0,
    "selected-period": _ctx.selectedPeriod
  }, null, 8, ["selected-period"])) : _createCommentVNode("", true), _ctx.activeRadio == _ctx.DashboardEnum.USERS ? (_openBlock(), _createBlock(_component_dashboard_users_tab, {
    key: 1,
    "selected-period": _ctx.selectedPeriod
  }, null, 8, ["selected-period"])) : _createCommentVNode("", true), _ctx.activeRadio == _ctx.DashboardEnum.SOCIAL ? (_openBlock(), _createBlock(_component_dashboard_social_tab, {
    key: 2,
    "selected-period": _ctx.selectedPeriod
  }, null, 8, ["selected-period"])) : _createCommentVNode("", true)]);
}