import axios from "axios";
import delay from "delay";


export const waitingJob=(jobID:string,interval=2000):Promise<any>=>{
  return new Promise((resolve,reject)=>{
    axios.get('/recorder/jobID/' + jobID).then(async (resp) => {
      if (resp.data === "ERROR") {
        reject();
      } else {
        if ((resp.data+"").length > 0) {

          resolve(resp.data);
        } else {
          await delay(interval);
          resolve(await waitingJob(jobID));
        }
      }
    }).catch((e) => {
      reject();
      console.error(e);
    })
  });
}
