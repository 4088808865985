import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import { __decorate } from "tslib";
import UserCell from "@/components/user/UserCell.vue";
import StandardButton from "@/components/buttons/StandardButton.vue";
import { goToUser, goToUserAnalytics } from "@/util/routeUtil";
import WCheckboxGroup from "@/components/form/w-checkbox-group.vue";
import axios from "axios";
import WElMessage from "@/components/wrappers/w-elmessage.vue";
import WInput from "@/components/form/w-input.vue";
import WTag from "@/components/wrappers/w-tag.vue";
import Icon from "@/components/icon/Icon.vue";
import WDialog from "@/components/wrappers/w-dialog.vue";
import WSelectAdvanced from "@/components/form/w-select-advanced.vue";
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
let UserDetails = class UserDetails extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "user", void 0);
    _defineProperty(this, "workflowEnabled", void 0);
    _defineProperty(this, "classClickFilter", void 0);
    _defineProperty(this, "userPasswordIsEditable", false);
    _defineProperty(this, "userPasswordMinChars", void 0);
    _defineProperty(this, "userPasswordMaxChars", void 0);
    _defineProperty(this, "roleAdmin", 'admin');
    _defineProperty(this, "roleAuthor", 'author');
    _defineProperty(this, "roleApprover", 'approver');
    _defineProperty(this, "forcePasswordChange", 'forcePasswordChange');
    _defineProperty(this, "BASE_API_USERS", '/api/v2/admin/users');
    _defineProperty(this, "groups", []);
    _defineProperty(this, "selectedGroups", []);
    _defineProperty(this, "availableCustomFields", []);
    _defineProperty(this, "resetPasswordPopup", false);
    _defineProperty(this, "deactivateUserPopup", false);
    _defineProperty(this, "passwordField", '');
  }
  mounted() {
    this.refreshUser();
  }
  refreshUser() {
    this.initGroups();
    this.initCustomFields();
  }
  initGroups() {
    this.groups = [];
    this.selectedGroups = this.user.groups.filter(group => !group.system).map(group => group.id);
    axios.get(this.BASE_API_USERS + '/groups').then(response => {
      this.groups = response.data.sort((a, b) => {
        if (a.system) {
          return -1;
        } else {
          if (b.system) {
            return 1;
          } else {
            return 0;
          }
        }
      });
    });
  }
  get displayableGroups() {
    return this.groups.filter(group => !group.system);
  }
  initCustomFields() {
    this.availableCustomFields = [];
    axios.get(this.BASE_API_USERS + '/getAvailableCustomFields').then(response => {
      for (const customField of response.data) {
        let customFieldValue = {
          customField: customField,
          value: ''
        };
        const existingUserCustomFieldValue = this.user.customFieldValues.find(value => value.customField.id === customField.id);
        if (existingUserCustomFieldValue) {
          customFieldValue.value = existingUserCustomFieldValue.value;
        }
        this.availableCustomFields.push(customFieldValue);
      }
    });
  }
  getTranslatedCustomFieldLabel(customField) {
    let customFieldLabel = customField.labelEn;
    if (this.$i18n.locale.toLowerCase() === 'de') {
      customFieldLabel = customField.labelDe;
    } else if (this.$i18n.locale.toLowerCase() === 'es') {
      customFieldLabel = customField.labelEs;
    } else if (this.$i18n.locale.toLowerCase() === 'fr') {
      customFieldLabel = customField.labelFr;
    }
    return customFieldLabel;
  }
  get lastActiveLabel() {
    if (!this.user.lastActiveDate) {
      return '';
    }
    return this.$t('admin.dashboard.users.sidebar.lastActive', {
      date: this.$d(new Date(this.user.lastActiveDate))
    });
  }
  get possibleRoles() {
    let roles = [{
      name: this.$t('admin.dashboard.users.role.administrator'),
      key: this.roleAdmin
    }, {
      name: this.$t('admin.dashboard.users.role.author'),
      key: this.roleAuthor
    }];
    if (this.workflowEnabled) {
      roles.push({
        name: this.$t('admin.dashboard.users.role.approver'),
        key: this.roleApprover
      });
    }
    return roles;
  }
  get passwordOptions() {
    return [{
      name: this.$t('admin.dashboard.users.sidebar.passwordReset.forcePasswordUpdate'),
      key: this.forcePasswordChange
    }];
  }
  get userRoles() {
    const currentRoles = [];
    if (this.user.admin) {
      currentRoles.push(this.roleAdmin);
    }
    if (this.user.trainer) {
      currentRoles.push(this.roleAuthor);
    }
    if (this.workflowEnabled && this.user.approver) {
      currentRoles.push(this.roleApprover);
    }
    return currentRoles;
  }
  goToUser() {
    goToUser(this.user);
  }
  goToUserAnalytics() {
    goToUserAnalytics(this.user);
  }
  onChangeOneRole(changed, label, value) {
    if (value === this.roleAdmin) {
      this.user.admin = !!changed;
    }
    if (value === this.roleAuthor) {
      this.user.trainer = !!changed;
    }
    if (value === this.roleApprover) {
      this.user.approver = !!changed;
    }
    this.updateUser();
  }
  onChangePasswordOption(changed, label, value) {
    if (value === this.forcePasswordChange) {
      axios.put(this.BASE_API_USERS + '/updatePassword', null, {
        params: {
          dataType: "json",
          userID: this.user.userID,
          value: !!changed
        }
      }).then(() => {
        WElMessage.showSuccessMessage(this.$t("admin.dashboard.users.sidebar.passwordReset.onChangePasswordReset"));
      }, error => {
        this.displayErrorMsg(error);
      });
    }
  }
  updateUser() {
    axios.put(this.BASE_API_USERS + '/update', {
      user: JSON.stringify(this.user)
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(() => {
      WElMessage.showSuccessMessage(this.$t("admin.dashboard.users.sidebar.userUpdated"));
    }, error => {
      this.displayErrorMsg(error);
    });
  }
  updateUserPassword() {
    if (this.passwordField.length && this.isPasswordLengthOkay()) {
      axios.put(`${this.BASE_API_USERS}/${this.user.id}/password`, null, {
        params: {
          dataType: "json",
          password: this.passwordField
        }
      }).then(response => {
        WElMessage.showSuccessMessage(response.data);
        this.resetPasswordPopup = false;
        this.passwordField = '';
      }, error => {
        this.displayErrorMsg(error);
      });
    }
  }
  isPasswordLengthOkay() {
    if (this.passwordField.length < this.userPasswordMinChars) {
      WElMessage.showErrorMessage(this.$t('admin.dashboard.users.sidebar.passwordReset.onPasswordToShort', {
        nbCharacters: this.userPasswordMinChars
      }).toString());
      return false;
    } else if (this.passwordField.length > this.userPasswordMaxChars) {
      WElMessage.showErrorMessage(this.$t('admin.dashboard.users.sidebar.passwordReset.onPasswordToLong', {
        nbCharacters: this.userPasswordMaxChars
      }).toString());
      return false;
    }
    return true;
  }
  onChangeFirstName(value) {
    this.user.firstName = value;
    this.updateUser();
  }
  onChangeLastName(value) {
    this.user.lastName = value;
    this.updateUser();
  }
  onChangeEmail(value) {
    this.user.email = value;
    this.updateUser();
  }
  onChangeUserCustomFieldValue(userCustomField, value) {
    let newCF = true;
    this.user.customFieldValues.forEach(userCF => {
      if (userCF.customField.id === userCustomField.customField.id) {
        userCF.value = value;
        newCF = false;
      }
    });
    if (newCF) {
      userCustomField.value = value;
      this.user.customFieldValues.push(userCustomField);
    }
    this.updateUser();
  }
  changeSelectedGroups(newGroups) {
    if (this.groups.length > 0) {
      this.selectedGroups = newGroups;
      this.user.groups = this.groups.filter(group => this.selectedGroups.includes(group.id) || group.system);
      this.updateUser();
    }
  }
  deleteOneGroup(groupToDelete) {
    let currentGroups = this.user.groups || [];
    currentGroups = currentGroups.filter(group => group !== groupToDelete && !group.system);
    this.user.groups = currentGroups;
    this.selectedGroups = this.user.groups.map(group => group.id);
    this.updateUser();
  }
  generatePassword() {
    axios.put(this.BASE_API_USERS + '/generatePassword', null, {
      params: {
        dataType: "json",
        email: this.user.email
      }
    }).then(response => {
      WElMessage.showSuccessMessage(response.data);
      this.resetPasswordPopup = false;
    }, error => {
      this.displayErrorMsg(error);
    });
  }
  deactivateUser() {
    axios.put(this.BASE_API_USERS + '/deactivate', {
      uuids: [this.user.userID]
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(() => {
      WElMessage.showSuccessMessage(this.$t("admin.dashboard.users.sidebar.userDeactivated"));
    }, error => {
      this.displayErrorMsg(error);
    }).finally(() => {
      this.deactivateUserPopup = false;
      this.$emit('reinitSearch');
    });
  }
  displayErrorMsg(error) {
    var _error$message;
    if ((_error$message = error.message) !== null && _error$message !== void 0 && _error$message.length) {
      WElMessage.showErrorMessage(error.message);
    } else {
      WElMessage.showErrorMessage(this.$t('errors.default'));
    }
  }
};
__decorate([Prop({
  required: true
})], UserDetails.prototype, "user", void 0);
__decorate([Prop({
  default: false
})], UserDetails.prototype, "workflowEnabled", void 0);
__decorate([Prop({
  default: ''
})], UserDetails.prototype, "classClickFilter", void 0);
__decorate([Prop()], UserDetails.prototype, "userPasswordIsEditable", void 0);
__decorate([Prop({
  required: true
})], UserDetails.prototype, "userPasswordMinChars", void 0);
__decorate([Prop({
  required: true
})], UserDetails.prototype, "userPasswordMaxChars", void 0);
__decorate([Watch('user')], UserDetails.prototype, "refreshUser", null);
UserDetails = __decorate([Component({
  components: {
    WDialog,
    Icon,
    WSelectAdvanced,
    WTag,
    WInput,
    WCheckboxGroup,
    StandardButton,
    UserCell
  },
  emits: ['reinitSearch']
})], UserDetails);
export default UserDetails;