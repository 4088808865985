import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
let UnsubscribeEmailNotificationSuccess = class UnsubscribeEmailNotificationSuccess extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "displayGoToHomeBtn", false);
  }
  /**
   * Go to home page
   * @private
   */
  goToHome() {
    window.location.assign('/');
  }
};
__decorate([Prop()], UnsubscribeEmailNotificationSuccess.prototype, "displayGoToHomeBtn", void 0);
UnsubscribeEmailNotificationSuccess = __decorate([Component({})], UnsubscribeEmailNotificationSuccess);
export default UnsubscribeEmailNotificationSuccess;