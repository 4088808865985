import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { __decorate } from "tslib";
import WDialog from "@/components/wrappers/w-dialog.vue";
import StandardButton from "@/components/buttons/StandardButton.vue";
import TitlePopUp from "@/components/text/TitlePopUp.vue";
import axios from "axios";
import BigButton from "@/components/buttons/BigButton.vue";
import LegacyVideoChapterPlayer from "@/components/video/LegacyVideoChapterPlayer.vue";
import Icon from "@/components/icon/Icon.vue";
import MediaRecorder from "@/components/recorder/MediaRecorder.vue";
import { Events } from "@/events/events";
import LightButton from "@/components/buttons/LightButton.vue";
import prettyMilliseconds from "pretty-ms";
import WInput from "@/components/form/w-input.vue";
import { confirmation } from "@/util/dialog";
import WSelect from "@/components/form/w-select.vue";
import delay from "delay";
import Loader from "@/components/loader/Loader.vue";
import { Component, Emit, Prop, Vue } from "vue-facing-decorator";
let VoiceOver = class VoiceOver extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "urlPlayer", void 0);
    _defineProperty(this, "chapterId", void 0);
    _defineProperty(this, "generateVoiceEnabled", void 0);
    // is the voiceOverComponent is open /visiible
    _defineProperty(this, "voiceOverOpen", false);
    _defineProperty(this, "durationVideo", 0);
    // the list of subtitles available for this chapter
    _defineProperty(this, "listOfSubtitles", []);
    // current step in the step flow
    // 0-9 => voiceAI
    // 10+ => microphone recording
    _defineProperty(this, "currentStep", 0);
    // the language selected for the voice AI
    _defineProperty(this, "languageSelectedId", null);
    // coundown value before a record
    _defineProperty(this, "countDown", -1);
    // current time recorded when record with mediaRecorder
    _defineProperty(this, "timeRecorded", 0);
    // blob from microphone record
    _defineProperty(this, "blobRecorded", null);
    // url of the blob
    _defineProperty(this, "urlBlob", null);
    // state of the record
    _defineProperty(this, "isRecording", false);
    _defineProperty(this, "waitingTranscoding", false);
    _defineProperty(this, "textToGenerate", "");
    _defineProperty(this, "timeoutWaitingTextGeneration", 0);
    _defineProperty(this, "jobIdVoicePreview", "");
    _defineProperty(this, "loadingVoiceFromText", false);
    _defineProperty(this, "isRecordingPause", false);
    _defineProperty(this, "langTxtSelected", '');
  }
  /**
   * Format the time recorded in semicolon style 00:00
   */
  get timeRecordedFormat() {
    return prettyMilliseconds(this.timeRecorded, {
      secondsDecimalDigits: 0,
      colonNotation: true
    });
  }
  /**
   * Get the lang selected by the user
   */
  get langSelected() {
    for (const sub of this.listOfSubtitles) {
      if (sub.id == this.languageSelectedId) {
        return sub.label;
      }
    }
    return "none";
  }
  get listOfLang() {
    return [{
      "value": "en-US",
      "label": this.$t('lang.en-US')
    }, {
      "value": "en-GB",
      "label": this.$t('lang.en-UK')
    }, {
      "value": "fr-FR",
      "label": this.$t('lang.fr-FR')
    }, {
      "value": "es-ES",
      "label": this.$t('lang.es-ES')
    }, {
      "value": "de-DE",
      "label": this.$t('lang.de-DE')
    }, {
      "value": "pt-PT",
      "label": this.$t('lang.pt-PT')
    }, {
      "value": "it-IT",
      "label": this.$t('lang.it-IT')
    }, {
      "value": "zh-CN",
      "label": this.$t('lang.zh-CN')
    }];
  }
  mounted() {
    this.voiceOverOpen = true;
    this.loadSubtitles();
  }
  /**
   * LMoad the subtitles of the chapters
   */
  async loadSubtitles() {
    const url = "/api/v2/chapter/subtitles/" + this.chapterId;
    axios.get(url).then(response => {
      this.listOfSubtitles = response.data;
    });
  }
  isLangAvailable(lang) {
    return ['arb', 'ar_AE', 'ca_ES', 'yue_CN', 'cmn_CN', 'da_DK', 'nl_BE', 'nl_NL', 'en_AU', 'en_GB', 'en_IN', 'en_NZ', 'en_ZA', 'en_US', 'en_GB_WLS', 'fi_FI', 'fr_FR', 'fr_BE', 'fr_CA', 'hi_IN', 'de_DE', 'de_AT', 'is_IS', 'it_IT', 'ja_JP', 'ko_KR', 'nb_NO', 'pl_PL', 'pt_BR', 'pt_PT', 'ro_RO', 'ru_RU', 'es_ES', 'es_MX', 'es_US', 'sv_SE', 'tr_TR', 'cy_GB'].indexOf(lang) > -1;
  }
  /**
   * Get the videoPlayer from refs
   * @private
   */
  videoPlayer() {
    return this.$refs['videoPlayer'];
  }
  /**
   * Get the mediaRecorder from refs
   * @private
   */
  mediaRecorder() {
    return this.$refs['mediaRecorder'];
  }
  /**
   * Get the audioplayer from refs
   * @private
   */
  audioPlayer() {
    return this.$refs['audioPlayer'];
  }
  async closeVoiceOver() {
    if (this.blobRecorded) {
      try {
        await confirmation(this.$t('voiceOver.panel.close.confirm'), '', this.$t('messageBox.ok'), this.$t('messageBox.cancel'));
        this.reinitPlayer();
        this.voiceOverOpen = false;
      } catch (e) {
        // cancel
        return;
      }
    } else {
      this.reinitPlayer();
      this.voiceOverOpen = false;
    }
  }
  goToTranscript() {
    return;
  }
  //*************************************
  //* MICROPHONE RECORDING METHOD
  //*************************************
  /**
   * User ask to record with microhpne,
   * go to step 10+
   * @private
   */
  onChooseMicVoice() {
    this.currentStep = 10;
    this.timeRecorded = 0;
    // we wait the component rendering
    this.$nextTick(() => {
      const mediaRecorder = this.mediaRecorder();
      if (mediaRecorder) {
        mediaRecorder.startRecording();
      } else {
        console.warn("Media recorder not found");
      }
    });
  }
  /**
   * Ask to the media recorder to pause the recording
   * stop the video player also
   * and wait for blob finaliszing (see onSuccessMicrophoneRecorded)
   */
  resumeMicrophoneRecording() {
    if (this.isRecording) {
      const mediaRecorder = this.mediaRecorder();
      if (mediaRecorder) {
        mediaRecorder.resumeRecording();
        this.isRecordingPause = false;
        const videoPlayer = this.videoPlayer();
        if (videoPlayer) {
          videoPlayer.play();
        }
      } else {
        console.warn("Media recorder not found");
      }
    }
  }
  /**
   * Get pourcentage of progress
   */
  get progressRecord() {
    const timeRecordInSecond = this.timeRecorded / 1000;
    return timeRecordInSecond / this.durationVideo;
  }
  get progressRecordStyle() {
    return `width:calc(${this.progressRecord * 100}% + calc(32px * ${this.progressRecord}))`;
  }
  /**
   * Ask to the media recorder to pause the recording
   * stop the video player also
   * and wait for blob finaliszing (see onSuccessMicrophoneRecorded)
   */
  pauseMicrophoneRecording() {
    if (this.isRecording) {
      const mediaRecorder = this.mediaRecorder();
      if (mediaRecorder) {
        mediaRecorder.pauseRecording();
        this.isRecordingPause = true;
        const videoPlayer = this.videoPlayer();
        if (videoPlayer) {
          videoPlayer.pause();
        }
      } else {
        console.warn("Media recorder not found");
      }
    }
  }
  /**
   * Ask to the media recorder to stop the recording
   * stop the video player also
   * and wait for blob finaliszing (see onSuccessMicrophoneRecorded)
   */
  stopMicrophoneRecording() {
    if (this.isRecording) {
      const mediaRecorder = this.mediaRecorder();
      this.isRecording = false;
      if (mediaRecorder) {
        mediaRecorder.stopRecording();
        const videoPlayer = this.videoPlayer();
        if (videoPlayer) {
          videoPlayer.pause();
        }
      } else {
        console.warn("Media recorder not found");
      }
    }
  }
  /**
   * When microphone blob is received from the mediarecorder
   * we change the step to ask for a confirmation
   * @param blob
   */
  onSuccessMicrophoneRecorded(blob) {
    this.blobRecorded = blob;
    this.urlBlob = URL.createObjectURL(blob);
    this.currentStep = 12;
    //now start the player
    const videoPlayer = this.videoPlayer();
    if (videoPlayer) {
      this.reinitPlayer();
      videoPlayer.mute();
      videoPlayer.setCurrentTime(0);
      videoPlayer.play();
    }
  }
  /**
   * When it's impossible to capture the microphone,
   * we rollback to step 0
   */
  onMicrophoneError() {
    this.currentStep = 0;
  }
  /**
   * save the value of current countdown before the microphone record
   * @param countDown
   */
  onCountDown(countDown) {
    this.countDown = countDown;
  }
  /**
   * Save the current time of the micriphone record
   * @param time
   */
  onTimeRecord(time) {
    this.timeRecorded = time;
    this.setSpeed(1);
    if (this.timeRecorded > this.durationVideo * 1000) {
      this.stopMicrophoneRecording();
    }
  }
  /**
   * When the medira recorder start to record the microphone
   * We mute the video plauyer, and launch the play
   */
  onStartRecording() {
    this.countDown = -1;
    this.currentStep = 11;
    this.isRecording = true;
    const videoPlayer = this.videoPlayer();
    if (videoPlayer) {
      videoPlayer.setCurrentTime(0);
      videoPlayer.play();
      videoPlayer.mute();
    }
  }
  /**
   * When the user is ok with the microphone
   * we send the blob to backend and close the panel
   */
  confirmMicRecording() {
    if (this.blobRecorded) {
      this.reinitPlayer();
      const formData = new FormData();
      formData.append('file', this.blobRecorded);
      axios.post('/recorder/voiceOver/upload/' + this.chapterId, formData, {
        onUploadProgress: progressEvent => {
          const percentCompleted = Math.round(progressEvent.loaded * 100 / progressEvent.total);
          console.log(`Progression de l'upload : ${percentCompleted}%`);
        }
      }).then(() => {
        this.onClose();
      });
    }
  }
  /**
   * The user cancel the record,
   * we reset the blob and come back to step 0
   */
  cancelMicrophoneRecord() {
    const mediaRecoreder = this.mediaRecorder();
    if (mediaRecoreder) {
      mediaRecoreder.cancel();
    }
    this.reinitPlayer();
    this.blobRecorded = null;
    this.urlBlob = null;
    this.currentStep = 0;
    this.countDown = 0;
  }
  //*********************************
  //* SUBTITLE TO VOICE AI METHODS
  //*********************************
  /**
   * User ask to use a generated voice
   * step 1+
   * @private
   */
  onChooseAIVoice() {
    this.currentStep = 1;
  }
  /**
   * Save the lang selected by the user
   * @param lang
   * @private
   */
  onLangSelected(lang) {
    if (lang) {
      this.languageSelectedId = lang;
      trig(Events.SELECT_AUDIO_TRACK, this.languageSelectedId);
      this.currentStep = 2;
    } else {
      alert('go to transcript TODO');
    }
  }
  /**
   * Cancel the VoiceAI choise
   * we remove the current voice selected on the player and come back to step 0
   * @private
   */
  cancelVoiceAI() {
    this.currentStep = 0;
    this.reinitPlayer();
    trig(Events.SELECT_AUDIO_TRACK, 0);
  }
  /**
   * Call the api to launch the voice over with a ai generated voice
   * and close the panel on succeess
   * @private
   */
  confirmVoiceAIOver() {
    this.reinitPlayer();
    const urlValidateSubtitle = "/recorder/voiceOver/subtitle/" + this.chapterId;
    const formData = new FormData();
    formData.append('subtitleId', this.languageSelectedId + "");
    axios.post(urlValidateSubtitle, formData).then(response => {
      console.log(response.data);
      this.onClose();
    }).catch(error => {
      console.error('Error on seding generated voice to backend:', error);
    });
  }
  // ************************************
  //* GENERATE WITH TEXT METHOD
  //*************************************
  selectGenerateWithText() {
    this.currentStep = 20;
  }
  cancelTxtVoiceAI() {
    this.currentStep = 0;
    this.blobRecorded = null;
    this.urlBlob = null;
    this.reinitPlayer();
  }
  previewVoiceAI() {
    if (this.textToGenerate) {
      clearTimeout(this.timeoutWaitingTextGeneration);
      const urlPreviewVoice = "/api/v2/chapter/voice/text/" + this.chapterId;
      const formData = new FormData();
      formData.append('text', this.textToGenerate);
      formData.append('jobID', this.jobIdVoicePreview);
      formData.append('lang', this.langTxtSelected);
      this.loadingVoiceFromText = true;
      axios.post(urlPreviewVoice, formData).then(async response => {
        if (response.status == 200) {
          this.loadingVoiceFromText = false;
          this.urlBlob = response.data;
          this.jobIdVoicePreview = '';
          const videoPlayer = this.videoPlayer();
          if (videoPlayer) {
            videoPlayer.setCurrentTime(0);
            videoPlayer.play();
            videoPlayer.mute();
          }
          const audioPlayer = this.audioPlayer();
          if (audioPlayer) {
            audioPlayer.autoplay = true;
            audioPlayer.volume = 1;
            audioPlayer.currentTime = 0;
            audioPlayer.play();
          }
        } else if (response.status == 202) {
          this.jobIdVoicePreview = response.data;
          this.timeoutWaitingTextGeneration = setTimeout(() => {
            this.previewVoiceAI();
          }, 2000);
        }
      }).catch(error => {
        clearTimeout(this.timeoutWaitingTextGeneration);
        console.error('Error on preview generated voice', error);
      });
    } else {
      console.error('Error on preview generated voice no voice to generate');
    }
  }
  confirmTxtVoiceAI() {
    if (this.urlBlob) {
      this.reinitPlayer();
      const urlTxtVoiceOver = "/recorder/voiceOver/text/" + this.chapterId;
      const formData = new FormData();
      formData.append('filePath', this.urlBlob);
      axios.post(urlTxtVoiceOver, formData).then(() => {
        this.onClose();
      }).catch(err => {
        console.log("Error on validate text voiceOver", err);
      });
      return;
    }
  }
  //*********************************
  //* AUDIO PLAYER METHOD
  //********************************/
  reinitPlayer() {
    const videoPlayer = this.videoPlayer();
    if (videoPlayer) {
      videoPlayer.pause();
      videoPlayer.unmute();
    }
  }
  setSpeed(speed = 1) {
    const videoPlayer = this.videoPlayer();
    if (videoPlayer) {
      videoPlayer.setSpeed(speed);
    }
  }
  onPlay() {
    const audioPlayer = this.audioPlayer();
    if (audioPlayer) {
      if (audioPlayer.paused) {
        audioPlayer.play();
      }
    }
    if (this.isRecording && this.isRecordingPause) {
      this.resumeMicrophoneRecording();
    }
  }
  /**
   * PLAYER METHOD
   */
  onStop() {
    const audioPlayer = this.audioPlayer();
    if (audioPlayer) {
      if (!audioPlayer.paused) {
        audioPlayer.pause();
      }
    }
    if (this.isRecording) {
      var _this$videoPlayer;
      var videoPlayerTime = ((_this$videoPlayer = this.videoPlayer()) === null || _this$videoPlayer === void 0 ? void 0 : _this$videoPlayer.getCurrentTime()) || 0;
      // if end of the video
      if (this.timeRecorded + 1000 > this.durationVideo * 1000) {
        this.stopMicrophoneRecording();
      } else {
        this.pauseMicrophoneRecording();
      }
    }
  }
  onTimeUpdate(time) {
    const audioPlayer = this.audioPlayer();
    if (audioPlayer) {
      if (Math.abs(audioPlayer.currentTime - time) > 0.5) {
        audioPlayer.currentTime = time;
      }
    }
    if (this.isRecording) {
      // syncrhonise recorder with player (1s max)
      if (Math.abs(time * 1000 - this.timeRecorded) >= 1000) {
        const videoPlayer = this.videoPlayer();
        if (videoPlayer) {
          videoPlayer.setCurrentTime(this.timeRecorded / 1000);
        }
      }
    }
  }
  onVolumeChange(volume) {
    const audioPlayer = this.audioPlayer();
    if (audioPlayer) {
      audioPlayer.volume = volume;
    }
    if (this.isRecording) {
      const videoPlayer = this.videoPlayer();
      if (videoPlayer) {
        videoPlayer.mute();
      }
    }
  }
  onDuration(duration) {
    this.durationVideo = duration;
  }
  //****************************************
  //* EVENT METHODS
  //*****************************************/
  async onClose() {
    this.waitingTranscoding = true;
    // waiting transcoding start
    await delay(3000);
    return;
  }
  beforeDestroy() {
    clearTimeout(this.timeoutWaitingTextGeneration);
  }
};
__decorate([Prop({
  required: true
})], VoiceOver.prototype, "urlPlayer", void 0);
__decorate([Prop({
  required: true
})], VoiceOver.prototype, "chapterId", void 0);
__decorate([Prop({
  default: false
})], VoiceOver.prototype, "generateVoiceEnabled", void 0);
__decorate([Emit('goToSubtitle')], VoiceOver.prototype, "goToTranscript", null);
__decorate([Emit('onClose')], VoiceOver.prototype, "onClose", null);
VoiceOver = __decorate([Component({
  components: {
    Loader,
    WSelect,
    WInput,
    LightButton,
    MediaRecorder,
    Icon,
    LegacyVideoChapterPlayer,
    BigButton,
    TitlePopUp,
    StandardButton,
    WDialog
  }
})], VoiceOver);
export default VoiceOver;