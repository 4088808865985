import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3c46d4ca"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "description"
};
const _hoisted_2 = {
  class: "options"
};
const _hoisted_3 = {
  class: "selectTitle"
};
const _hoisted_4 = {
  class: "toggle-scorm",
  "data-test": "speach-scorm-popup-scheduled-version-toggle"
};
const _hoisted_5 = {
  key: 0,
  class: "toggle-scorm"
};
const _hoisted_6 = {
  class: "warning-message"
};
const _hoisted_7 = {
  key: 0
};
const _hoisted_8 = {
  key: 1
};
const _hoisted_9 = {
  key: 1,
  class: "export-in-progress-container"
};
const _hoisted_10 = {
  key: 0
};
const _hoisted_11 = {
  class: "export-in-progress-container__msg"
};
const _hoisted_12 = {
  class: "export-in-progress-container__msg"
};
const _hoisted_13 = {
  key: 1
};
const _hoisted_14 = {
  class: "export-in-progress-container__msg"
};
const _hoisted_15 = {
  class: "export-in-progress-container__msg"
};
const _hoisted_16 = {
  class: "dialog-footer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_select = _resolveComponent("w-select");
  const _component_w_toggle = _resolveComponent("w-toggle");
  const _component_icon = _resolveComponent("icon");
  const _component_loader = _resolveComponent("loader");
  const _component_standard_button = _resolveComponent("standard-button");
  const _component_w_dialog = _resolveComponent("w-dialog");
  return _openBlock(), _createBlock(_component_w_dialog, {
    visible: _ctx.visible,
    title: _ctx.$t('admin.dashboard.speaches.sidebar.scormExport.title'),
    onOnDialogClose: _ctx.closePopup
  }, {
    footer: _withCtx(() => [_createElementVNode("span", _hoisted_16, [_createVNode(_component_standard_button, {
      outlined: true,
      label: _ctx.$t('admin.dashboard.users.sidebar.deactivation.cancel'),
      onClick: _ctx.closePopup,
      "data-test": "speach-scorm-popup-cancel"
    }, null, 8, ["label", "onClick"]), !_ctx.isDownloadInProgress ? (_openBlock(), _createBlock(_component_standard_button, {
      key: 0,
      label: _ctx.$t('admin.dashboard.users.sidebar.deactivation.ok'),
      onClick: _ctx.startDownload
    }, null, 8, ["label", "onClick"])) : (_openBlock(), _createBlock(_component_standard_button, {
      key: 1,
      disabled: !_ctx.url,
      label: _ctx.$t('admin.dashboard.speaches.sidebar.scormExport.download.download'),
      onClick: _ctx.download
    }, null, 8, ["disabled", "label", "onClick"]))])]),
    default: _withCtx(() => [!_ctx.isDownloadInProgress ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.scormExport.information')), 1), _createElementVNode("fieldset", null, [_createElementVNode("legend", null, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.scormExport.parameters')), 1), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", null, [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.scormExport.type')), 1), _createVNode(_component_w_select, {
      value: "SCORM_LITE",
      options: _ctx.scormTypes,
      modelValue: _ctx.scormTypeSelected,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.scormTypeSelected = $event)
    }, null, 8, ["options", "modelValue"])]), _createElementVNode("div", _hoisted_4, [_createTextVNode(_toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.scormExport.scheduled')) + " ", 1), _createVNode(_component_w_toggle, {
      value: false,
      modelValue: _ctx.scormScheduledVersion,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.scormScheduledVersion = $event)
    }, null, 8, ["modelValue"])]), _ctx.scormTypeSelected == 'SCORM_FULL' ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createTextVNode(_toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.scormExport.navigation')) + " ", 1), _createVNode(_component_w_toggle, {
      value: false,
      modelValue: _ctx.scormNavigationInContent,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => _ctx.scormNavigationInContent = $event)
    }, null, 8, ["modelValue"])])) : _createCommentVNode("", true)])]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_icon, {
      icon: "information-outline",
      color: "light2",
      size: "default"
    }), _ctx.scormTypeSelected == 'SCORM_FULL' ? (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.scormExport.warningEmbed')), 1)) : (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.scormExport.warningClassic')), 1))])], 64)) : (_openBlock(), _createElementBlock("div", _hoisted_9, [!_ctx.url ? (_openBlock(), _createElementBlock("div", _hoisted_10, [_createElementVNode("div", null, [_createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.scormExport.inProgressModal.msgTop').toString()), 1), _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.scormExport.inProgressModal.msgBottom').toString()), 1)]), _createElementVNode("div", null, [_createVNode(_component_loader, {
      "spinner-size": 'spinnersNormal',
      class: "export-in-progress-container__spinner"
    })])])) : (_openBlock(), _createElementBlock("div", _hoisted_13, [_createElementVNode("div", null, [_createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.scormExport.download.msgTop').toString()), 1), _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t('admin.dashboard.speaches.sidebar.scormExport.download.msgBottom').toString()), 1)])]))]))]),
    _: 1
  }, 8, ["visible", "title", "onOnDialogClose"]);
}