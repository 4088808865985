import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.map.js";
import { __decorate } from "tslib";
import WSelectUsers from "@/components/form/w-select-users.vue";
import { VisibilityEnum } from "@/models/visibilityEnum";
import axios from "axios";
import WElMessage from "@/components/wrappers/w-elmessage.vue";
import StandardButton from "@/components/buttons/StandardButton.vue";
import WAlert from "@/components/wrappers/w-alert.vue";
import WSelectAdvanced from "@/components/form/w-select-advanced.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
let BatchUpdateSpeach = class BatchUpdateSpeach extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "speachesToUpdate", void 0);
    _defineProperty(this, "availableCategories", []);
    _defineProperty(this, "fieldsToUpdate", {
      author: undefined,
      visibility: undefined,
      categoryId: undefined
    });
    _defineProperty(this, "BASE_API_SPEACHES", '/api/v2/admin/speach');
  }
  mounted() {
    this.initCategories();
  }
  get availableVisibilities() {
    return [{
      id: VisibilityEnum.PUBLIC,
      name: this.$t('admin.dashboard.speaches.visibility.public')
    }, {
      id: VisibilityEnum.SEMI_PRIVE,
      name: this.$t('admin.dashboard.speaches.visibility.semiPrivate')
    }, {
      id: VisibilityEnum.RESTREINT,
      name: this.$t('admin.dashboard.speaches.visibility.restricted')
    }];
  }
  get listScheduledSpeaches() {
    return this.speachesToUpdate.filter(speach => speach.futureVersionDelayedTillTimestamp !== undefined);
  }
  initCategories() {
    axios.get(this.BASE_API_SPEACHES + '/categories').then(response => {
      this.availableCategories = response.data;
    });
  }
  onChangeAuthor(value) {
    this.fieldsToUpdate.author = value;
  }
  onChangeVisibility(value) {
    this.fieldsToUpdate.visibility = value;
  }
  onChangeCategory(value) {
    this.fieldsToUpdate.categoryId = value;
  }
  submitBatchUpdate() {
    var _this$fieldsToUpdate$;
    if (!this.fieldsToUpdate) {
      return;
    }
    axios.put(this.BASE_API_SPEACHES + '/batch-update', {
      speachesIds: this.speachesToUpdate.filter(speach => speach.futureVersionDelayedTillTimestamp === undefined).map(speach => `${speach.id}`)
    }, {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        dataType: "json",
        authorId: (_this$fieldsToUpdate$ = this.fieldsToUpdate.author) === null || _this$fieldsToUpdate$ === void 0 ? void 0 : _this$fieldsToUpdate$.id,
        visibility: this.fieldsToUpdate.visibility,
        categoryId: this.fieldsToUpdate.categoryId
      }
    }).then(() => {
      WElMessage.showSuccessMessage(this.$t("admin.dashboard.speaches.toasts.speachesBatchUpdated"));
      this.$emit('batchUpdateSuccess');
    }, error => {
      const speachTitles = this.speachesToUpdate.filter(speach => error.message.includes(speach.id)).map(speach => speach.title);
      WElMessage.showLongErrorMessage(this.$t("admin.dashboard.speaches.toasts.speachesBatchUpdateFailed", {
        speachesFailed: speachTitles
      }));
    });
  }
  cancelBatchUpdate() {
    this.$emit('batchUpdateCancel');
  }
};
__decorate([Prop({
  default: []
})], BatchUpdateSpeach.prototype, "speachesToUpdate", void 0);
BatchUpdateSpeach = __decorate([Component({
  components: {
    WSelectAdvanced,
    WAlert,
    StandardButton,
    WSelectUsers
  },
  emits: ['batchUpdateSuccess', 'batchUpdateCancel']
})], BatchUpdateSpeach);
export default BatchUpdateSpeach;