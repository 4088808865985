import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import axios from "axios";
import { Component, Vue } from "vue-facing-decorator";
let PdpDisplay = class PdpDisplay extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "pdpContent", "");
  }
  mounted() {
    this.getpdpContent();
  }
  async getpdpContent() {
    const result = await axios.get("/pdp/getPdp", {
      params: {
        dataType: "json"
      }
    });
    this.pdpContent = result.data;
    await this.$nextTick();
    this.$emit("pdpLoaded");
  }
};
PdpDisplay = __decorate([Component({})], PdpDisplay);
export default PdpDisplay;
/*
    To set some specific style in the content of the pdp in the DataBase use :
     - classe="title"
     - classe="subtitle"
     - classe="text"

    those classes are defined in a non-scoped Stylesheet at the end of this document.
    Non-scoped, because the classes contained in the DB could not be interpreted by a scoped style
     */