import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_dialog = _resolveComponent("el-dialog");
  return _openBlock(), _createBlock(_component_el_dialog, {
    "modal-class": _ctx.customClass,
    modelValue: _ctx.isVisible,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.isVisible = $event),
    width: _ctx.width,
    title: _ctx.title,
    "close-on-click-modal": _ctx.closeOnOutsideClick,
    onClose: _cache[1] || (_cache[1] = $event => _ctx.emitCloseEvent()),
    "align-center": true,
    modal: _ctx.modal,
    "append-to-body": _ctx.appendToBody,
    "close-on-press-escape": _ctx.closeOnOutsideClick,
    "show-close": _ctx.closeOnOutsideClick,
    fullscreen: _ctx.fullscreen
  }, _createSlots({
    default: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 2
  }, [_ctx.$slots.header ? {
    name: "header",
    fn: _withCtx(() => [_renderSlot(_ctx.$slots, "header")]),
    key: "0"
  } : undefined, _ctx.$slots.footer ? {
    name: "footer",
    fn: _withCtx(() => [_renderSlot(_ctx.$slots, "footer")]),
    key: "1"
  } : undefined]), 1032, ["modal-class", "modelValue", "width", "title", "close-on-click-modal", "modal", "append-to-body", "close-on-press-escape", "show-close", "fullscreen"]);
}