import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import axios from "axios";
import { FooterItemPathEnum } from "@/models/footerItemPathEnum";
import { Component, Vue } from "vue-facing-decorator";
let Footer = class Footer extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "footerData", {
      isEnabled: false,
      items: []
    });
  }
  get FooterItemPathEnum() {
    return FooterItemPathEnum;
  }
  mounted() {
    this.loadFooterData();
  }
  loadFooterData() {
    axios.get('/api/v2/footer').then(({
      data
    }) => {
      this.footerData = data;
    });
  }
};
Footer = __decorate([Component({})], Footer);
export default Footer;