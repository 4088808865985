import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-ccb2e682"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 1
};
const _hoisted_2 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_badge = _resolveComponent("el-badge");
  const _component_SpeachGrid = _resolveComponent("SpeachGrid");
  const _component_el_tab_pane = _resolveComponent("el-tab-pane");
  const _component_el_tabs = _resolveComponent("el-tabs");
  const _component_grid_layout = _resolveComponent("grid-layout");
  return _openBlock(), _createBlock(_component_grid_layout, {
    class: "gridLayoutContainer"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_tabs, {
      class: "tabs",
      modelValue: _ctx.selectedTab,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.selectedTab = $event)
    }, {
      default: _withCtx(() => [_ctx.isAuthor ? (_openBlock(), _createBlock(_component_el_tab_pane, {
        key: 0,
        name: "EDITED",
        label: _ctx.$t('myAccount.tabs.edited')
      }, {
        label: _withCtx(() => [_ctx.speachRefused > 0 ? (_openBlock(), _createBlock(_component_el_badge, {
          key: 0,
          class: "notifyNumber",
          value: _ctx.speachRefused
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('myAccount.tabs.edited')), 1)]),
          _: 1
        }, 8, ["value"])) : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t('myAccount.tabs.edited')), 1))]),
        default: _withCtx(() => [_ctx.selectedTab === 'EDITED' ? (_openBlock(), _createBlock(_component_SpeachGrid, {
          key: 0,
          "add-card": true,
          "add-card-icon": 'file-image-plus-outline',
          "url-add-card": _ctx.urlSpeachRecorder,
          emptyState: false,
          "label-add-card": _ctx.$t('myAccount.speach.create'),
          url: "/api/v2/my/speachEdited",
          "load-regulated": true,
          commentsEnabled: _ctx.commentsEnabled
        }, null, 8, ["url-add-card", "label-add-card", "commentsEnabled"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["label"])) : _createCommentVNode("", true), _ctx.isAuthor ? (_openBlock(), _createBlock(_component_el_tab_pane, {
        key: 1,
        name: "COEDIT",
        label: _ctx.$t('myAccount.tabs.coedited')
      }, {
        default: _withCtx(() => [_ctx.selectedTab === 'COEDIT' ? (_openBlock(), _createBlock(_component_SpeachGrid, {
          key: 0,
          url: "/api/v2/my/speachCoEdited",
          "load-regulated": false,
          commentsEnabled: _ctx.commentsEnabled
        }, null, 8, ["commentsEnabled"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["label"])) : _createCommentVNode("", true), _ctx.isAuthor && _ctx.isWorkflowEnabled ? (_openBlock(), _createBlock(_component_el_tab_pane, {
        key: 2,
        name: "WFINPROGRESS",
        label: _ctx.$t('myAccount.tabs.validationProcess')
      }, {
        default: _withCtx(() => [_ctx.selectedTab === 'WFINPROGRESS' ? (_openBlock(), _createBlock(_component_SpeachGrid, {
          key: 0,
          url: "/api/v2/my/speachInWorkflowValidation",
          filter: false,
          "load-regulated": true,
          commentsEnabled: _ctx.commentsEnabled
        }, null, 8, ["commentsEnabled"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["label"])) : _createCommentVNode("", true), _ctx.isApprover && _ctx.isWorkflowEnabled ? (_openBlock(), _createBlock(_component_el_tab_pane, {
        key: 3,
        name: "WFTOVALIDATE"
      }, {
        label: _withCtx(() => [_ctx.speachToValidate > 0 ? (_openBlock(), _createBlock(_component_el_badge, {
          key: 0,
          class: "notifyNumber",
          value: _ctx.speachToValidate
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('myAccount.tabs.validationPending')), 1)]),
          _: 1
        }, 8, ["value"])) : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.$t('myAccount.tabs.validationPending')), 1))]),
        default: _withCtx(() => [_ctx.selectedTab === 'WFTOVALIDATE' ? (_openBlock(), _createBlock(_component_SpeachGrid, {
          key: 0,
          url: "/api/v2/my/speachWorkflowToValidate",
          filter: false,
          "load-regulated": true,
          commentsEnabled: _ctx.commentsEnabled
        }, null, 8, ["commentsEnabled"])) : _createCommentVNode("", true)]),
        _: 1
      })) : _createCommentVNode("", true), _ctx.isAuthor && _ctx.isDelayEnabled ? (_openBlock(), _createBlock(_component_el_tab_pane, {
        key: 4,
        name: "SPEACHDELAY",
        label: _ctx.$t('myAccount.tabs.delay')
      }, {
        default: _withCtx(() => [_ctx.selectedTab === 'SPEACHDELAY' ? (_openBlock(), _createBlock(_component_SpeachGrid, {
          key: 0,
          url: "/api/v2/my/speachDelay",
          filter: false,
          "publish-filter": false,
          "load-regulated": true,
          commentsEnabled: _ctx.commentsEnabled
        }, null, 8, ["commentsEnabled"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["label"])) : _createCommentVNode("", true), _createVNode(_component_el_tab_pane, {
        name: "SPEACHDUEDATE",
        label: _ctx.$t('myAccount.tabs.dueDate')
      }, {
        default: _withCtx(() => [_ctx.selectedTab === 'SPEACHDUEDATE' ? (_openBlock(), _createBlock(_component_SpeachGrid, {
          key: 0,
          url: "/api/v2/my/speachWithDueDate",
          filter: false,
          "publish-filter": false,
          "load-regulated": true,
          commentsEnabled: _ctx.commentsEnabled
        }, null, 8, ["commentsEnabled"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["label"]), !_ctx.isAdmin ? (_openBlock(), _createBlock(_component_el_tab_pane, {
        key: 5,
        name: "FOLLOWED",
        label: _ctx.$t('myAccount.tabs.followed')
      }, {
        default: _withCtx(() => [_ctx.selectedTab === 'FOLLOWED' ? (_openBlock(), _createBlock(_component_SpeachGrid, {
          key: 0,
          url: "/api/v2/my/speachFollowed",
          filter: true,
          "publish-filter": false,
          "load-regulated": false,
          commentsEnabled: _ctx.commentsEnabled
        }, null, 8, ["commentsEnabled"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["label"])) : _createCommentVNode("", true), _createVNode(_component_el_tab_pane, {
        label: _ctx.$t('myAccount.tabs.bookmarked'),
        name: "BOOKMARKED"
      }, {
        default: _withCtx(() => [_ctx.selectedTab === 'BOOKMARKED' ? (_openBlock(), _createBlock(_component_SpeachGrid, {
          key: 0,
          url: "/api/v2/my/speachBookmarked",
          filter: true,
          "publish-filter": false,
          "load-regulated": false,
          commentsEnabled: _ctx.commentsEnabled
        }, null, 8, ["commentsEnabled"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["label"]), _createVNode(_component_el_tab_pane, {
        label: _ctx.$t('myAccount.tabs.toFollow'),
        name: "ASSIGNED"
      }, {
        default: _withCtx(() => [_ctx.selectedTab === 'ASSIGNED' ? (_openBlock(), _createBlock(_component_SpeachGrid, {
          key: 0,
          url: "/api/v2/my/speachAssigned",
          filter: true,
          "publish-filter": false,
          "load-regulated": false,
          commentsEnabled: _ctx.commentsEnabled
        }, null, 8, ["commentsEnabled"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["label"]), _ctx.isAuthor ? (_openBlock(), _createBlock(_component_el_tab_pane, {
        key: 6,
        name: "TEMPLATE_EDIT",
        label: _ctx.$t('myAccount.tabs.template.edited')
      }, {
        default: _withCtx(() => [_ctx.selectedTab === 'TEMPLATE_EDIT' ? (_openBlock(), _createBlock(_component_SpeachGrid, {
          key: 0,
          url: "/api/v2/my/templateEdited",
          "load-regulated": true,
          commentsEnabled: _ctx.commentsEnabled
        }, null, 8, ["commentsEnabled"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["label"])) : _createCommentVNode("", true), _ctx.isAuthor ? (_openBlock(), _createBlock(_component_el_tab_pane, {
        key: 7,
        name: "TEMPLATE_COEDIT",
        label: _ctx.$t('myAccount.tabs.template.coedit')
      }, {
        default: _withCtx(() => [_ctx.selectedTab === 'TEMPLATE_COEDIT' ? (_openBlock(), _createBlock(_component_SpeachGrid, {
          key: 0,
          url: "/api/v2/my/templateCoEdited",
          "load-regulated": true,
          commentsEnabled: _ctx.commentsEnabled
        }, null, 8, ["commentsEnabled"])) : _createCommentVNode("", true)]),
        _: 1
      }, 8, ["label"])) : _createCommentVNode("", true)]),
      _: 1
    }, 8, ["modelValue"])]),
    _: 1
  });
}