const LOGIN_MAGIC_KEY = "a3ad9f94-326d-4523-a7ae-d8add5e5660b";

/**
 * Si le contenu de la réponse ajax contient la valeur magique insérée par #{security.loginRedirection /}
 * Alors cela veut dire que la requête ajax a redirigé vers la page de login
 * Donc on redirige vers la page de login
 *
 * On choisi de recharger la fenêtre parente dans le cas où on détecte que la fenêtre courante est embarquée dans une
 * iframe du même serveur.
 */
export function redirectIfLogin(data: any) {
  if (checkIfLogin(data)) {
    redirectToLogin(true)
  }
}

function redirectToLogin(disconnected: boolean){
  let url="/login";
  if(disconnected){
    url += "?disconnected=true";
  }
  if (window.location.pathname != url) {
    if (isEmbeddedOnSameHost()) {
      window.parent.location.assign(url);
    } else {
      window.location.assign(url);
    }
  }
}

/**
 * Si le contenu de la réponse ajax contient la valeur magique insérée par #{security.loginRedirection /}
 * Alors cela veut dire que la requête ajax demande à rediriger vers le login.
 * Cette fonction permet de dire si c'est le cas ou non
 */
function checkIfLogin(data: any): boolean {
  return (data+"").indexOf(LOGIN_MAGIC_KEY) > -1;
}


/**
 * Détecte si la page courante est embarquée dans une iframe du même serveur.
 */
function isEmbeddedOnSameHost() {
  try {
    return isEmbedded() && window.parent.location.host === window.location.host;
  } catch (e) {
    // si il y a une erreur same-origin policy, cela veut dire qu'on est embarqué sur un site externe.
    return false;
  }
}

/**
 * Détecte si la page courante est embarquée dans une iframe.
 *
 * @throws SecurityError si window.top est une frame dont l'origine est différente de window.self
 */
function isEmbedded() {
  return window.self !== window.top;
}
