import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue, Watch } from "vue-facing-decorator";
let InformationDialog = class InformationDialog extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "title", void 0);
    _defineProperty(this, "message", void 0);
    _defineProperty(this, "width", void 0);
    _defineProperty(this, "visible", void 0);
    _defineProperty(this, "closeOnOutsideClick", void 0);
    _defineProperty(this, "isVisible", false);
  }
  mounted() {
    this.isVisible = this.visible;
  }
  onVisiblePropChanges() {
    this.isVisible = this.visible;
  }
  handleDialogClose() {
    this.isVisible = false;
    return true;
  }
};
__decorate([Prop({
  default: ''
})], InformationDialog.prototype, "title", void 0);
__decorate([Prop({
  default: ''
})], InformationDialog.prototype, "message", void 0);
__decorate([Prop({
  default: '560px'
})], InformationDialog.prototype, "width", void 0);
__decorate([Prop({
  default: false
})], InformationDialog.prototype, "visible", void 0);
__decorate([Prop({
  default: true
})], InformationDialog.prototype, "closeOnOutsideClick", void 0);
__decorate([Watch('visible')], InformationDialog.prototype, "onVisiblePropChanges", null);
__decorate([Emit("on-dialog-close")], InformationDialog.prototype, "handleDialogClose", null);
InformationDialog = __decorate([Component({
  emits: ['on-dialog-close']
})], InformationDialog);
export default InformationDialog;