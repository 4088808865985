import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue, Watch } from "vue-facing-decorator";
let WCheckbox = class WCheckbox extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "modelValue", void 0);
    _defineProperty(this, "internalChecked", false);
  }
  mounted() {
    this.internalChecked = this.modelValue;
  }
  handleChange() {
    return this.internalChecked;
  }
  // Watcher to update real Value when prop 'value' changes
  onValueChanged(newValue) {
    this.internalChecked = newValue;
  }
  changeValueWithClick() {
    this.internalChecked = !this.internalChecked;
    this.handleChange();
  }
};
__decorate([Prop({
  default: false
})], WCheckbox.prototype, "modelValue", void 0);
__decorate([Emit('update:modelValue')], WCheckbox.prototype, "handleChange", null);
__decorate([Watch('isChecked')], WCheckbox.prototype, "onValueChanged", null);
WCheckbox = __decorate([Component({})], WCheckbox);
export default WCheckbox;