import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
import DashBoardLineChart from "@/components/dashboard/charts/DashBoardLineChart.vue";
import EmptyChart from "@/components/dashboard/charts/EmptyChart.vue";
let AdvancedLineChart = class AdvancedLineChart extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "legendTooltipId", void 0);
    _defineProperty(this, "width", void 0);
    _defineProperty(this, "height", void 0);
    _defineProperty(this, "graphs", void 0);
    _defineProperty(this, "title", void 0);
    _defineProperty(this, "big", void 0);
    _defineProperty(this, "cumulative", void 0);
    _defineProperty(this, "loading", void 0);
  }
};
__decorate([Prop({
  required: true
})], AdvancedLineChart.prototype, "legendTooltipId", void 0);
__decorate([Prop({
  default: 500
})], AdvancedLineChart.prototype, "width", void 0);
__decorate([Prop({
  default: 500
})], AdvancedLineChart.prototype, "height", void 0);
__decorate([Prop({
  required: true
})], AdvancedLineChart.prototype, "graphs", void 0);
__decorate([Prop({
  default: ""
})], AdvancedLineChart.prototype, "title", void 0);
__decorate([Prop({
  default: false
})], AdvancedLineChart.prototype, "big", void 0);
__decorate([Prop({
  type: Boolean,
  default: false
})], AdvancedLineChart.prototype, "cumulative", void 0);
__decorate([Prop({
  default: false
})], AdvancedLineChart.prototype, "loading", void 0);
AdvancedLineChart = __decorate([Component({
  components: {
    EmptyChart,
    DashBoardLineChart
  }
})], AdvancedLineChart);
export default AdvancedLineChart;