import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
let SidePanel = class SidePanel extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "visible", void 0);
    _defineProperty(this, "closeButtonVisible", void 0);
    _defineProperty(this, "wideSidePanel", void 0);
  }
};
__decorate([Prop({
  required: true
})], SidePanel.prototype, "visible", void 0);
__decorate([Prop({
  default: false
})], SidePanel.prototype, "closeButtonVisible", void 0);
__decorate([Prop({
  default: false
})], SidePanel.prototype, "wideSidePanel", void 0);
SidePanel = __decorate([Component({})], SidePanel);
export default SidePanel;