import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_popover = _resolveComponent("el-popover");
  return _openBlock(), _createBlock(_component_el_popover, {
    placement: _ctx.placement,
    title: _ctx.title,
    width: _ctx.width,
    trigger: "hover",
    content: _ctx.text
  }, {
    reference: _withCtx(() => [_renderSlot(_ctx.$slots, "default")]),
    _: 3
  }, 8, ["placement", "title", "width", "content"]);
}