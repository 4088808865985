import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
let ImageBackground = class ImageBackground extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "imageUrl", void 0);
    _defineProperty(this, "titre", void 0);
    _defineProperty(this, "sousTitre", void 0);
    _defineProperty(this, "width", void 0);
    _defineProperty(this, "height", void 0);
    _defineProperty(this, "radius", void 0);
    _defineProperty(this, "backgroundSize", void 0);
    _defineProperty(this, "marginTop", void 0);
    _defineProperty(this, "idDivBackground", void 0);
  }
  get radiusApplied() {
    if (this.radius !== '') {
      return {
        'border-radius': this.radius
      };
    } else {
      return '';
    }
  }
};
__decorate([Prop({
  required: true
})], ImageBackground.prototype, "imageUrl", void 0);
__decorate([Prop({
  default: ''
})], ImageBackground.prototype, "titre", void 0);
__decorate([Prop({
  default: ''
})], ImageBackground.prototype, "sousTitre", void 0);
__decorate([Prop({
  default: '266px'
})], ImageBackground.prototype, "width", void 0);
__decorate([Prop({
  default: '169px'
})], ImageBackground.prototype, "height", void 0);
__decorate([Prop({
  default: ''
})], ImageBackground.prototype, "radius", void 0);
__decorate([Prop({
  default: 'contain'
})], ImageBackground.prototype, "backgroundSize", void 0);
__decorate([Prop({
  default: ''
})], ImageBackground.prototype, "marginTop", void 0);
__decorate([Prop({
  default: ''
})], ImageBackground.prototype, "idDivBackground", void 0);
ImageBackground = __decorate([Component({})], ImageBackground);
export default ImageBackground;