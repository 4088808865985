import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-56d9088e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "aiStepContainer"
};
const _hoisted_2 = {
  class: "aiContent"
};
const _hoisted_3 = {
  class: "labelSubTitle"
};
const _hoisted_4 = {
  class: "contentTitle"
};
const _hoisted_5 = {
  class: "labelSubTitle"
};
const _hoisted_6 = {
  class: "contentDescription"
};
const _hoisted_7 = {
  class: "labelSubTitle"
};
const _hoisted_8 = {
  class: "chapterTitle"
};
const _hoisted_9 = {
  class: "chapterNumber"
};
const _hoisted_10 = {
  class: "chapterTitleText"
};
const _hoisted_11 = {
  class: "subChapterTitle"
};
const _hoisted_12 = {
  class: "subChapterNumber"
};
const _hoisted_13 = {
  class: "subChapterTitleText"
};
const _hoisted_14 = {
  class: "bottomLineButton"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Loader = _resolveComponent("Loader");
  const _component_el_collapse_item = _resolveComponent("el-collapse-item");
  const _component_el_collapse = _resolveComponent("el-collapse");
  const _component_icon = _resolveComponent("icon");
  const _component_standard_button = _resolveComponent("standard-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_ctx.generationInProgress ? (_openBlock(), _createBlock(_component_Loader, {
    key: 0
  })) : _createCommentVNode("", true), _createVNode(_Transition, {
    name: "fade"
  }, {
    default: _withCtx(() => {
      var _ctx$speachGenerated, _ctx$speachGenerated2;
      return [_withDirectives(_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('aiContent.title')), 1), _createElementVNode("div", _hoisted_4, _toDisplayString((_ctx$speachGenerated = _ctx.speachGenerated) === null || _ctx$speachGenerated === void 0 ? void 0 : _ctx$speachGenerated.title), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('aiContent.description')), 1), _createElementVNode("div", _hoisted_6, _toDisplayString((_ctx$speachGenerated2 = _ctx.speachGenerated) === null || _ctx$speachGenerated2 === void 0 ? void 0 : _ctx$speachGenerated2.description), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$t('aiContent.plan')), 1), _createVNode(_component_el_collapse, {
        modelValue: _ctx.openElement,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.openElement = $event)
      }, {
        default: _withCtx(() => {
          var _ctx$speachGenerated3;
          return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx$speachGenerated3 = _ctx.speachGenerated) === null || _ctx$speachGenerated3 === void 0 ? void 0 : _ctx$speachGenerated3.chapters, (chapter, indexChapter) => {
            return _openBlock(), _createBlock(_component_el_collapse_item, {
              name: 'chapter' + indexChapter,
              key: 'chapter' + indexChapter
            }, {
              title: _withCtx(() => [_createElementVNode("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('aiContent.chapter')) + " " + _toDisplayString(indexChapter + 1) + ":", 1), _createElementVNode("div", _hoisted_10, _toDisplayString(chapter.title), 1)])]),
              default: _withCtx(() => [_createElementVNode("div", null, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(chapter.subchapters, (subchapter, indexSubChapter) => {
                return _openBlock(), _createElementBlock("div", {
                  key: 'subchapter' + indexSubChapter
                }, [_createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, _toDisplayString(indexChapter + 1) + "." + _toDisplayString(indexSubChapter + 1) + ":", 1), _createElementVNode("div", _hoisted_13, _toDisplayString(subchapter.title), 1)])]);
              }), 128))])]),
              _: 2
            }, 1032, ["name"]);
          }), 128))];
        }),
        _: 1
      }, 8, ["modelValue"]), _createElementVNode("div", _hoisted_14, [_createVNode(_component_standard_button, {
        outlined: true,
        class: "buttonRefresh",
        onClick: _ctx.regenerate
      }, {
        default: _withCtx(() => [_createVNode(_component_icon, {
          icon: "refresh",
          class: "iconButtonElement"
        }), _createTextVNode(_toDisplayString(_ctx.$t('aiContent.regenerate')), 1)]),
        _: 1
      }, 8, ["onClick"]), _createVNode(_component_standard_button, {
        onClick: _ctx.validateAiSpeach
      }, {
        default: _withCtx(() => [_createVNode(_component_icon, {
          icon: "check",
          class: "iconButtonElement"
        }), _createTextVNode(_toDisplayString(_ctx.$t('aiContent.validate')), 1)]),
        _: 1
      }, 8, ["onClick"])])], 512), [[_vShow, !_ctx.generationInProgress]])];
    }),
    _: 1
  })]);
}