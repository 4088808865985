import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_input = _resolveComponent("el-input");
  return _openBlock(), _createBlock(_component_el_input, {
    modelValue: _ctx.realValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.realValue = $event),
    disabled: !_ctx.enabled,
    size: _ctx.size,
    placeholder: _ctx.placeholder,
    clearable: _ctx.clearable,
    type: _ctx.type,
    maxlength: _ctx.maxlength,
    "show-word-limit": _ctx.showWordLimit && _ctx.maxlength !== '',
    rows: _ctx.rows,
    onChange: _ctx.onChange,
    "data-test": _ctx.dataTest
  }, _createSlots({
    _: 2
  }, [!_ctx.noPrefix ? {
    name: "prefix",
    fn: _withCtx(() => [_renderSlot(_ctx.$slots, "prefix")]),
    key: "0"
  } : undefined]), 1032, ["modelValue", "disabled", "size", "placeholder", "clearable", "type", "maxlength", "show-word-limit", "rows", "onChange", "data-test"]);
}