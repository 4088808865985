import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-03f95f18"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 0,
  class: "filter"
};
const _hoisted_2 = {
  class: "filter"
};
const _hoisted_3 = {
  id: "container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_select = _resolveComponent("w-select");
  const _component_search_category_title = _resolveComponent("search-category-title");
  const _component_standard_speach_card = _resolveComponent("standard-speach-card");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_search_category_title, {
    "results-number": _ctx.totalNumberOfSpeachesFound,
    "category-title": "searchbar.speaches",
    class: "searchSpeachesTitle"
  }, {
    default: _withCtx(() => [_ctx.availableLanguages.length > 0 ? (_openBlock(), _createElementBlock("span", _hoisted_1, [_createVNode(_component_w_select, {
      options: _ctx.langFilterOptions,
      "label-key": "text",
      "value-key": "id",
      placeholder: _ctx.$t('lang.placeholder'),
      onChange: _ctx.filterSpeachLang
    }, null, 8, ["options", "placeholder", "onChange"])])) : _createCommentVNode("", true), _createElementVNode("span", _hoisted_2, [_createVNode(_component_w_select, {
      options: _ctx.sortOptions,
      value: _ctx.sortIndex,
      "label-key": "text",
      "value-key": "id",
      onChange: _ctx.sortSpeachSearch
    }, null, 8, ["options", "value", "onChange"])])]),
    _: 1
  }, 8, ["results-number"]), _createElementVNode("div", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.speachesFound, (speach, index) => {
    return _openBlock(), _createElementBlock("div", {
      key: speach.id,
      class: _normalizeClass([_ctx.getClass(index), "card"])
    }, [_createVNode(_component_standard_speach_card, {
      speach: speach,
      loadRegulated: true
    }, null, 8, ["speach"])], 2);
  }), 128))])]);
}