import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
let WDatePicker = class WDatePicker extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "calendar", void 0);
    _defineProperty(this, "separator", void 0);
    _defineProperty(this, "placement", void 0);
    _defineProperty(this, "cal", null);
  }
  mounted() {
    this.cal = this.calendar;
  }
  get dateFormat() {
    let dateFormat = 'M/D/YYYY';
    if (this.$i18n.locale.toLowerCase() === 'de') {
      dateFormat = 'D.M.YYYY';
    } else if (this.$i18n.locale.toLowerCase() === 'es') {
      dateFormat = 'D/M/YYYY';
    } else if (this.$i18n.locale.toLowerCase() === 'fr') {
      dateFormat = 'DD/MM/YYYY';
    }
    return dateFormat;
  }
  changeCal() {
    this.cal = this.calendar;
  }
  onChangeCalendar(newDate) {
    this.$emit('changeCalendar', newDate);
  }
};
__decorate([Prop({
  default: null
})], WDatePicker.prototype, "calendar", void 0);
__decorate([Prop({
  default: '-'
})], WDatePicker.prototype, "separator", void 0);
__decorate([Prop({
  default: 'bottom'
})], WDatePicker.prototype, "placement", void 0);
__decorate([Watch('calendar')], WDatePicker.prototype, "changeCal", null);
WDatePicker = __decorate([Component({
  emits: ['changeCalendar']
})], WDatePicker);
export default WDatePicker;