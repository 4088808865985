import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "shareChapterSelectorContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_checkbox = _resolveComponent("w-checkbox");
  const _component_w_select = _resolveComponent("w-select");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_w_checkbox, {
    onClick: _ctx.resetChooseChapter,
    modelValue: _ctx.selectChapter,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.selectChapter = $event),
    class: "shareChapterCheckbox"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('chapter.share.startAt')), 1)]),
    _: 1
  }, 8, ["onClick", "modelValue"]), _createVNode(_component_w_select, {
    options: _ctx.chapters,
    "value-key": "chapitreID",
    "label-key": "title",
    onChange: _ctx.updateLinksWithChapters,
    placeholder: _ctx.$t('chapter.share.selectChapter'),
    modelValue: _ctx.selectedChapter,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => _ctx.selectedChapter = $event),
    disabled: !_ctx.selectChapter,
    class: "shareChapterSelectorSelect"
  }, null, 8, ["options", "onChange", "placeholder", "modelValue", "disabled"])]);
}