import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
import StandardButton from "@/components/buttons/StandardButton.vue";
import GridLayout from "@/components/layout/GridLayout.vue";
import Icon from "@/components/icon/Icon.vue";
let GetStartedCard = class GetStartedCard extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "title", void 0);
    _defineProperty(this, "content", void 0);
    _defineProperty(this, "buttonLabel", void 0);
    _defineProperty(this, "closeButton", void 0);
  }
};
__decorate([Prop({
  required: true
})], GetStartedCard.prototype, "title", void 0);
__decorate([Prop({
  required: true
})], GetStartedCard.prototype, "content", void 0);
__decorate([Prop({
  required: true
})], GetStartedCard.prototype, "buttonLabel", void 0);
__decorate([Prop({
  default: true
})], GetStartedCard.prototype, "closeButton", void 0);
GetStartedCard = __decorate([Component({
  components: {
    Icon,
    GridLayout,
    StandardButton
  }
})], GetStartedCard);
export default GetStartedCard;