import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
let WSelect = class WSelect extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "size", void 0);
    _defineProperty(this, "enabled", void 0);
    _defineProperty(this, "placeholder", void 0);
    _defineProperty(this, "value", void 0);
    _defineProperty(this, "loading", void 0);
    _defineProperty(this, "valueKey", void 0);
    _defineProperty(this, "labelKey", void 0);
    _defineProperty(this, "dataTest", void 0);
    _defineProperty(this, "options", void 0);
    _defineProperty(this, "realValue", '');
  }
  mounted() {
    this.realValue = this.value;
  }
  onBlur() {
    this.$emit('blur', this.realValue);
  }
  onChangeValue() {
    this.$emit('change', this.realValue);
  }
  onChangePropValue() {
    this.realValue = this.value;
  }
};
__decorate([Prop({
  default: 'default'
})], WSelect.prototype, "size", void 0);
__decorate([Prop({
  default: true
})], WSelect.prototype, "enabled", void 0);
__decorate([Prop({
  default: ''
})], WSelect.prototype, "placeholder", void 0);
__decorate([Prop({
  default: ''
})], WSelect.prototype, "value", void 0);
__decorate([Prop({
  default: false
})], WSelect.prototype, "loading", void 0);
__decorate([Prop({
  default: "value"
})], WSelect.prototype, "valueKey", void 0);
__decorate([Prop({
  default: "label"
})], WSelect.prototype, "labelKey", void 0);
__decorate([Prop()], WSelect.prototype, "dataTest", void 0);
__decorate([Prop({
  default: []
})], WSelect.prototype, "options", void 0);
__decorate([Watch('value')], WSelect.prototype, "onChangePropValue", null);
WSelect = __decorate([Component({
  emits: ['blur', 'change']
})], WSelect);
export default WSelect;