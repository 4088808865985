import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4ae8eb46"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 1,
  class: "wfGmp"
};
const _hoisted_2 = {
  key: 2,
  class: "wfDelayed"
};
const _hoisted_3 = {
  key: 0,
  class: "language"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_text_tag = _resolveComponent("text-tag");
  const _component_icon = _resolveComponent("icon");
  const _component_w_tool_tip = _resolveComponent("w-tool-tip");
  const _component_IconButton = _resolveComponent("IconButton");
  const _component_MenuSpeachCard = _resolveComponent("MenuSpeachCard");
  const _component_base_speach_card = _resolveComponent("base-speach-card");
  return _openBlock(), _createBlock(_component_base_speach_card, {
    speach: _ctx.speach,
    hover: _ctx.hover,
    outframe: _ctx.outframe,
    loadRegulated: _ctx.loadRegulated,
    isWFRejected: _ctx.isWFRejected,
    class: _normalizeClass(_ctx.isWFRejected ? 'wf-rejected' : ''),
    commentsEnabled: _ctx.commentsEnabled,
    "speach-date-to-display": _ctx.speachDateToDisplay
  }, {
    portrait: _withCtx(() => [!_ctx.speach.publication ? (_openBlock(), _createBlock(_component_text_tag, {
      key: 0,
      type: "light",
      class: "tag-undefined"
    }, {
      default: _withCtx(() => [_createElementVNode("span", null, _toDisplayString(_ctx.$t('thumbnail.speach.unpublished')), 1)]),
      _: 1
    })) : _createCommentVNode("", true), _ctx.speach.regulated_gmp ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_text_tag, {
      type: "light"
    }, {
      default: _withCtx(() => [_createVNode(_component_icon, {
        icon: "medal-outline"
      }), _createTextVNode("REG")]),
      _: 1
    })])) : _createCommentVNode("", true), _ctx.speach.delayed_till ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createVNode(_component_w_tool_tip, {
      width: 320,
      title: _ctx.$t('thumbnail.speach.delayed.title'),
      text: _ctx.$tc('thumbnail.speach.delayed.text', {
        date: _ctx.speach.delayed_till
      })
    }, {
      default: _withCtx(() => [_createVNode(_component_text_tag, {
        type: "dark"
      }, {
        default: _withCtx(() => [_createVNode(_component_icon, {
          icon: "clock-time-eleven-outline"
        }), _createTextVNode(" " + _toDisplayString(_ctx.speach.delayed_till), 1)]),
        _: 1
      })]),
      _: 1
    }, 8, ["title", "text"])])) : _createCommentVNode("", true), _ctx.speach.isTemplate ? (_openBlock(), _createBlock(_component_text_tag, {
      key: 3,
      type: "light",
      class: "tag-template"
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('thumbnail.speach.template')), 1)]),
      _: 1
    })) : _createCommentVNode("", true)]),
    topLeftSlot: _withCtx(() => [_ctx.speach.canShare && _ctx.speach.publication && !_ctx.outframe && !_ctx.speach.isTemplate ? (_openBlock(), _createBlock(_component_IconButton, {
      key: 0,
      color: "white",
      size: "small",
      icon: "share",
      onClick: _cache[0] || (_cache[0] = $event => _ctx.shareSpeach())
    })) : _createCommentVNode("", true)]),
    topRightSlot: _withCtx(() => [_ctx.speach.canEdit && !_ctx.outframe ? (_openBlock(), _createBlock(_component_IconButton, {
      key: 0,
      style: {
        "margin-right": "8px"
      },
      color: "white",
      size: "small",
      icon: "pencil-outline",
      onClick: _cache[1] || (_cache[1] = $event => _ctx.goToSpeachEdit())
    })) : _createCommentVNode("", true), _ctx.speach.canEdit && !_ctx.outframe ? (_openBlock(), _createBlock(_component_MenuSpeachCard, {
      key: 1,
      speach: _ctx.speach
    }, null, 8, ["speach"])) : _createCommentVNode("", true)]),
    descriptionMiddleRight: _withCtx(() => [!_ctx.speach.isTemplate ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.speach.language), 1)) : _createCommentVNode("", true)]),
    middleSlot: _withCtx(() => [_renderSlot(_ctx.$slots, "middleSlot", {}, undefined, true)]),
    _: 3
  }, 8, ["speach", "hover", "outframe", "loadRegulated", "isWFRejected", "class", "commentsEnabled", "speach-date-to-display"]);
}