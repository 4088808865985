import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
let WLink = class WLink extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "type", void 0);
  }
};
__decorate([Prop({
  default: 'primary'
})], WLink.prototype, "type", void 0);
WLink = __decorate([Component({})], WLink);
export default WLink;