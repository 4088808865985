import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-7dfc225a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "typeNotificationContainer"
};
const _hoisted_2 = {
  class: "label"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_toggle = _resolveComponent("w-toggle");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.keyLabel)), 1), _createVNode(_component_w_toggle, {
    value: _ctx.active,
    onChange: _ctx.onChangeToggle
  }, null, 8, ["value", "onChange"])]);
}