import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import axios from "axios";
import { confirmation } from "@/util/dialog";
import BigButton from "@/components/buttons/BigButton.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
let EditDocumentChapter = class EditDocumentChapter extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "addVideoUrl", void 0);
    _defineProperty(this, "addAudioUrl", void 0);
    _defineProperty(this, "chapitreId", void 0);
    _defineProperty(this, "courId", void 0);
  }
  addVideo() {
    confirmation(this.$t("switch.synchro.warning.video"), '', this.$t('messageBox.ok'), this.$t('messageBox.cancel')).then(() => {
      this.handleAddVideo();
    });
  }
  handleAddVideo() {
    axios.get(this.addVideoUrl).then(() => {
      this.reloadPanel();
    });
  }
  addAudio() {
    confirmation(this.$t("switch.synchro.warning.audio"), '', this.$t('messageBox.ok'), this.$t('messageBox.cancel')).then(() => {
      this.handleAddAudio();
    });
  }
  handleAddAudio() {
    axios.get(this.addAudioUrl).then(() => {
      this.reloadPanel();
    });
  }
  reloadPanel() {
    trig("AFFICHER_EDITION_CHAPITRE", this.chapitreId);
    trig("RAFRAICHIR_PLAN_COUR", this.courId);
  }
};
__decorate([Prop({
  required: true
})], EditDocumentChapter.prototype, "addVideoUrl", void 0);
__decorate([Prop({
  required: true
})], EditDocumentChapter.prototype, "addAudioUrl", void 0);
__decorate([Prop({
  required: true
})], EditDocumentChapter.prototype, "chapitreId", void 0);
__decorate([Prop({
  required: true
})], EditDocumentChapter.prototype, "courId", void 0);
EditDocumentChapter = __decorate([Component({
  components: {
    BigButton
  }
})], EditDocumentChapter);
export default EditDocumentChapter;