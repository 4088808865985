import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_checkbox = _resolveComponent("el-checkbox");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_checkbox, {
    class: "w-checkbox",
    modelValue: _ctx.internalChecked,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.internalChecked = $event),
    onChange: _ctx.handleChange
  }, null, 8, ["modelValue", "onChange"]), _createElementVNode("span", {
    onClick: _cache[1] || (_cache[1] =
    //@ts-ignore
    (...args) => _ctx.changeValueWithClick && _ctx.changeValueWithClick(...args)),
    class: "checkboxLabel"
  }, [_renderSlot(_ctx.$slots, "default", {}, undefined, true)])]);
}