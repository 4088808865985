import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1ae33482"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content"
};
const _hoisted_2 = {
  class: "content__title"
};
const _hoisted_3 = {
  class: "content__description"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_standard_button = _resolveComponent("standard-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t('notification.emailUnsubscribe.success.title').toString()), 1), _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('notification.emailUnsubscribe.success.description').toString()), 1), _ctx.displayGoToHomeBtn ? (_openBlock(), _createBlock(_component_standard_button, {
    key: 0,
    class: "go-to-home-btn",
    label: _ctx.$t('notification.emailUnsubscribe.success.goToHomeBtn').toString(),
    onAction: _cache[0] || (_cache[0] = $event => _ctx.goToHome())
  }, null, 8, ["label"])) : _createCommentVNode("", true)]);
}