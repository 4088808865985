import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import TextHighlight from "@/components/text/TextHighlight.vue";
import TextTag from "@/components/text/TextTag.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
let UserCell = class UserCell extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "user", void 0);
    _defineProperty(this, "searchTerm", void 0);
    _defineProperty(this, "secondLineContent", void 0);
    _defineProperty(this, "width", void 0);
    _defineProperty(this, "size", void 0);
  }
  get userIsInstanceOfUserInfos() {
    return "label" in this.user;
  }
  get imageSize() {
    if (this.size === 'small') {
      return '24px';
    } else if (this.size === 'large') {
      return '48px';
    }
    return '32px';
  }
  get cssVars() {
    return {
      '--parent-width': this.width,
      '--image-size': this.imageSize
    };
  }
};
__decorate([Prop({
  required: true
})], UserCell.prototype, "user", void 0);
__decorate([Prop({
  required: true
})], UserCell.prototype, "searchTerm", void 0);
__decorate([Prop({
  required: true
})], UserCell.prototype, "secondLineContent", void 0);
__decorate([Prop({
  default: '100%'
})], UserCell.prototype, "width", void 0);
__decorate([Prop({
  default: ''
})], UserCell.prototype, "size", void 0);
UserCell = __decorate([Component({
  components: {
    TextTag,
    TextHighlight
  }
})], UserCell);
export default UserCell;