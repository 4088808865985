import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import WToolTip from "@/components/tooltip/w-toolTip.vue";
import { Component, Prop, Vue, Emit } from "vue-facing-decorator";
let WTag = class WTag extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "textTag", void 0);
    _defineProperty(this, "round", void 0);
    _defineProperty(this, "size", void 0);
    _defineProperty(this, "closable", void 0);
    _defineProperty(this, "ellipsis", void 0);
    _defineProperty(this, "withToolTip", void 0);
  }
  removeTag(event) {
    return;
  }
};
__decorate([Prop({
  required: true
})], WTag.prototype, "textTag", void 0);
__decorate([Prop({
  default: false
})], WTag.prototype, "round", void 0);
__decorate([Prop({
  default: 'default'
}) // values : mini, small, medium, large
], WTag.prototype, "size", void 0);
__decorate([Prop({
  default: true
})], WTag.prototype, "closable", void 0);
__decorate([Prop({
  default: false
})], WTag.prototype, "ellipsis", void 0);
__decorate([Prop({
  default: false
})], WTag.prototype, "withToolTip", void 0);
__decorate([Emit("removeTag")], WTag.prototype, "removeTag", null);
WTag = __decorate([Component({
  components: {
    WToolTip
  }
})], WTag);
export default WTag;