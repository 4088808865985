import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
import DashBoardDoughnutChart from "@/components/dashboard/charts/DashBoardDoughnutChart.vue";
import EmptyChart from "@/components/dashboard/charts/EmptyChart.vue";
let AdvancedDoughnutChart = class AdvancedDoughnutChart extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "nameValues", void 0);
    _defineProperty(this, "title", void 0);
    _defineProperty(this, "labelTooltip", void 0);
    _defineProperty(this, "labelTooltipPlural", void 0);
    _defineProperty(this, "big", void 0);
    _defineProperty(this, "loading", void 0);
  }
};
__decorate([Prop({
  required: true
})], AdvancedDoughnutChart.prototype, "nameValues", void 0);
__decorate([Prop({
  default: ''
})], AdvancedDoughnutChart.prototype, "title", void 0);
__decorate([Prop({
  default: ""
})], AdvancedDoughnutChart.prototype, "labelTooltip", void 0);
__decorate([Prop({
  default: ""
})], AdvancedDoughnutChart.prototype, "labelTooltipPlural", void 0);
__decorate([Prop({
  default: false
})], AdvancedDoughnutChart.prototype, "big", void 0);
__decorate([Prop({
  default: false
})], AdvancedDoughnutChart.prototype, "loading", void 0);
AdvancedDoughnutChart = __decorate([Component({
  components: {
    EmptyChart,
    DashBoardDoughnutChart
  }
})], AdvancedDoughnutChart);
export default AdvancedDoughnutChart;