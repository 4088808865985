import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import Icon from "@/components/icon/Icon.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
let WAlert = class WAlert extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "title", void 0);
    _defineProperty(this, "description", void 0);
    _defineProperty(this, "type", void 0);
    _defineProperty(this, "showIcon", void 0);
    _defineProperty(this, "closable", void 0);
  }
  get icon() {
    if (this.type === 'info') {
      return 'information-outline';
    } else if (this.type === 'warning') {
      return 'alert-circle-outline';
    } else if (this.type === 'error') {
      return 'close-octagon-outline';
    }
    return 'check-circle-outline';
  }
};
__decorate([Prop({
  default: ''
})], WAlert.prototype, "title", void 0);
__decorate([Prop({
  default: ''
})], WAlert.prototype, "description", void 0);
__decorate([Prop({
  default: 'info'
}) // values: success, info, warning, error
], WAlert.prototype, "type", void 0);
__decorate([Prop({
  default: false
})], WAlert.prototype, "showIcon", void 0);
__decorate([Prop({
  default: true
})], WAlert.prototype, "closable", void 0);
WAlert = __decorate([Component({
  components: {
    Icon
  }
})], WAlert);
export default WAlert;