import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
import Icon from "@/components/icon/Icon.vue";
import axios from "axios";
import WToolTip from "@/components/tooltip/w-toolTip.vue";
let BaseSpeachCardBookmarkButton = class BaseSpeachCardBookmarkButton extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "speach", void 0);
    _defineProperty(this, "bookmarkedState", false);
  }
  mounted() {
    this.bookmarkedState = this.speach.isFavori;
  }
  toggleBookmarkState() {
    // optimistic status
    this.bookmarkedState = !this.bookmarkedState;
    return axios.get("/favori.FavoriController/toggleFavori", {
      params: {
        idCour: this.speach.id
      }
    });
  }
};
__decorate([Prop({
  required: true
})], BaseSpeachCardBookmarkButton.prototype, "speach", void 0);
BaseSpeachCardBookmarkButton = __decorate([Component({
  components: {
    WToolTip,
    Icon
  }
})], BaseSpeachCardBookmarkButton);
export default BaseSpeachCardBookmarkButton;