import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
import { formatDate } from "@/util/stringUtils";
let WorkflowRejectedBlock = class WorkflowRejectedBlock extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "comment", void 0);
    _defineProperty(this, "groupName", void 0);
    _defineProperty(this, "date", void 0);
    _defineProperty(this, "stepNumber", void 0);
    _defineProperty(this, "stepsCount", void 0);
  }
  get dateFormatted() {
    return formatDate(this.date);
  }
};
__decorate([Prop({
  required: true
})], WorkflowRejectedBlock.prototype, "comment", void 0);
__decorate([Prop({
  required: true
})], WorkflowRejectedBlock.prototype, "groupName", void 0);
__decorate([Prop({
  required: true
})], WorkflowRejectedBlock.prototype, "date", void 0);
__decorate([Prop({
  required: true
})], WorkflowRejectedBlock.prototype, "stepNumber", void 0);
__decorate([Prop({
  required: true
})], WorkflowRejectedBlock.prototype, "stepsCount", void 0);
WorkflowRejectedBlock = __decorate([Component({})], WorkflowRejectedBlock);
export default WorkflowRejectedBlock;