import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-e69c2d46"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  key: 1
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_tool_tip = _resolveComponent("w-tool-tip");
  const _component_el_tag = _resolveComponent("el-tag");
  return _openBlock(), _createBlock(_component_el_tag, {
    "disable-transitions": true,
    class: _normalizeClass([{
      ellipsisClass: this.ellipsis
    }, "wTag"]),
    closable: _ctx.closable,
    size: _ctx.size,
    key: _ctx.textTag,
    round: _ctx.round,
    onClose: _cache[0] || (_cache[0] = $event => _ctx.removeTag($event))
  }, {
    default: _withCtx(() => [_ctx.withToolTip ? (_openBlock(), _createBlock(_component_w_tool_tip, {
      key: 0,
      text: _ctx.textTag,
      placement: 'left'
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.textTag), 1)]),
      _: 1
    }, 8, ["text"])) : (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.textTag), 1))]),
    _: 1
  }, 8, ["class", "closable", "size", "round"]);
}