
export const isAdmin=()=>{
    const valueJSON=sessionStorage.getItem("user");
    if(!valueJSON){
        return false;
    }
    return JSON.parse(valueJSON).isAdmin
}

export const isAuthor=()=>{
    const valueJSON=sessionStorage.getItem("user");
    if(!valueJSON){
        return false;
    }
    return JSON.parse(valueJSON).isAuthor
}
export const isApprover=()=>{
  const valueJSON=sessionStorage.getItem("user");
  if(!valueJSON){
    return false;
  }
  return JSON.parse(valueJSON).isApprover
}

export const isReadOnly=()=>{
    const valueJSON=sessionStorage.getItem("user");
    if(!valueJSON){
        return false;
    }
    return JSON.parse(valueJSON).isReadOnly
}

export const isSuperAdmin=()=>{
    const valueJSON=sessionStorage.getItem("user");
    if(!valueJSON){
        return false;
    }
    return JSON.parse(valueJSON).isSuperAdmin
}
