export class recorderTypeModel {
  public recorderType: RecorderType = RecorderType.NONE;
  public isAudio: boolean = (this.recorderType === RecorderType.AUDIO
      || this.recorderType === RecorderType.AUDIO_PPT
      || this.recorderType === RecorderType.AUDIO_UPLOAD
      || this.recorderType === RecorderType.AUDIO_UPLOAD_PPT);
}

export enum RecorderType{
  NONE = "NONE",
  HTML = "HTML",
  EMBED = "EMBED",
  VIDEO = "VIDEO",
  PDF_UPLOAD = "PDF_UPLOAD",
  AUDIO = "AUDIO",
  PPT = "PPT",
  LIVE_EVENT = "LIVE_EVENT",
  QUIZZ = "QUIZZ",
  QUIZZ_EXAMEN = "QUIZZ_EXAMEN",
  QUESTION_OUVERTE = "QUESTION_OUVERTE",
  WEBCAM = "WEBCAM",
  WC_PPT = "WC_PPT",
  PDF_URL = "PDF_URL",
  VIDEO_PPT = "VIDEO_PPT",
  VIDEO_UPLOAD = "VIDEO_UPLOAD",
  VIDEO_UPLOAD_PPT = "VIDEO_UPLOAD_PPT",
  AUDIO_PPT = "AUDIO_PPT",
  AUDIO_UPLOAD = "AUDIO_UPLOAD",
  AUDIO_UPLOAD_PPT = "AUDIO_UPLOAD_PPT",
  REUSSITE = "REUSSITE",
  SONDAGE = "SONDAGE",
  ENQUETE = "ENQUETE",
  SCREEN = "SCREEN",
  SCREEN_AUDIO = "SCREEN_AUDIO",
  SCREEN_WEBCAM = "SCREEN_WEBCAM",
  WEET="WEET"
}
