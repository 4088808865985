import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
let Grid = class Grid extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "cols", void 0);
    _defineProperty(this, "color", void 0);
  }
  mounted() {
    const canvas = this.$refs.canvas;
    window.addEventListener("resize", this.drawGrid);
    this.drawGrid();
  }
  beforeDestroy() {
    window.removeEventListener("resize", this.drawGrid);
  }
  drawGrid() {
    const canvas = this.$refs.canvas;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      var _canvas$parentElement, _canvas$parentElement2;
      canvas.width = ((_canvas$parentElement = canvas.parentElement) === null || _canvas$parentElement === void 0 ? void 0 : _canvas$parentElement.clientWidth) || 0;
      canvas.height = ((_canvas$parentElement2 = canvas.parentElement) === null || _canvas$parentElement2 === void 0 ? void 0 : _canvas$parentElement2.clientHeight) || 0;
      const canvasWidth = canvas.width;
      const canvasHeight = canvas.height;
      const spaceBetweenLines = canvasWidth / this.cols;
      const rows = Math.ceil(canvasHeight / spaceBetweenLines);
      ctx.lineWidth = 1;
      ctx.strokeStyle = this.color;
      for (let i = 0; i < this.cols; i++) {
        ctx.beginPath();
        ctx.moveTo(i * spaceBetweenLines, 0);
        ctx.lineTo(i * spaceBetweenLines, canvasHeight);
        ctx.stroke();
      }
      for (let i = 0; i < rows; i++) {
        ctx.beginPath();
        ctx.moveTo(0, i * spaceBetweenLines);
        ctx.lineTo(canvasWidth, i * spaceBetweenLines);
        ctx.stroke();
      }
    }
  }
};
__decorate([Prop({
  required: true
})], Grid.prototype, "cols", void 0);
__decorate([Prop({
  default: "black"
})], Grid.prototype, "color", void 0);
Grid = __decorate([Component({})], Grid);
export default Grid;