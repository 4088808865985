import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { ElMessage } from "element-plus";
import Editor from "@tinymce/tinymce-vue";
import StandardButton from "@/components/buttons/StandardButton.vue";
import { LangEnum } from "@/models/LangEnum";
import { confirmation } from "@/util/dialog";
import { Component, Prop, Vue } from "vue-facing-decorator";
let WTinyMce = class WTinyMce extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "editorId", void 0);
    _defineProperty(this, "customConfig", void 0);
    _defineProperty(this, "text", void 0);
    _defineProperty(this, "saveLabel", '');
    _defineProperty(this, "cancelLabel", '');
    _defineProperty(this, "initConfig", null);
    _defineProperty(this, "initialText", '');
    _defineProperty(this, "liveText", '');
    _defineProperty(this, "formatUiLangToTinyMceFormat", lang => {
      switch (lang === null || lang === void 0 ? void 0 : lang.toLowerCase()) {
        case LangEnum.FR:
          return 'fr_FR';
        case LangEnum.EN:
          return 'en';
        case LangEnum.ES:
          return 'es';
        case LangEnum.DE:
          return 'de';
        default:
          return 'en';
      }
    });
  }
  updated() {
    if (this.text && !this.initialText) {
      this.initialText = this.text;
      this.liveText = this.text;
    }
    if (this.customConfig && !this.initConfig) {
      this.initConfig = {
        ...this.customConfig,
        branding: false,
        statusbar: false,
        language: this.formatUiLangToTinyMceFormat(this.customConfig.language)
      };
    }
  }
  onSaveChanges() {
    if (this.initialText === this.liveText) {
      ElMessage({
        message: this.$t('accessibility.statement.edition.nochanges'),
        type: 'info',
        showClose: false
      });
      return;
    }
    this.$emit('onSaveChanges', this.liveText);
  }
  onCancelChanges() {
    confirmation(this.$t('accessibility.statement.edition.on-cancel.dialog-body'), '', this.$t('accessibility.statement.edition.on-cancel.confirm'), this.$t('accessibility.statement.edition.on-cancel.abort')).then(() => {
      this.onConfirmCancelChanges();
    });
  }
  onConfirmCancelChanges() {
    this.$emit('onCancelChanges');
  }
};
__decorate([Prop({
  default: ''
})], WTinyMce.prototype, "editorId", void 0);
__decorate([Prop({
  default: null
})], WTinyMce.prototype, "customConfig", void 0);
__decorate([Prop({
  default: ''
})], WTinyMce.prototype, "text", void 0);
__decorate([Prop()], WTinyMce.prototype, "saveLabel", void 0);
__decorate([Prop()], WTinyMce.prototype, "cancelLabel", void 0);
WTinyMce = __decorate([Component({
  components: {
    tinymceEditor: Editor,
    StandardButton
  },
  emits: ["onSaveChanges", "onCancelChanges"]
})], WTinyMce);
export default WTinyMce;