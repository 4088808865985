import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon");
  const _component_user_cell = _resolveComponent("user-cell");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  return _openBlock(), _createBlock(_component_el_select, {
    modelValue: _ctx.realValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.realValue = $event),
    "value-key": "id",
    disabled: !_ctx.enabled,
    size: _ctx.size,
    loading: _ctx.loading,
    placeholder: _ctx.placeholderDisplay,
    multiple: _ctx.multiple,
    filterable: "",
    remote: "",
    "reserve-keyword": "",
    "remote-method": _ctx.updateUsersList,
    "collapse-tags": _ctx.collapseTags,
    "data-test": _ctx.dataTest,
    "popper-class": _ctx.classClickFilter
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.authors, author => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: author.id,
        label: author.label,
        value: author
      }, {
        default: _withCtx(() => [_ctx.isSelected(author) ? (_openBlock(), _createBlock(_component_icon, {
          key: 0,
          icon: "check",
          class: "checkBefore"
        })) : _createCommentVNode("", true), _createVNode(_component_user_cell, {
          user: author,
          "search-term": "",
          "second-line-content": author.email
        }, null, 8, ["user", "second-line-content"])]),
        _: 2
      }, 1032, ["label", "value"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue", "disabled", "size", "loading", "placeholder", "multiple", "remote-method", "collapse-tags", "data-test", "popper-class"]);
}