import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, withModifiers as _withModifiers } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon");
  const _component_w_tool_tip = _resolveComponent("w-tool-tip");
  return _openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["iconButtonVue", _ctx.className + ' ' + _ctx.color + ' ' + _ctx.size]),
    onClick: _cache[0] || (_cache[0] = _withModifiers(
    //@ts-ignore
    (...args) => _ctx.click && _ctx.click(...args), ["stop"]))
  }, [_ctx.tooltip !== '' ? (_openBlock(), _createBlock(_component_w_tool_tip, {
    key: 0,
    text: _ctx.tooltip,
    placement: _ctx.tooltipPosition
  }, {
    default: _withCtx(() => [_createElementVNode("div", {
      class: _normalizeClass(_ctx.isDisabled ? 'disabled' : '')
    }, [_createVNode(_component_icon, {
      icon: _ctx.getIcon,
      size: _ctx.size
    }, null, 8, ["icon", "size"])], 2)]),
    _: 1
  }, 8, ["text", "placement"])) : (_openBlock(), _createElementBlock("div", {
    key: 1,
    class: _normalizeClass(_ctx.isDisabled ? 'disabled' : '')
  }, [_createVNode(_component_icon, {
    icon: _ctx.getIcon,
    size: _ctx.size
  }, null, 8, ["icon", "size"]), _createTextVNode(" " + _toDisplayString(_ctx.text), 1)], 2))], 2);
}