import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import Icon from "@/components/icon/Icon.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
let Rating = class Rating extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "editable", void 0);
    _defineProperty(this, "value", void 0);
  }
  setNote(note) {
    if (this.editable) {
      this.$emit("input", note);
    }
  }
};
__decorate([Prop({
  default: false
})], Rating.prototype, "editable", void 0);
__decorate([Prop({
  default: 0
})], Rating.prototype, "value", void 0);
Rating = __decorate([Component({
  components: {
    Icon
  }
})], Rating);
export default Rating;