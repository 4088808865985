import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import axios from "axios";
import { Component, Prop, Vue } from "vue-facing-decorator";
import BigButton from "@/components/buttons/BigButton.vue";
import { RecorderType } from "@/models/recorderType.model";
import VoiceOver from "@/components/video/VoiceOver.vue";
import { Events } from "@/events/events";
import { confirmation } from "@/util/dialog";
let EditVideoChapter = class EditVideoChapter extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "addPresentationUrl", void 0);
    _defineProperty(this, "chapitreId", void 0);
    _defineProperty(this, "subtitlesEndpointUrl", void 0);
    _defineProperty(this, "couldAddDocument", void 0);
    _defineProperty(this, "couldManageSubtitles", void 0);
    _defineProperty(this, "couldVoiceOver", void 0);
    _defineProperty(this, "couldGenerateVoice", void 0);
    _defineProperty(this, "urlPlayer", void 0);
    _defineProperty(this, "startVoiceOver", false);
  }
  onCloseVoiceOver() {
    this.startVoiceOver = false;
    // trig(Events.AFFICHER_EDITION_CHAPITRE, {id: this.chapitreId})
    this.reloadPanel(undefined);
  }
  addDocument(event) {
    confirmation(this.$t("switch.synchro.warning.document"), '', this.$t('messageBox.ok'), this.$t('messageBox.cancel')).then(this.handleAddDocument);
  }
  handleAddDocument() {
    axios.get(this.addPresentationUrl).then(() => {
      this.reloadPanel(RecorderType.WC_PPT);
    });
  }
  reloadPanel(type = undefined) {
    trig(Events.AFFICHER_EDITION_CHAPITRE, {
      id: this.chapitreId,
      type: type
    });
  }
  addVoiceOver() {
    this.startVoiceOver = true;
  }
  async goToSubtitles() {
    if (this.subtitlesEndpointUrl) {
      let response = await axios.get("/api/transcript/token", {
        params: {
          chapterId: this.chapitreId
        }
      });
      window.location.assign(`${this.subtitlesEndpointUrl}?token=${response.data.token}&lng=${response.data.lng}`);
      return response.data;
    }
  }
};
__decorate([Prop({
  required: true
})], EditVideoChapter.prototype, "addPresentationUrl", void 0);
__decorate([Prop({
  required: true
})], EditVideoChapter.prototype, "chapitreId", void 0);
__decorate([Prop()], EditVideoChapter.prototype, "subtitlesEndpointUrl", void 0);
__decorate([Prop({
  default: true
})], EditVideoChapter.prototype, "couldAddDocument", void 0);
__decorate([Prop({
  required: true
})], EditVideoChapter.prototype, "couldManageSubtitles", void 0);
__decorate([Prop({
  default: true
})], EditVideoChapter.prototype, "couldVoiceOver", void 0);
__decorate([Prop({
  default: false
})], EditVideoChapter.prototype, "couldGenerateVoice", void 0);
__decorate([Prop({
  required: true
})], EditVideoChapter.prototype, "urlPlayer", void 0);
EditVideoChapter = __decorate([Component({
  components: {
    VoiceOver,
    BigButton
  }
})], EditVideoChapter);
export default EditVideoChapter;