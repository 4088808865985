import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
import Icon from "@/components/icon/Icon.vue";
import StandardButton from "@/components/buttons/StandardButton.vue";
import BigButton from "@/components/buttons/BigButton.vue";
import WInput from "@/components/form/w-input.vue";
import axios from "axios";
import { alertError } from "@/util/dialog";
import Loader from "@/components/loader/Loader.vue";
import { waitingJob } from "@/util/job.utils";
import LightButton from "@/components/buttons/LightButton.vue";
import IconButton from "@/components/buttons/IconButton.vue";
let AIStepsGenerator = class AIStepsGenerator extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "aiFiles", []);
    _defineProperty(this, "language", void 0);
    _defineProperty(this, "duration", void 0);
    _defineProperty(this, "openElement", []);
    _defineProperty(this, "generationInProgress", true);
    _defineProperty(this, "speachGenerated", null);
  }
  mounted() {
    this.generateLLMSteps();
  }
  generateLLMSteps() {
    this.generationInProgress = true;
    const formData = new FormData();
    this.aiFiles.forEach(el => {
      formData.append('jobIDs', el.jobID);
    });
    formData.append("language", this.language);
    formData.append("duration", this.duration);
    axios.post('/recorder/aiSpeachGenerator/generateSteps', formData).then(async resp => {
      this.waitStepGenerationProceed(resp.data);
    }).catch(e => {
      console.log(e);
      this.generationInProgress = false;
      console.error(e);
      alertError(this.$t('aiContent.error'));
    });
  }
  openAllElement() {
    this.openElement = [];
    if (this.speachGenerated) {
      let i = 0;
      for (const chapter of (_this$speachGenerated = this.speachGenerated) === null || _this$speachGenerated === void 0 ? void 0 : _this$speachGenerated.chapters) {
        var _this$speachGenerated;
        this.openElement.push("chapter" + i);
        i++;
      }
    }
  }
  regenerate() {
    this.generateLLMSteps();
  }
  async waitStepGenerationProceed(jobID) {
    try {
      const data = await waitingJob(jobID);
      this.speachGenerated = data;
      this.openAllElement();
      this.generationInProgress = false;
    } catch (e) {
      console.error(e);
      alertError(this.$t('aiContent.error'));
    }
  }
  async validateAiSpeach() {
    this.generationInProgress = true;
    axios.post('/recorder/aiSpeachGenerator/validateSteps', this.speachGenerated).then(async resp => {
      this.generationInProgress = false;
      document.location.href = resp.data;
    }).catch(e => {
      console.error(e);
      this.generationInProgress = false;
      alertError(this.$t('aiContent.error'));
    });
  }
};
__decorate([Prop({
  required: true
})], AIStepsGenerator.prototype, "aiFiles", void 0);
__decorate([Prop({
  required: true
})], AIStepsGenerator.prototype, "language", void 0);
__decorate([Prop({
  required: true
})], AIStepsGenerator.prototype, "duration", void 0);
AIStepsGenerator = __decorate([Component({
  components: {
    IconButton,
    LightButton,
    Loader,
    WInput,
    BigButton,
    StandardButton,
    Icon
  }
})], AIStepsGenerator);
export default AIStepsGenerator;