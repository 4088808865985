import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
let WSelect = class WSelect extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "value", void 0);
    _defineProperty(this, "realValue", false);
  }
  mounted() {
    this.realValue = this.value;
  }
  onChange(value) {
    //  this.$emit('change',value);
  }
};
__decorate([Prop({
  default: ''
})], WSelect.prototype, "value", void 0);
WSelect = __decorate([Component({})], WSelect);
export default WSelect;