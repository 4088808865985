import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import WDialog from "@/components/wrappers/w-dialog.vue";
import { Events } from "@/events/events";
import StandardButton from "@/components/buttons/StandardButton.vue";
import TitlePopUp from "@/components/text/TitlePopUp.vue";
import axios from "axios";
import { Component, Prop, Vue } from "vue-facing-decorator";
let AudioTrackLoader = class AudioTrackLoader extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "url", void 0);
    _defineProperty(this, "audioId", void 0);
    _defineProperty(this, "visible", false);
    _defineProperty(this, "currentSubtitleID", 0);
    _defineProperty(this, "previousSubtitleID", 0);
    _defineProperty(this, "timeoutAudioSelected", 0);
  }
  mounted() {
    register(Events.VIDEO_AUDIO_TRACK_SELECTED, this.onAudioSelected);
  }
  onAudioSelected(event, {
    subtitle_id,
    prev_subtitle_id
  }) {
    this.currentSubtitleID = subtitle_id;
    this.previousSubtitleID = prev_subtitle_id;
    axios.get(this.url + subtitle_id).then(async response => {
      if (response.status == 200) {
        const audio = document.getElementById(this.audioId);
        audio.src = response.data;
        this.closePopUp();
      } else if (response.status == 202) {
        this.visible = true;
        this.timeoutAudioSelected = setTimeout(() => {
          this.onAudioSelected(event, {
            subtitle_id,
            prev_subtitle_id
          });
        }, 2000);
      }
    }).catch(async err => {
      this.timeoutAudioSelected = setTimeout(() => {
        this.onAudioSelected(event, {
          subtitle_id,
          prev_subtitle_id
        });
      }, 2000);
    });
  }
  cancelGeneration() {
    clearTimeout(this.timeoutAudioSelected);
    trig(Events.RESET_AUDIO_TRACK, this.previousSubtitleID);
    this.closePopUp();
  }
  closePopUp() {
    this.visible = false;
  }
  beforeDestroy() {
    clearTimeout(this.timeoutAudioSelected);
    unregister(Events.VIDEO_AUDIO_TRACK_SELECTED, this.onAudioSelected);
  }
};
__decorate([Prop({
  required: true
})], AudioTrackLoader.prototype, "url", void 0);
__decorate([Prop({
  required: true
})], AudioTrackLoader.prototype, "audioId", void 0);
AudioTrackLoader = __decorate([Component({
  components: {
    TitlePopUp,
    StandardButton,
    WDialog
  }
})], AudioTrackLoader);
export default AudioTrackLoader;