export const listOfLanguage=($t:any)=>{

  return [
    { "value": "en-US", "label": $t('lang.en-US')},
    { "value": "en-GB", "label": $t('lang.en-UK')},
    { "value": "fr-FR", "label": $t('lang.fr-FR') },
    { "value": "es-ES", "label": $t('lang.es-ES') },
    { "value": "de-DE", "label": $t('lang.de-DE') },
    { "value": "pt-PT", "label": $t('lang.pt-PT') },
    { "value": "it-IT", "label": $t('lang.it-IT') },
    { "value": "zh-CN", "label": $t('lang.zh-CN') }
  ];
}
