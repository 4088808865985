import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f994a4a6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "getStartedContainer"
};
const _hoisted_2 = {
  class: "getStartedMainBlock"
};
const _hoisted_3 = {
  class: "imageContainer"
};
const _hoisted_4 = {
  class: "image"
};
const _hoisted_5 = {
  class: "contentContainer"
};
const _hoisted_6 = {
  class: "titleContainer"
};
const _hoisted_7 = {
  class: "content"
};
const _hoisted_8 = {
  class: "buttonContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_standard_button = _resolveComponent("standard-button");
  const _component_icon = _resolveComponent("icon");
  const _component_grid_layout = _resolveComponent("grid-layout");
  return _openBlock(), _createBlock(_component_grid_layout, null, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_renderSlot(_ctx.$slots, "icon", {}, undefined, true)])]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.title), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.content), 1)])]), _createElementVNode("div", _hoisted_8, [_createVNode(_component_standard_button, {
      label: _ctx.buttonLabel,
      onAction: _cache[0] || (_cache[0] = $event => _ctx.$emit('buttonClick'))
    }, null, 8, ["label"])]), _ctx.closeButton ? (_openBlock(), _createBlock(_component_icon, {
      key: 0,
      icon: "close",
      class: "closeGetStarted",
      onClick: _cache[1] || (_cache[1] = $event => _ctx.$emit('close'))
    })) : _createCommentVNode("", true)])]),
    _: 3
  });
}