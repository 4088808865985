import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import axios from "axios";
import { Component, Vue } from "vue-facing-decorator";
let GcuDisplay = class GcuDisplay extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "gcuContent", "");
  }
  mounted() {
    this.getGcuContent();
  }
  async getGcuContent() {
    const result = await axios.get("/gcu/getGcu", {
      params: {
        dataType: "json"
      }
    });
    this.gcuContent = result.data;
    await this.$nextTick();
    this.$emit("gcuLoaded");
  }
};
GcuDisplay = __decorate([Component({})], GcuDisplay);
export default GcuDisplay;
/*
    To set some specific style in the content of the GCU in the DataBase use :
     - classe="title"
     - classe="subtitle"
     - classe="text"

    those classes are defined in a non-scoped Stylesheet at the end of this document.
    Non-scoped, because the classes contained in the DB could not be interpreted by a scoped style
     */