import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
let EmptyChart = class EmptyChart extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "url", void 0);
    _defineProperty(this, "loading", void 0);
  }
};
__decorate([Prop({
  required: true
})], EmptyChart.prototype, "url", void 0);
__decorate([Prop({
  default: false
})], EmptyChart.prototype, "loading", void 0);
EmptyChart = __decorate([Component({})], EmptyChart);
export default EmptyChart;