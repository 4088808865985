import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0e006456"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "line"
};
const _hoisted_2 = {
  class: "line"
};
const _hoisted_3 = {
  class: "line"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_dashboard_speachs_tiles = _resolveComponent("dashboard-speachs-tiles");
  const _component_advanced_line_chart = _resolveComponent("advanced-line-chart");
  const _component_tile = _resolveComponent("tile");
  const _component_advanced_doughnut_chart = _resolveComponent("advanced-doughnut-chart");
  const _component_advanced_bar_chart = _resolveComponent("advanced-bar-chart");
  const _component_dashboard_table = _resolveComponent("dashboard-table");
  const _directive_observe_visibility = _resolveDirective("observe-visibility");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_dashboard_speachs_tiles), _createElementVNode("div", _hoisted_1, [_withDirectives((_openBlock(), _createBlock(_component_tile, {
    title: _ctx.$t('admin.dashboard.creationsCumulative.title'),
    width: 50,
    big: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_advanced_line_chart, {
      graphs: _ctx.creationsCumulativeGraphe,
      height: 355,
      title: _ctx.$t('admin.dashboard.creationsCumulative.title'),
      width: 500,
      "legend-tooltip-id": "creationsCumulativeLegend",
      cumulative: "",
      loading: _ctx.creationsCumulativeLoading
    }, null, 8, ["graphs", "title", "loading"])]),
    _: 1
  }, 8, ["title"])), [[_directive_observe_visibility, _ctx.visibilityChangedCreationCumulative]]), _withDirectives((_openBlock(), _createBlock(_component_tile, {
    title: _ctx.$t('admin.dashboard.speachesCategory.title'),
    width: 50,
    big: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_advanced_doughnut_chart, {
      "name-values": _ctx.speachesCategory,
      title: _ctx.$t('admin.dashboard.speachesCategory.title'),
      "label-tooltip": "admin.dashboard.speachesCategory.tooltip.label",
      "label-tooltip-plural": "admin.dashboard.speachesCategory.tooltip.labelPlural",
      loading: _ctx.speachesCategoryLoading
    }, null, 8, ["name-values", "title", "loading"])]),
    _: 1
  }, 8, ["title"])), [[_directive_observe_visibility, _ctx.visibilityChangedSpeachesCategory]])]), _createElementVNode("div", _hoisted_2, [_withDirectives((_openBlock(), _createBlock(_component_tile, {
    title: _ctx.$t('admin.dashboard.readingActivity.title'),
    width: 50,
    big: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_advanced_bar_chart, {
      "do-rotate": true,
      graphs: _ctx.readActivity,
      height: 355,
      title: _ctx.$t('admin.dashboard.readingActivity.title'),
      width: 500,
      "legend-tooltip-id": "readActivityLegend",
      loading: _ctx.readActivityLoading
    }, null, 8, ["graphs", "title", "loading"])]),
    _: 1
  }, 8, ["title"])), [[_directive_observe_visibility, _ctx.visibilityChangedReadActivity]]), _withDirectives((_openBlock(), _createBlock(_component_tile, {
    title: _ctx.$t('admin.dashboard.table.title.topSpeachByRead'),
    width: 50,
    big: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_dashboard_table, {
      "data-table": _ctx.topSpeachByWatch,
      params: _ctx.topSpeachByWatchParams,
      loading: _ctx.topSpeachWatchLoading
    }, null, 8, ["data-table", "params", "loading"])]),
    _: 1
  }, 8, ["title"])), [[_directive_observe_visibility, _ctx.visibilityChangedTopSpeachWatch]])]), _createElementVNode("div", _hoisted_3, [_withDirectives((_openBlock(), _createBlock(_component_tile, {
    title: _ctx.$t('admin.dashboard.readingCumulative.title'),
    width: 50,
    big: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_advanced_line_chart, {
      graphs: _ctx.readingCumulativeGraphe,
      height: 355,
      title: _ctx.$t('admin.dashboard.readingCumulative.title'),
      width: 500,
      "legend-tooltip-id": "readingCumulativeLegend",
      cumulative: "",
      loading: _ctx.readingCumulativeLoading
    }, null, 8, ["graphs", "title", "loading"])]),
    _: 1
  }, 8, ["title"])), [[_directive_observe_visibility, _ctx.visibilityChangedReadingCumulative]]), _withDirectives((_openBlock(), _createBlock(_component_tile, {
    title: _ctx.$t('admin.dashboard.creation.tunnel.title'),
    width: 50,
    big: ""
  }, {
    default: _withCtx(() => [_createVNode(_component_advanced_bar_chart, {
      "do-rotate": false,
      graphs: _ctx.creationTunnel,
      height: 355,
      title: _ctx.$t('admin.dashboard.creation.tunnel.title'),
      width: 500,
      "legend-tooltip-id": "creationTunnelLegend",
      loading: _ctx.creationLoading
    }, null, 8, ["graphs", "title", "loading"])]),
    _: 1
  }, 8, ["title"])), [[_directive_observe_visibility, _ctx.visibilityChangedCreation]])])], 64);
}