import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.for-each.js";
import "core-js/modules/esnext.iterator.map.js";
import { __decorate } from "tslib";
import axios from "axios";
import { Events } from "@/events/events";
import { Plus } from '@element-plus/icons-vue';
import { getDifferenceBetweenArraysOfUsers } from "@/util/arrayUtils";
import { goToParcours } from "@/util/routeUtil";
import { markRaw } from "vue";
import WElMessage from "@/components/wrappers/w-elmessage.vue";
import WInput from "@/components/form/w-input.vue";
import StandardButton from "@/components/buttons/StandardButton.vue";
import GridLayout from "@/components/layout/GridLayout.vue";
import UserSearchCompact from "@/components/search/UserSearchCompact.vue";
import WTag from "@/components/wrappers/w-tag.vue";
import WCheckboxGroup from "@/components/form/w-checkbox-group.vue";
import WAlert from "@/components/wrappers/w-alert.vue";
import ImageBackground from "@/components/layout/ImageBackground.vue";
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
let PathEditor = class PathEditor extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "pathId", '');
    _defineProperty(this, "title", '');
    _defineProperty(this, "description", '');
    _defineProperty(this, "image", '');
    _defineProperty(this, "selectedTabDefault", void 0);
    _defineProperty(this, "selectedTab", '');
    _defineProperty(this, "plus", markRaw(Plus));
    _defineProperty(this, "path", null);
    _defineProperty(this, "groups", []);
    _defineProperty(this, "checkedGroups", []);
    _defineProperty(this, "canPublish", false);
    _defineProperty(this, "currentUserUID", null);
  }
  updateUrl() {
    const pathUpdated = this.pathId + '?selectedTab=' + this.selectedTab;
    window.history.pushState(null, document.title, '/recorder/editionparcour/' + pathUpdated);
  }
  mounted() {
    this.selectedTab = this.selectedTabDefault;
    this.getGroups().then(groups => this.groups = groups);
    this.updatePath();
    this.getCurrentUser();
  }
  getCurrentUser() {
    const valueJSON = sessionStorage.getItem("user") || "";
    this.currentUserUID = JSON.parse(valueJSON).uid;
  }
  get isOwnerOfThisPath() {
    var _this$path;
    return this.currentUserUID === ((_this$path = this.path) === null || _this$path === void 0 ? void 0 : _this$path.user.userID);
  }
  get otherUserTranslatedMessage() {
    var _this$path2, _this$path3;
    return this.$t('path.edit.fromOtherUser', {
      firstName: (_this$path2 = this.path) === null || _this$path2 === void 0 ? void 0 : _this$path2.user.firstName,
      lastName: (_this$path3 = this.path) === null || _this$path3 === void 0 ? void 0 : _this$path3.user.lastName
    });
  }
  updatePath() {
    this.getPath().then(path => {
      this.path = path;
      this.canPublish = this.path.items.length > 0;
      this.checkedGroups = path.assignment.groups.map(group => group.id);
      if (!this.canPublish) {
        this.selectedTab = 'AVAILABLE';
      }
    });
  }
  async getPath() {
    let result = await axios.get(`/api/v2/learningPath/${this.pathId}`);
    return result.data;
  }
  async getGroups() {
    let result = await axios.get(`/api/v2/learningPath/getAllGroups`);
    return result.data;
  }
  onBlurTitle(newTitle) {
    if (this.path) {
      this.path.title = newTitle;
      axios.put(`/api/v2/learningPath`, {
        path: JSON.stringify(this.path)
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(() => {
        WElMessage.showSuccessMessage(this.$t("path.edit.alert.titleUpdated"));
      }, error => {
        WElMessage.showErrorMessage(error);
      });
    }
  }
  onBlurDescription(newDescription) {
    if (this.path) {
      this.path.description = newDescription;
      axios.put(`/api/v2/learningPath`, {
        path: JSON.stringify(this.path)
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(() => {
        WElMessage.showSuccessMessage(this.$t("path.edit.alert.descriptionUpdated"));
      }, error => {
        WElMessage.showErrorMessage(error);
      });
    }
  }
  onChangeOneAssignment(changed, label, value) {
    axios.put(`/api/v2/learningPath/${this.pathId}/changeGroup`, null, {
      params: {
        dataType: "json",
        groupId: value,
        add: changed
      }
    }).then(() => {
      WElMessage.showSuccessMessage(this.$t("path.edit.alert.assignmentUpdated"));
    }, error => {
      WElMessage.showErrorMessage(error);
    });
  }
  get goodImage() {
    if (this.image !== '') {
      return this.image;
    } else {
      return '/public/images/vignette_defaut.png';
    }
  }
  changePicture() {
    trig(Events.OPEN_POPIN_IMAGE, 'popInImageParcour');
  }
  addSpeachTo(speachID) {
    axios.put(`/api/v2/learningPath/${this.pathId}/addSpeach/${speachID}`).then(() => {
      WElMessage.showSuccessMessage(this.$t("path.edit.alert.speachAdded"));
      this.updatePath();
    }, error => {
      WElMessage.showErrorMessage(error);
    });
  }
  removeSpeachFrom(speachID) {
    if (this.path) {
      axios.put(`/api/v2/learningPath/${this.pathId}/removeSpeach/${speachID}`).then(() => {
        WElMessage.showSuccessMessage(this.$t("path.edit.alert.speachRemoved"));
        this.updatePath();
      }, error => {
        WElMessage.showErrorMessage(error);
      });
    }
  }
  selectUsers(users) {
    if (this.path) {
      let currentUsers = this.path.assignment.users || [];
      const usersToAdd = getDifferenceBetweenArraysOfUsers(users, currentUsers);
      const usersToDelete = getDifferenceBetweenArraysOfUsers(currentUsers, users);
      for (const userToAdd of usersToAdd) {
        this.addOrDeleteUser(userToAdd, true);
        currentUsers.push(userToAdd);
      }
      for (const userToDelete of usersToDelete) {
        this.addOrDeleteUser(userToDelete, false);
        currentUsers = currentUsers.filter(user => user !== userToDelete);
      }
      this.path.assignment.users = currentUsers;
      this.$forceUpdate();
    }
  }
  deleteOneUser(userToDelete) {
    if (this.path) {
      let currentUsers = this.path.assignment.users || [];
      this.addOrDeleteUser(userToDelete, false);
      currentUsers = currentUsers.filter(user => user !== userToDelete);
      this.path.assignment.users = currentUsers;
      this.$forceUpdate();
    }
  }
  addOrDeleteUser(user, add) {
    axios.put(`/api/v2/learningPath/${this.pathId}/changeUser`, null, {
      params: {
        dataType: "json",
        userId: user.userID,
        add: add
      }
    }).then(() => {
      WElMessage.showSuccessMessage(this.$t("path.edit.alert.usersUpdated"));
    }, error => {
      WElMessage.showErrorMessage(error);
    });
  }
  publishPath() {
    if (this.canPublish) {
      axios.put(`/api/v2/learningPath/${this.pathId}/publish`).then(() => {
        WElMessage.showSuccessMessage(this.$t("path.edit.alert.published"));
        if (this.path) {
          goToParcours(this.path);
        }
      }, error => {
        WElMessage.showErrorMessage(error);
      });
    }
  }
  changeSpeachesOrder(listSpeaches) {
    let listSpeachIds = '';
    listSpeaches.forEach(speach => listSpeachIds += (listSpeachIds !== '' ? ';' : '') + speach.speachID);
    axios.put(`/api/v2/learningPath/${this.pathId}/recordOrder`, null, {
      params: {
        dataType: "json",
        order: listSpeachIds
      }
    }).then(() => {
      WElMessage.showSuccessMessage(this.$t("path.edit.alert.orderChanged"));
    }, error => {
      WElMessage.showErrorMessage(error);
    });
  }
};
__decorate([Prop({
  required: true
})], PathEditor.prototype, "pathId", void 0);
__decorate([Prop({
  required: true
})], PathEditor.prototype, "title", void 0);
__decorate([Prop({
  required: true
})], PathEditor.prototype, "description", void 0);
__decorate([Prop({
  required: true
})], PathEditor.prototype, "image", void 0);
__decorate([Prop({
  default: 'SELECTED'
})], PathEditor.prototype, "selectedTabDefault", void 0);
__decorate([Watch("selectedTab", {
  deep: false,
  immediate: false
})], PathEditor.prototype, "updateUrl", null);
PathEditor = __decorate([Component({
  components: {
    ImageBackground,
    WAlert,
    WCheckboxGroup,
    WTag,
    UserSearchCompact,
    GridLayout,
    StandardButton,
    WInput
  }
})], PathEditor);
export default PathEditor;