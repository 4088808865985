import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.filter.js";
import "core-js/modules/esnext.iterator.find.js";
import "core-js/modules/esnext.iterator.map.js";
import { __decorate } from "tslib";
import UserCell from "@/components/user/UserCell.vue";
import StandardButton from "@/components/buttons/StandardButton.vue";
import { deleteSpeach, goToMonitorSpeach, goToSpeach, goToSpeachEdition, goToTemplates } from "@/util/routeUtil";
import axios from "axios";
import WElMessage from "@/components/wrappers/w-elmessage.vue";
import WInput from "@/components/form/w-input.vue";
import WTag from "@/components/wrappers/w-tag.vue";
import Icon from "@/components/icon/Icon.vue";
import WDialog from "@/components/wrappers/w-dialog.vue";
import WToolTip from "@/components/tooltip/w-toolTip.vue";
import GridLayout from "@/components/layout/GridLayout.vue";
import { SpeachState } from "@/models/speachStateEnum";
import { VisibilityEnum } from "@/models/visibilityEnum";
import { Events } from "@/events/events";
import WToggle from "@/components/form/w-toggle.vue";
import { confirmation } from "@/util/dialog";
import WSelectUsers from "@/components/form/w-select-users.vue";
import ScormExportDialog from "@/components/speach/ScormExportDialog.vue";
import WSelectAdvanced from "@/components/form/w-select-advanced.vue";
import delay from "delay";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import WText from "@/components/wrappers/w-text.vue";
import WDate from "@/components/wrappers/w-date.vue";
import { ElMessageBox } from "element-plus/es";
import { Component, Prop, Vue, Emit, Watch } from "vue-facing-decorator";
import { isNullOrUndefined } from "@tinymce/tinymce-vue/lib/es2015/main/ts/Utils";
let SpeachDetails = class SpeachDetails extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "speach", void 0);
    _defineProperty(this, "classClickFilter", void 0);
    _defineProperty(this, "visible", true);
    _defineProperty(this, "scormClassicEnabled", void 0);
    _defineProperty(this, "BASE_API_SPEACHES", '/api/v2/admin/speach');
    _defineProperty(this, "BASE_API_SPEACHES_FOR_METADATA", '/api/v2/speach');
    _defineProperty(this, "availableCategories", []);
    _defineProperty(this, "availableGroups", []);
    _defineProperty(this, "scormExportPopup", false);
    _defineProperty(this, "goToTemplatesPopup", false);
    _defineProperty(this, "selectedTab", 'GENERAL');
    _defineProperty(this, "loaded", false);
    _defineProperty(this, "coGroupsIds", []);
    _defineProperty(this, "affectedGroupsIds", []);
  }
  async mounted() {
    var _this$speach, _this$speach2;
    this.coGroupsIds = ((_this$speach = this.speach) === null || _this$speach === void 0 ? void 0 : _this$speach.authorsInfos.coEditorGroups.map(coGroup => coGroup.id)) || [];
    this.affectedGroupsIds = ((_this$speach2 = this.speach) === null || _this$speach2 === void 0 ? void 0 : _this$speach2.affectationUsersInfos.affectedGroups.map(group => group.id)) || [];
    await this.initAvailableGroups();
    await this.refreshSpeach();
    // wait end of select listener before define page loaded
    await delay(500);
    this.loaded = true;
  }
  get SpeachState() {
    return SpeachState;
  }
  get VisibilityEnum() {
    return VisibilityEnum;
  }
  get availableStates() {
    return [{
      id: SpeachState.PUBLIE,
      name: this.$t('admin.dashboard.speaches.state.published')
    }, {
      id: SpeachState.NON_PUBLIE,
      name: this.$t('admin.dashboard.speaches.state.notPublished')
    }];
  }
  get availableVisibilities() {
    return [{
      id: VisibilityEnum.PUBLIC,
      name: this.$t('admin.dashboard.speaches.visibility.public')
    }, {
      id: VisibilityEnum.PRIVE,
      name: this.$t('admin.dashboard.speaches.visibility.private')
    }, {
      id: VisibilityEnum.SEMI_PRIVE,
      name: this.$t('admin.dashboard.speaches.visibility.semiPrivate')
    }, {
      id: VisibilityEnum.RESTREINT,
      name: this.$t('admin.dashboard.speaches.visibility.restricted')
    }];
  }
  get speachDueDateIsSet() {
    var _this$speach3;
    return ((_this$speach3 = this.speach) === null || _this$speach3 === void 0 ? void 0 : _this$speach3.dueDateTimestamp) !== undefined;
  }
  async initAvailableGroups() {
    await axios.get(this.BASE_API_SPEACHES + '/groups').then(response => {
      this.availableGroups = response.data;
    });
  }
  async refreshSpeach() {
    await this.initCategories();
    await this.getMetadata();
  }
  async initCategories() {
    await axios.get(this.BASE_API_SPEACHES + '/categories').then(response => {
      this.availableCategories = response.data;
    });
  }
  async getMetadata() {
    await axios.get(this.BASE_API_SPEACHES + '/getMetadata', {
      params: {
        speachID: this.speach.speachID
      }
    }).then(response => {
      this.speach.dueDateTimestamp = response.data.dueDateTimestamp;
      this.speach.unpublishAfterDueDate = response.data.unpublishAfterDueDate;
    });
  }
  goToSpeach() {
    goToSpeach(this.speach);
  }
  goToSpeachEdit() {
    goToSpeachEdition(this.speach);
  }
  shareSpeach() {
    trig(Events.DIFFUSER_COUR, {
      id: this.speach.id,
      isPublie: this.speach.publication,
      isDuringDelayPeriod: this.speach.delayed_till
    });
  }
  goToMonitorSpeach() {
    goToMonitorSpeach(this.speach);
  }
  async duplicateSpeach() {
    await axios.put(this.BASE_API_SPEACHES + '/duplicate', null, {
      params: {
        dataType: "json",
        speachId: this.speach.speachID
      }
    }).then(() => {
      WElMessage.showSuccessMessage(this.$t("admin.dashboard.speaches.toasts.speachDuplicated"));
    }, () => {
      WElMessage.showErrorMessage(this.$t("admin.dashboard.speaches.toasts.speachDuplicatedFailed"));
    }).finally(() => {
      this.scormExportPopup = false;
      this.reinitSearch();
    });
  }
  async updateSpeach() {
    if (!this.loaded) {
      return;
    }
    await axios.put(this.BASE_API_SPEACHES + '/update', {
      speach: JSON.stringify(this.speach)
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(() => {
      WElMessage.showSuccessMessage(this.$t("admin.dashboard.speaches.toasts.speachUpdated"));
    }, () => {
      WElMessage.showErrorMessage(this.$t("admin.dashboard.speaches.toasts.speachUpdateFailed"));
    });
  }
  onChangeCategory(value) {
    this.speach.category = this.availableCategories.find(cat => cat.id === value) || this.speach.category;
    this.updateSpeach();
  }
  onChangeState(value) {
    this.speach.state = value;
    this.updateSpeach();
  }
  onChangeVisibility(value) {
    this.speach.visibility = value;
    this.updateSpeach();
  }
  onChangeAccessCode(value) {
    this.speach.accessCode = value;
    this.updateSpeach();
  }
  onChangePinned(value) {
    this.speach.isPinnedOnHome = value;
    this.updateSpeach();
  }
  onChangeAuthor(value) {
    this.speach.authorId = value.id;
    this.updateSpeach();
    this.speach.authorsInfos.author = value;
  }
  async onChangeCoAuthors(value) {
    if (!this.loaded) {
      return;
    }
    await axios.put(this.BASE_API_SPEACHES + '/updateCoEditors', {
      speach: JSON.stringify(this.speach),
      coEditors: JSON.stringify(value)
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(() => {
      WElMessage.showSuccessMessage(this.$t("admin.dashboard.users.sidebar.userUpdated"));
      this.speach.authorsInfos.coEditors = value;
    }, error => {
      WElMessage.showErrorMessage(error);
    });
  }
  deleteOneCoAuthor(coAuthorToDelete) {
    let currentCoAuthors = this.speach.authorsInfos.coEditors || [];
    currentCoAuthors = currentCoAuthors.filter(coAuthor => coAuthor !== coAuthorToDelete);
    this.speach.authorsInfos.coEditors = currentCoAuthors;
    this.onChangeCoAuthors(this.speach.authorsInfos.coEditors);
  }
  async onChangeCoGroups(value) {
    this.coGroupsIds = value;
    if (!this.loaded) {
      return;
    }
    await axios.put(this.BASE_API_SPEACHES + '/updateCoEditors', {
      speach: JSON.stringify(this.speach),
      coGroups: JSON.stringify(value)
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(() => {
      WElMessage.showSuccessMessage(this.$t("admin.dashboard.users.sidebar.userUpdated"));
      this.speach.authorsInfos.coEditorGroups = [];
      for (const groupId of value) {
        const groupToAdd = this.availableGroups.find(group => group.id === groupId);
        if (!groupToAdd) {
          return;
        }
        const groupAdAuthorInfos = {
          id: groupToAdd.id,
          label: groupToAdd.name,
          email: '',
          imgUrl: ''
        };
        this.speach.authorsInfos.coEditorGroups.push(groupAdAuthorInfos);
      }
    }, error => {
      WElMessage.showErrorMessage(error);
    });
  }
  deleteOneCoGroup(coGroupToDelete) {
    const coGroupsIdsToKeep = this.coGroupsIds.filter(coGroup => coGroup !== coGroupToDelete);
    this.onChangeCoGroups(coGroupsIdsToKeep);
  }
  async onChangeAffectedUsers(value) {
    if (!this.loaded) {
      return;
    }
    await axios.put(this.BASE_API_SPEACHES + '/updateAffectedUsers', {
      speachID: this.speach.speachID,
      users: JSON.stringify(value)
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(() => {
      WElMessage.showSuccessMessage(this.$t("admin.dashboard.speaches.toasts.speachUpdated"));
      this.speach.affectationUsersInfos.affectedUsers = value;
    }, error => {
      WElMessage.showErrorMessage(error);
    });
  }
  deleteOneAffectedUser(userToDelete) {
    let currentAffectedUsers = this.speach.affectationUsersInfos.affectedUsers || [];
    currentAffectedUsers = currentAffectedUsers.filter(user => user !== userToDelete);
    this.speach.affectationUsersInfos.affectedUsers = currentAffectedUsers;
    this.onChangeAffectedUsers(this.speach.affectationUsersInfos.affectedUsers);
  }
  async onChangeAffectedGroups(value) {
    this.affectedGroupsIds = value;
    if (!this.loaded) {
      return;
    }
    await axios.put(this.BASE_API_SPEACHES + '/updateAffectedGroups', {
      speachID: this.speach.speachID,
      groups: JSON.stringify(value)
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(() => {
      WElMessage.showSuccessMessage(this.$t("admin.dashboard.speaches.toasts.speachUpdated"));
      this.speach.affectationUsersInfos.affectedGroups = [];
      for (const groupId of value) {
        const groupToAdd = this.availableGroups.find(group => group.id === groupId);
        if (!groupToAdd) {
          return;
        }
        const groupInfos = {
          id: groupToAdd.id,
          label: groupToAdd.name,
          email: '',
          imgUrl: ''
        };
        this.speach.affectationUsersInfos.affectedGroups.push(groupInfos);
      }
    }, error => {
      WElMessage.showErrorMessage(error);
    });
  }
  deleteOneAffectedGroup(groupToDelete) {
    const affectedGroupsIds = this.affectedGroupsIds.filter(group => group !== groupToDelete);
    this.onChangeAffectedGroups(affectedGroupsIds);
  }
  async createTemplate() {
    await axios.put(this.BASE_API_SPEACHES + '/createTemplate', null, {
      params: {
        dataType: "json",
        speachId: this.speach.id
      }
    }).then(() => {
      this.goToTemplatesPopup = true;
    }, () => {
      WElMessage.showErrorMessage(this.$t("admin.dashboard.speaches.toasts.templateFailed"));
    }).finally(() => {
      this.reinitSearch();
    });
  }
  goToTemplates() {
    this.goToTemplatesPopup = false;
    goToTemplates();
  }
  reinitSearch() {
    return;
  }
  archiveSpeach() {
    ElMessageBox.confirm(this.$t('thumbnail.menu.archiveConfirm'), '', {
      confirmButtonText: this.$t('messageBox.ok'),
      cancelButtonText: this.$t('messageBox.cancel'),
      customClass: "speach-archive-confirm-message",
      confirmButtonClass: "speach-archive-confirm-button",
      autofocus: false // TODO : remove when upgrading element-plus to >= 2.6
    }).then(async () => {
      await deleteSpeach(this.speach).then(() => {
        WElMessage.showSuccessMessage(this.$t("admin.dashboard.speaches.toasts.speachArchived"));
      }, () => {
        WElMessage.showErrorMessage(this.$t("admin.dashboard.speaches.toasts.speachArchiveFailed"));
      }).finally(() => {
        this.reinitSearch();
      });
    });
  }
  cancelWorkflow() {
    confirmation(this.$t('admin.dashboard.speaches.sidebar.cancelWorkflow.confirm'), '', this.$t('messageBox.ok'), this.$t('messageBox.cancel')).then(async () => {
      await axios.put(this.BASE_API_SPEACHES + '/cancelWorkflow', null, {
        params: {
          dataType: "json",
          speachId: this.speach.speachID
        }
      }).then(() => {
        WElMessage.showSuccessMessage(this.$t("admin.dashboard.speaches.toasts.workflowCanceled"));
      }, () => {
        WElMessage.showErrorMessage(this.$t("admin.dashboard.speaches.toasts.workflowCanceledFailed"));
      }).finally(() => {
        this.reinitSearch();
      });
    });
  }
  async changeUnpublishAfterDueDate(value) {
    await axios.put(this.BASE_API_SPEACHES_FOR_METADATA + '/setUnpublishedAfterDueDate', {
      speachID: this.speach.speachID,
      unpublish: `${value}`
    }, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(() => {
      this.speach.unpublishAfterDueDate = value;
      WElMessage.showSuccessMessage(this.$t("admin.dashboard.speaches.toasts.speachUpdated"));
    }, () => {
      WElMessage.showErrorMessage(this.$t("admin.dashboard.speaches.toasts.speachUpdateFailed"));
    });
  }
  isDelayed(speach) {
    return !isNullOrUndefined(speach.delayedTillTimestamp) && speach.delayedTillTimestamp > Date.now() || !isNullOrUndefined(speach.futureVersionDelayedTillTimestamp) && speach.futureVersionDelayedTillTimestamp > Date.now();
  }
};
__decorate([Prop({
  required: true
})], SpeachDetails.prototype, "speach", void 0);
__decorate([Prop({
  default: ''
})], SpeachDetails.prototype, "classClickFilter", void 0);
__decorate([Prop()], SpeachDetails.prototype, "visible", void 0);
__decorate([Prop({
  default: false
})], SpeachDetails.prototype, "scormClassicEnabled", void 0);
__decorate([Watch('speach')], SpeachDetails.prototype, "refreshSpeach", null);
__decorate([Emit('reinitSearch')], SpeachDetails.prototype, "reinitSearch", null);
SpeachDetails = __decorate([Component({
  components: {
    WDate,
    WText,
    ConfirmDialog,
    WSelectAdvanced,
    ScormExportDialog,
    WSelectUsers,
    WToggle,
    GridLayout,
    WToolTip,
    WDialog,
    Icon,
    WTag,
    WInput,
    StandardButton,
    UserCell
  }
})], SpeachDetails);
export default SpeachDetails;