import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import Icon from "@/components/icon/Icon.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
let SearchBarSearchField = class SearchBarSearchField extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "searchUser", void 0);
    _defineProperty(this, "initialSearchTerm", void 0);
    _defineProperty(this, "searchTerm", "");
    _defineProperty(this, "hasFocus", false);
  }
  get getPlaceHolder() {
    if (this.searchUser) {
      return this.$t("searchbar.placeholder.speach.all");
    } else {
      return this.$t("searchbar.placeholder.speach.only");
    }
  }
  mounted() {
    if (this.initialSearchTerm) {
      this.searchTerm = this.initialSearchTerm;
      this.emitSearchTermChangedEvent();
    }
  }
  resetSearchTerm() {
    this.searchTerm = '';
    this.emitSearchTermChangedEvent();
  }
  emitSearchTermChangedEvent() {
    this.$emit("searchtermchanged", {
      searchTerm: this.searchTerm
    });
  }
  keyUpSearchInput(e) {
    // si l'utilisateur modifie le champ de recherche et que le champ devient vide,
    // on le passe à null pour masquer la page de recherche
    if (this.searchTerm === "") {
      this.resetSearchTerm();
    } else if (e.keyCode === 13) {
      if (this.searchTerm === null) {
        // si l'utilisateur clique sur ENTER on lance la recherche
        // si le champ de recherche est null on le passe à vide pour la recherche
        this.searchTerm = "";
        // modification de l'url pour permettre de revenir sur la recherche en cas de retour arrière
        this.emitSearchTermChangedEvent();
      } else {
        this.$emit("enterkeypressed", {
          searchTerm: this.searchTerm
        });
      }
    } else if (this.searchTerm) {
      this.emitSearchTermChangedEvent();
    }
  }
};
__decorate([Prop({
  default: false
})], SearchBarSearchField.prototype, "searchUser", void 0);
__decorate([Prop({
  required: true
})], SearchBarSearchField.prototype, "initialSearchTerm", void 0);
SearchBarSearchField = __decorate([Component({
  components: {
    Icon
  },
  emits: ['searchtermchanged']
})], SearchBarSearchField);
export default SearchBarSearchField;