import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
import DashboardBarChart from "@/components/dashboard/charts/DashboardBarChart.vue";
import EmptyChart from "@/components/dashboard/charts/EmptyChart.vue";
let AdvancedBarChart = class AdvancedBarChart extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "legendTooltipId", void 0);
    _defineProperty(this, "width", void 0);
    _defineProperty(this, "height", void 0);
    _defineProperty(this, "graphs", void 0);
    _defineProperty(this, "doRotate", void 0);
    _defineProperty(this, "title", void 0);
    _defineProperty(this, "big", void 0);
    _defineProperty(this, "loading", void 0);
  }
};
__decorate([Prop({
  default: ''
})], AdvancedBarChart.prototype, "legendTooltipId", void 0);
__decorate([Prop({
  default: 500
})], AdvancedBarChart.prototype, "width", void 0);
__decorate([Prop({
  default: 500
})], AdvancedBarChart.prototype, "height", void 0);
__decorate([Prop({
  required: true
})], AdvancedBarChart.prototype, "graphs", void 0);
__decorate([Prop({
  default: false
})], AdvancedBarChart.prototype, "doRotate", void 0);
__decorate([Prop({
  default: ''
})], AdvancedBarChart.prototype, "title", void 0);
__decorate([Prop({
  default: false
})], AdvancedBarChart.prototype, "big", void 0);
__decorate([Prop({
  default: false
})], AdvancedBarChart.prototype, "loading", void 0);
AdvancedBarChart = __decorate([Component({
  components: {
    EmptyChart,
    DashboardBarChart
  }
})], AdvancedBarChart);
export default AdvancedBarChart;