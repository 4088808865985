import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import StandardButton from "@/components/buttons/StandardButton.vue";
import PdpDisplay from "@/components/pdp/PdpDisplay.vue";
import { Component, Vue } from "vue-facing-decorator";
let PdpAgreementScreenForm = class PdpAgreementScreenForm extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "pdpEntirelyScrolled", false);
  }
  handleScroll() {
    const pdpContainer = this.$refs.pdpContainer;
    if (pdpContainer.offsetHeight + pdpContainer.scrollTop >= pdpContainer.scrollHeight) {
      this.pdpEntirelyScrolled = true;
    }
  }
  clicked() {
    if (this.pdpEntirelyScrolled) {
      this.$emit("accept");
    }
  }
};
PdpAgreementScreenForm = __decorate([Component({
  components: {
    PdpDisplay,
    StandardButton
  }
})], PdpAgreementScreenForm);
export default PdpAgreementScreenForm;