import {Speach} from "@/models/speach.model";
import axios from "axios";
import {User} from "@/models/user.model";
import {stringToURL} from "@/util/stringUtils";
import {LearningPath} from "@/models/path.model";


export const goToMonitorSpeach=(speach:Speach):void=>{
    window.location.assign(`/analytics/s/${speach.speachID}`);
}

export const goToVersioning=(speach:Speach):void=>{
    window.location.assign(`/list-versions/${speach.speachID}`)
}

export const gotToWorkflow=(speach:Speach):void=>{
    window.location.assign(`/workflow/validator/${speach.speachID}`)
}

export const goToWorkflowAuthor=(speach:Speach):void=>{
  window.location.assign(`/workflow/author/${speach.speachID}`)
}
export const deleteSpeach=async (speach:Speach):Promise<void>=>{
    return await axios.delete('/user/speach/delete',
        {params:{
                id:speach.id
            }}
        )
}

export const restoreSpeach=async (speach:Speach):Promise<void>=>{
    console.debug('restoreSpeach:: speach = ', speach);
    return await axios.put('/user/speach/restore', null,
        {params:{
                dataType: "json",
                id:speach.id
            }}
    )
}




export const toParcoursPath=(learningPath:LearningPath):string=>{
    return `/p/${learningPath.pathId}/${stringToURL(learningPath.title, "path")}`;
}
export const goToParcours=(learningPath:LearningPath):void=>{
    window.location.assign(toParcoursPath(learningPath));
}

export const toSpeachPath=(speach:Speach,loadRegulated=false):string=>{
  let speachID=speach.speachID;
  if(loadRegulated && speach.delayed_till){
    speachID+="_REGULATED"
  }
  return `/s/${speachID}/${stringToURL(speach.title, "speach")}`;
}

export const toSpeachEditionPath=(speach:Speach):string=>{
  return `/recorder/edit/${speach.speachID}`;
}

export const goToSpeach=(speach:Speach,outframe:boolean=false,loadRegulated=false):void=> {
    if(speach.isWorkflowInProgress){
      if(speach.canApprove) {
        gotToWorkflow(speach);
      }
      else{
        goToWorkflowAuthor(speach);
      }
    }
    else if (speach.publication || speach.delayed_till) {
        if(!outframe){
            window.location.assign(toSpeachPath(speach,loadRegulated));
        }else{
            open(toSpeachPath(speach),'blank');
        }
    } else {
        window.location.assign(`/play/${speach.speachID}/${stringToURL(speach.title, "speach")}`);
    }
}

export const goToSpeachEdition=(speach:Speach):void=> {
  if(speach.isWorkflowInProgress){
    if(speach.canApprove) {
      gotToWorkflow(speach);
    }
    else{
      goToWorkflowAuthor(speach);
    }
  } else {
    window.location.assign(toSpeachEditionPath(speach));
  }
}

export const refresh=():void=>{
    window.location.reload();
}

export const toUserPath=(user:User):string=>{
  return "/profil/" + user.userID + "/" + stringToURL(user.firstName+' '+user.lastName, "user");
}
export const goToUser=(user:User):void=>{
  window.location.assign(toUserPath(user));
}

export const toUserAnalyticsPath=(user:User):string=>{
  return "/analytics/u/" + user.id;
}
export const goToUserAnalytics=(user:User):void=>{
  window.location.assign(toUserAnalyticsPath(user));
}

export const gotToNotificationPage = ():void => {
    window.location.assign("/account/myNotifications");
}
export const toRecorderSpeach=():string=>{
    return "/recorder/indexrecorderlayout";
}
export const gotToRecorderSpeach =():void=>{
    window.location.assign(toRecorderSpeach())
}
 export const goToTemplates=():void=>{
  window.location.assign("/administration/t/all")
 }
