import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import IconButton from '@/components/buttons/IconButton.vue';
import axios from "axios";
import { confirmation } from "@/util/dialog";
import { Component, Prop, Vue } from "vue-facing-decorator";
let ResetQuizButton = class ResetQuizButton extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "disabled", void 0);
    _defineProperty(this, "quizzId", void 0);
    _defineProperty(this, "idAchatCour", void 0);
    _defineProperty(this, "speachId", void 0);
    _defineProperty(this, "displayAsIcon", void 0);
  }
  resetQuiz() {
    let url = `/api/v2/quizz/delete-results-user`;
    axios.get(url, {
      params: {
        dataType: "json",
        quizzId: this.quizzId,
        idAchatCour: this.idAchatCour
      }
    }).then(() => {
      this.reloadAnalytics();
    });
  }
  reloadAnalytics() {
    window.location.assign(`/analytics/s/${this.speachId}`);
  }
  confirmResetQuiz() {
    confirmation(this.$t('myAccount.speachSteering.popUpQuizz.confirmation'), '', this.$t('messageBox.ok'), this.$t('messageBox.cancel')).then(this.resetQuiz);
  }
};
__decorate([Prop({
  default: false
})], ResetQuizButton.prototype, "disabled", void 0);
__decorate([Prop({
  required: true
})], ResetQuizButton.prototype, "quizzId", void 0);
__decorate([Prop({
  required: true
})], ResetQuizButton.prototype, "idAchatCour", void 0);
__decorate([Prop({
  required: true
})], ResetQuizButton.prototype, "speachId", void 0);
__decorate([Prop({
  default: false
})], ResetQuizButton.prototype, "displayAsIcon", void 0);
ResetQuizButton = __decorate([Component({
  components: {
    IconButton
  }
})], ResetQuizButton);
export default ResetQuizButton;