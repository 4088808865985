import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  class: "containerEditDocumentChapter"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_BigButton = _resolveComponent("BigButton");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    class: "addVideo",
    onClick: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.addVideo && _ctx.addVideo(...args))
  }, [_createVNode(_component_BigButton, {
    label: this.$t('switch.synchro.add.video').toString(),
    icon: "play-box-outline"
  }, null, 8, ["label"])]), _createElementVNode("div", {
    class: "addAudio",
    onClick: _cache[1] || (_cache[1] =
    //@ts-ignore
    (...args) => _ctx.addAudio && _ctx.addAudio(...args))
  }, [_createVNode(_component_BigButton, {
    label: this.$t('switch.synchro.add.audio').toString(),
    icon: "microphone-outline"
  }, null, 8, ["label"])])]);
}