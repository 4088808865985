import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import Icon from "@/components/icon/Icon.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
let NotificationIcon = class NotificationIcon extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "notificationNumber", void 0);
  }
  get notificationNumberDisplay() {
    let value = "";
    if (this.notificationNumber > 99) {
      value = "+99";
    } else {
      value = this.notificationNumber + "";
    }
    return value;
  }
};
__decorate([Prop({
  required: true
})], NotificationIcon.prototype, "notificationNumber", void 0);
NotificationIcon = __decorate([Component({
  components: {
    Icon
  }
})], NotificationIcon);
export default NotificationIcon;