import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
let Tile = class Tile extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "title", void 0);
    _defineProperty(this, "width", void 0);
    _defineProperty(this, "big", void 0);
  }
  get getWidth() {
    return `width: ${this.width}%`;
  }
  get getClass() {
    return 'container' + (this.big ? ' big-tile' : '');
  }
};
__decorate([Prop({
  default: ""
})], Tile.prototype, "title", void 0);
__decorate([Prop({
  default: 33
})], Tile.prototype, "width", void 0);
__decorate([Prop({
  type: Boolean,
  default: false
})], Tile.prototype, "big", void 0);
Tile = __decorate([Component({})], Tile);
export default Tile;