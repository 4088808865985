import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
let WImage = class WImage extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "src", void 0);
    _defineProperty(this, "fit", void 0);
    _defineProperty(this, "hideOnClickModal", void 0);
    _defineProperty(this, "loading", void 0);
    _defineProperty(this, "lazy", void 0);
    _defineProperty(this, "alt", void 0);
  }
};
__decorate([Prop({
  required: true
})], WImage.prototype, "src", void 0);
__decorate([Prop({
  default: 'none'
}) // can be: fill, contain, cover, none or scale-down
], WImage.prototype, "fit", void 0);
__decorate([Prop({
  default: false
})], WImage.prototype, "hideOnClickModal", void 0);
__decorate([Prop({
  default: 'eager'
}) // can be: eager or lazy
], WImage.prototype, "loading", void 0);
__decorate([Prop({
  default: false
})], WImage.prototype, "lazy", void 0);
__decorate([Prop({
  default: ''
})], WImage.prototype, "alt", void 0);
WImage = __decorate([Component({})], WImage);
export default WImage;