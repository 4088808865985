import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_tiny_mce = _resolveComponent("w-tiny-mce");
  const _component_grid_layout = _resolveComponent("grid-layout");
  return _openBlock(), _createBlock(_component_grid_layout, {
    class: "gridLayoutContainer"
  }, {
    default: _withCtx(() => {
      var _ctx$accessibilityDat;
      return [_createVNode(_component_w_tiny_mce, {
        editorId: 'statements-editor',
        customConfig: _ctx.editorConfig,
        text: (_ctx$accessibilityDat = _ctx.accessibilityData) === null || _ctx$accessibilityDat === void 0 ? void 0 : _ctx$accessibilityDat.statement,
        saveLabel: _ctx.$t('accessibility.statement.edition.save'),
        cancelLabel: _ctx.$t('accessibility.statement.edition.cancel'),
        onOnSaveChanges: _ctx.saveAccessibilityData,
        onOnCancelChanges: _ctx.cancelEdit
      }, null, 8, ["customConfig", "text", "saveLabel", "cancelLabel", "onOnSaveChanges", "onOnCancelChanges"])];
    }),
    _: 1
  });
}