import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
let WDate = class WDate extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "date", void 0);
    _defineProperty(this, "stringDate", void 0);
    _defineProperty(this, "timestamp", void 0);
  }
  get dateToFormattedString() {
    let dateToFormat = this.date;
    if (this.timestamp !== null) {
      dateToFormat = new Date(this.timestamp);
    } else if (this.stringDate !== '') {
      dateToFormat = new Date(this.stringDate);
    }
    if (dateToFormat === null) {
      return '-';
    }
    return this.$d(dateToFormat);
  }
};
__decorate([Prop({
  default: null
})], WDate.prototype, "date", void 0);
__decorate([Prop({
  default: ''
})], WDate.prototype, "stringDate", void 0);
__decorate([Prop({
  default: null
})], WDate.prototype, "timestamp", void 0);
WDate = __decorate([Component({})], WDate);
export default WDate;