import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue, Emit, Watch } from "vue-facing-decorator";
let WDialog = class WDialog extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "visible", void 0);
    _defineProperty(this, "title", void 0);
    _defineProperty(this, "width", void 0);
    _defineProperty(this, "modal", void 0);
    _defineProperty(this, "closeOnOutsideClick", void 0);
    _defineProperty(this, "appendToBody", void 0);
    _defineProperty(this, "fullscreen", void 0);
    _defineProperty(this, "customClass", void 0);
    _defineProperty(this, "isVisible", false);
  }
  onVisibleChange() {
    this.isVisible = this.visible;
  }
  mounted() {
    this.isVisible = this.visible;
  }
  emitCloseEvent() {
    return;
  }
};
__decorate([Prop({
  required: true
})], WDialog.prototype, "visible", void 0);
__decorate([Prop({
  default: ''
})], WDialog.prototype, "title", void 0);
__decorate([Prop({
  default: '600px'
})], WDialog.prototype, "width", void 0);
__decorate([Prop({
  default: true
})], WDialog.prototype, "modal", void 0);
__decorate([Prop({
  default: true
})], WDialog.prototype, "closeOnOutsideClick", void 0);
__decorate([Prop({
  default: false
})], WDialog.prototype, "appendToBody", void 0);
__decorate([Prop({
  default: false
})], WDialog.prototype, "fullscreen", void 0);
__decorate([Prop({
  default: ''
})], WDialog.prototype, "customClass", void 0);
__decorate([Watch('visible')], WDialog.prototype, "onVisibleChange", null);
__decorate([Emit('onDialogClose')], WDialog.prototype, "emitCloseEvent", null);
WDialog = __decorate([Component({})], WDialog);
export default WDialog;