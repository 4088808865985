import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import WorkflowRejectedBlock from "@/components/workflow/WorkflowRejectedBlock.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
let WorkflowGraph = class WorkflowGraph extends Vue {
  constructor(...args) {
    super(...args);
    /**
     * {
     * currentStep : Number,
     * steps :
     *  {
     *      order : Number ,
     *      groupName : String
     *  }
     * rejected :
     *  {   comment : String ,
     *      groupName: String,
     *      date: Date,
     *      stepNumber: Number ,
     *      stepsCount: Number
     *   }
     * }
     */
    _defineProperty(this, "workflow", void 0);
  }
  mounted() {
    let worflowBlock = this.$refs.workflowInstanceBlock;
    this.setStepsAlign(worflowBlock.scrollWidth, worflowBlock.clientWidth);
  }
  setStepsAlign(scrollWidth, clientWidth) {
    // On modifie le style à la volée pour gérer quand il y a un overflow
    let worflowBlock = this.$refs.workflowInstanceBlock;
    if (scrollWidth > clientWidth) {
      // On ne centre plus le contenu
      worflowBlock.style.justifyContent = "space-between";
    }
  }
  get workflowStep() {
    return this.workflow.rejected ? this.workflow.rejected.stepNumber : this.workflow.currentStep;
  }
};
__decorate([Prop({
  required: true
})], WorkflowGraph.prototype, "workflow", void 0);
WorkflowGraph = __decorate([Component({
  components: {
    WorkflowRejectedBlock
  }
})], WorkflowGraph);
export default WorkflowGraph;