import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", {
    class: _normalizeClass([{
      visible: _ctx.visible
    }, "bgSidePanel"]),
    onClick: _cache[0] || (_cache[0] = $event => _ctx.$emit('close'))
  }, null, 2), _createElementVNode("div", {
    class: _normalizeClass([{
      visible: _ctx.visible,
      wideSidePanel: _ctx.wideSidePanel
    }, "sidePanel"])
  }, [_ctx.closeButtonVisible ? (_openBlock(), _createElementBlock("div", {
    key: 0,
    class: "closeBtn",
    onClick: _cache[1] || (_cache[1] = $event => _ctx.$emit('close'))
  })) : _createCommentVNode("", true), _renderSlot(_ctx.$slots, "default", {}, undefined, true)], 2)]);
}