import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-aae931d6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "gcu-form"
};
const _hoisted_2 = {
  class: "title"
};
const _hoisted_3 = {
  class: "buttonContainer"
};
const _hoisted_4 = {
  style: {
    "white-space": "nowrap"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_gcu_display = _resolveComponent("gcu-display");
  const _component_standard_button = _resolveComponent("standard-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('gcu.title')), 1), _createElementVNode("div", {
    ref: "gcuContainer",
    class: "gcu-container",
    onScroll: _cache[0] || (_cache[0] =
    //@ts-ignore
    (...args) => _ctx.handleScroll && _ctx.handleScroll(...args))
  }, [_createVNode(_component_gcu_display, {
    onGcuLoaded: _ctx.handleScroll
  }, null, 8, ["onGcuLoaded"])], 544), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_standard_button, {
    disabled: !_ctx.cguEntirelyScrolled,
    label: _ctx.$t('gcu.button.label'),
    onAction: _ctx.clicked
  }, null, 8, ["disabled", "label", "onAction"])])])]);
}