import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_IconButton = _resolveComponent("IconButton");
  const _component_icon = _resolveComponent("icon");
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item");
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu");
  const _component_el_dropdown = _resolveComponent("el-dropdown");
  return _openBlock(), _createBlock(_component_el_dropdown, {
    trigger: "click",
    disabled: _ctx.dupplicationInProgress,
    type: "primary"
  }, {
    dropdown: _withCtx(() => [_createVNode(_component_el_dropdown_menu, null, {
      default: _withCtx(() => [_ctx.speach.publication ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
        key: 0,
        onClick: _ctx.goToMonitorSpeach
      }, {
        default: _withCtx(() => [_createVNode(_component_icon, {
          icon: "chart-bar",
          class: "iconMenu"
        }), _createTextVNode(" " + _toDisplayString(_ctx.$t('thumbnail.menu.pilot')), 1)]),
        _: 1
      }, 8, ["onClick"])) : _createCommentVNode("", true), _ctx.speach.canEdit ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
        key: 1,
        onClick: _ctx.dupplicateCour
      }, {
        default: _withCtx(() => [_createVNode(_component_icon, {
          icon: "content-copy",
          class: "iconMenu"
        }), _createTextVNode(" " + _toDisplayString(_ctx.$t('thumbnail.menu.duplicate')), 1)]),
        _: 1
      }, 8, ["onClick"])) : _createCommentVNode("", true), _createVNode(_component_el_dropdown_item, {
        onClick: _ctx.goToVersioning
      }, {
        default: _withCtx(() => [_createVNode(_component_icon, {
          icon: "playlist-play",
          class: "iconMenu"
        }), _createTextVNode(" " + _toDisplayString(_ctx.$t('thumbnail.menu.versioning')), 1)]),
        _: 1
      }, 8, ["onClick"]), _ctx.speach.isWorkflowInProgress ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
        key: 2,
        onClick: _ctx.goToWorkflow
      }, {
        default: _withCtx(() => [_createVNode(_component_icon, {
          icon: "sitemap-outline",
          class: "iconMenu"
        }), _createTextVNode(" " + _toDisplayString(_ctx.$t('thumbnail.menu.workflow')), 1)]),
        _: 1
      }, 8, ["onClick"])) : _createCommentVNode("", true), _createVNode(_component_el_dropdown_item, {
        onClick: _ctx.deleteSpeach
      }, {
        default: _withCtx(() => [_createVNode(_component_icon, {
          icon: "delete-outline",
          class: "iconMenu"
        }), _createTextVNode(" " + _toDisplayString(_ctx.$t('thumbnail.menu.archive')), 1)]),
        _: 1
      }, 8, ["onClick"])]),
      _: 1
    })]),
    default: _withCtx(() => [_createVNode(_component_IconButton, {
      color: "white",
      size: "small",
      icon: "dots-vertical",
      loading: _ctx.dupplicationInProgress
    }, null, 8, ["loading"])]),
    _: 1
  }, 8, ["disabled"]);
}