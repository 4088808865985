import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import TextHighlight from "@/components/text/TextHighlight.vue";
import TextTag from "@/components/text/TextTag.vue";
import { goToUser } from "@/util/routeUtil";
import { Component, Prop, Vue } from "vue-facing-decorator";
let UserCard = class UserCard extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "user", void 0);
    _defineProperty(this, "searchTerm", void 0);
    _defineProperty(this, "workflowEnabled", void 0);
  }
  get backgroundURL() {
    return `url(${this.user.img})`;
  }
  goToUser() {
    goToUser(this.user);
  }
};
__decorate([Prop({
  required: true
})], UserCard.prototype, "user", void 0);
__decorate([Prop({
  required: true
})], UserCard.prototype, "searchTerm", void 0);
__decorate([Prop({
  default: false
})], UserCard.prototype, "workflowEnabled", void 0);
UserCard = __decorate([Component({
  components: {
    TextTag,
    TextHighlight
  }
})], UserCard);
export default UserCard;