import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
import Icon from "@/components/icon/Icon.vue";
let AddCard = class AddCard extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "url", void 0);
    _defineProperty(this, "icon", void 0);
    _defineProperty(this, "label", void 0);
    _defineProperty(this, "outframe", void 0);
  }
  goToUrl() {
    if (!this.outframe) {
      window.location.href = this.url;
    } else {
      alert(this.url);
      open(this.url, '_blank');
    }
  }
};
__decorate([Prop({
  required: true
})], AddCard.prototype, "url", void 0);
__decorate([Prop({
  default: 'plus'
})], AddCard.prototype, "icon", void 0);
__decorate([Prop({
  default: 'click on me'
})], AddCard.prototype, "label", void 0);
__decorate([Prop({
  default: false
})], AddCard.prototype, "outframe", void 0);
AddCard = __decorate([Component({
  components: {
    Icon
  }
})], AddCard);
export default AddCard;