import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-282f388e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ratingContainer"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [(_openBlock(), _createElementBlock(_Fragment, null, _renderList(5, i => {
    return _createVNode(_component_icon, {
      class: "star",
      key: 'rating' + i,
      icon: i <= _ctx.value ? 'star' : 'star-outline',
      onClick: $event => _ctx.setNote(i)
    }, null, 8, ["icon", "onClick"]);
  }), 64))]);
}