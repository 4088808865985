import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import ConfirmDialog from "@/components/dialogs/ConfirmDialog.vue";
import { Events } from "@/events/events";
import axios from "axios";
import WElMessage from "@/components/wrappers/w-elmessage.vue";
import { RecorderType } from "@/models/recorderType.model";
import { Component, Prop, Vue } from "vue-facing-decorator";
let ConvertQuizToExamLink = class ConvertQuizToExamLink extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "chapterId", void 0);
    _defineProperty(this, "isConfirmDialogVisible", false);
  }
  convertQuizzToExamQuizz() {
    axios.get(`/api/v2/quizz/do-convert-to-quizz-exam`, {
      headers: {
        'Content-Type': 'application/json'
      },
      params: {
        chapterId: this.chapterId
      }
    }).then(() => {
      this.toggleConfirmDialogToFalse();
      this.refreshChapterView();
    }, () => WElMessage.showErrorMessage(this.$t('errors.default')));
  }
  toggleConfirmDialogToFalse() {
    this.isConfirmDialogVisible = false;
  }
  refreshChapterView() {
    trig(Events.AFFICHER_EDITION_CHAPITRE, {
      id: this.chapterId,
      type: RecorderType.QUIZZ_EXAMEN
    });
  }
};
__decorate([Prop({
  required: true
})], ConvertQuizToExamLink.prototype, "chapterId", void 0);
ConvertQuizToExamLink = __decorate([Component({
  components: {
    ConfirmDialog
  }
})], ConvertQuizToExamLink);
export default ConvertQuizToExamLink;