import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-1852e32a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = {
  class: "buttons"
};
const _hoisted_3 = {
  class: "userRow"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_search_category_title = _resolveComponent("search-category-title");
  const _component_icon_button = _resolveComponent("icon-button");
  const _component_user_card = _resolveComponent("user-card");
  const _component_slide = _resolveComponent("slide");
  const _component_carousel = _resolveComponent("carousel");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_search_category_title, {
    "results-number": _ctx.totalNumberOfUsersFound,
    "category-title": "searchbar.users"
  }, null, 8, ["results-number"]), _createElementVNode("div", _hoisted_2, [_createVNode(_component_icon_button, {
    class: "buttonNavigation",
    color: "light2",
    icon: "chevron-left",
    onClick: _ctx.prevPage
  }, null, 8, ["onClick"]), _createVNode(_component_icon_button, {
    class: "buttonNavigation",
    color: "light2",
    icon: "chevron-right",
    onClick: _ctx.nextPage
  }, null, 8, ["onClick"])]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_carousel, {
    ref: "slider",
    mouseDrag: false,
    "items-to-scroll": 1,
    snapAlign: 'start',
    breakpoints: _ctx.getBreakpoint,
    class: "carouselUserSearch"
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.usersFound, user => {
      return _openBlock(), _createBlock(_component_slide, {
        key: 'userCard' + user.userID
      }, {
        default: _withCtx(() => [_createVNode(_component_user_card, {
          id: user.userID,
          user: user,
          trainer: user.trainer,
          "search-term": _ctx.searchTerm,
          "workflow-enabled": _ctx.workflowEnabled,
          class: "user-card"
        }, null, 8, ["id", "user", "trainer", "search-term", "workflow-enabled"])]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  }, 8, ["breakpoints"])])]);
}