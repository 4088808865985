import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b979e230"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "buttonBigContainer buttonBigContainerToolTip"
};
const _hoisted_2 = {
  class: "pictoBig"
};
const _hoisted_3 = {
  class: "tooltipButton"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon");
  const _component_el_popover = _resolveComponent("el-popover");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_popover, {
    placement: "bottom",
    width: 150,
    trigger: "hover"
  }, {
    reference: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", {
      class: _normalizeClass([_ctx.className, "buttonBig"])
    }, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_icon, {
      icon: _ctx.icon
    }, null, 8, ["icon"])])], 2)])]),
    default: _withCtx(() => [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.label), 1)]),
    _: 1
  })]);
}