import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6caee802"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "overlay ut-gcu"
};
const _hoisted_2 = {
  class: "gcu"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_pdp_agreement_screen_form = _resolveComponent("pdp-agreement-screen-form");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_component_pdp_agreement_screen_form, {
    onAccept: _ctx.userAccept
  }, null, 8, ["onAccept"])])]);
}