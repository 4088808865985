import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import WSelect from "@/components/form/w-select.vue";
import WToggle from "@/components/form/w-toggle.vue";
import axios from "axios";
import StandardButton from "@/components/buttons/StandardButton.vue";
import delay from "delay";
import { Component, Vue } from "vue-facing-decorator";
let ExportMassScorm = class ExportMassScorm extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "typeSelected", "SCORM_LITE");
    _defineProperty(this, "navigationInContent", false);
    _defineProperty(this, "listOfSpeach", []);
    _defineProperty(this, "currentSpeachToExport", 0);
    _defineProperty(this, "page", 1);
    _defineProperty(this, "waitingToDownload", "");
    _defineProperty(this, "types", [{
      value: "SCORM_LITE",
      label: "Scorm embed"
    }, {
      value: "SCORM_FULL",
      label: "Scorm classique"
    }]);
  }
  mounted() {
    this.loadSpeachsPage();
  }
  cancelExport() {
    window.location.reload();
  }
  async loadSpeachsPage() {
    const result = await axios.get('/api/v2/admin/speach', {
      params: {
        page: this.page
      }
    });
    const pageWrapper = result.data;
    if (pageWrapper.result.length > 0) {
      this.listOfSpeach = this.listOfSpeach.concat(pageWrapper.result);
      this.page++;
      this.loadSpeachsPage();
    }
  }
  async startDownload() {
    alert('Allow popup on this window for automatics download');
    const listOfSpeach = this.listOfSpeach;
    for (const speach of listOfSpeach) {
      if (speach && speach.speachID) {
        await this.launchJobScorm(speach);
        var isFileCreated = false;
        while (!isFileCreated) {
          await delay(2000);
          isFileCreated = await this.checkIfFileCreated();
        }
        var url = await this.downloadFileUrl();
        open(url, "_blank");
      }
    }
    this.currentSpeachToExport = 0;
    alert(this.listOfSpeach.length + " Exported");
  }
  async launchJobScorm(speach) {
    this.currentSpeachToExport++;
    const result = await axios.get('/administration/scorm/exportScorm', {
      params: {
        id: speach.id,
        contentNavigation: this.navigationInContent,
        type: this.typeSelected
      }
    });
    this.waitingToDownload = result.data.dataValue;
  }
  async checkIfFileCreated() {
    const result = await axios.get('/administration/scorm/checkScormCreated', {
      params: {
        name: this.waitingToDownload
      }
    });
    return result.data;
  }
  async downloadFileUrl() {
    return '/administration/scorm/download/' + this.waitingToDownload;
  }
};
ExportMassScorm = __decorate([Component({
  components: {
    StandardButton,
    WToggle,
    WSelect
  }
})], ExportMassScorm);
export default ExportMassScorm;