
export const html=(element:HTMLDivElement,html:string):void=>{
  element.innerHTML=html;
  const scripts = element.querySelectorAll('script');
  scripts.forEach((oldScript) => {
    const newScript = document.createElement('script');
    newScript.text = oldScript.innerHTML;
    oldScript.parentNode?.replaceChild(newScript, oldScript);
  });
}

export const extractDomain=(url: string): string=> {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname;
  } catch (e) {
    console.error("Invalid URL:", e);
    return '';
  }
}
