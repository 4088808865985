import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import TextHighlight from "@/components/text/TextHighlight.vue";
import TextTag from "@/components/text/TextTag.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
let SpeachCell = class SpeachCell extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "speach", void 0);
    _defineProperty(this, "searchTerm", void 0);
    _defineProperty(this, "secondLineContent", void 0);
    _defineProperty(this, "width", void 0);
    _defineProperty(this, "size", void 0);
  }
  get backgroundURL() {
    return `url(${this.speach.img})`;
  }
  get imageSize() {
    if (this.size === 'small') {
      return '32px';
    } else if (this.size === 'large') {
      return '64px';
    }
    return '48px';
  }
  get cssVars() {
    return {
      '--parent-width': this.width,
      '--image-size': this.imageSize
    };
  }
};
__decorate([Prop({
  required: true
})], SpeachCell.prototype, "speach", void 0);
__decorate([Prop({
  required: true
})], SpeachCell.prototype, "searchTerm", void 0);
__decorate([Prop({
  required: true
})], SpeachCell.prototype, "secondLineContent", void 0);
__decorate([Prop({
  default: '100%'
})], SpeachCell.prototype, "width", void 0);
__decorate([Prop({
  default: ''
})], SpeachCell.prototype, "size", void 0);
SpeachCell = __decorate([Component({
  components: {
    TextTag,
    TextHighlight
  }
})], SpeachCell);
export default SpeachCell;