import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.find.js";
import { __decorate } from "tslib";
import { Component, Vue } from "vue-facing-decorator";
import Icon from "@/components/icon/Icon.vue";
import axios from "axios";
import StandardButton from "@/components/buttons/StandardButton.vue";
import BigButton from "@/components/buttons/BigButton.vue";
import { alertError } from "@/util/dialog";
import WInput from "@/components/form/w-input.vue";
import AIStepsGenerator from "@/components/aiSpeachGenerator/AIStepsGenerator.vue";
import { waitingJob } from "@/util/job.utils";
import WSelect from "@/components/form/w-select.vue";
import WLink from "@/components/wrappers/w-link.vue";
import { listOfLanguage } from "@/util/langUtils";
let AISpeachGenerator = class AISpeachGenerator extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "step", 0);
    _defineProperty(this, "showOption", false);
    _defineProperty(this, "uploadInProgress", false);
    _defineProperty(this, "listOfUserFile", []);
    _defineProperty(this, "proceededFile", []);
    _defineProperty(this, "listOfDuration", ['']);
    _defineProperty(this, "durationSelected", '');
    _defineProperty(this, "languageSelected", "");
  }
  get listOfLanguage() {
    return listOfLanguage(this.$t);
  }
  get isAllFileProceed() {
    if (this.proceededFile.length === 0) {
      return false;
    }
    for (const file of this.proceededFile) {
      if (!file.data) {
        return false;
      }
    }
    return true;
  }
  mounted() {
    this.proceededFile = [];
    this.listOfDuration = [this.$t('aiSpeachGenerator.options.durations.short').toString(), this.$t('aiSpeachGenerator.options.durations.medium').toString(), this.$t('aiSpeachGenerator.options.durations.long').toString()];
    this.durationSelected = this.$t('aiSpeachGenerator.options.durations.short');
  }
  onFileChange() {
    // wait change finalize
    this.$nextTick(() => {
      for (let fileToUpload of this.listOfUserFile) {
        // check if document already proceed
        if (!this.proceededFile.find(el => {
          return el.uid + "" === fileToUpload.uid + "";
        })) {
          // proceed the file
          this.uploadAFile(fileToUpload);
        }
      }
    });
  }
  validateUploadDocument() {
    this.step = 1;
  }
  uploadAFile(fileToUpload) {
    var _fileToUpload$raw;
    console.debug('Uploading file to upload:', fileToUpload.name);
    this.proceededFile.push({
      uid: fileToUpload.uid + "",
      jobID: "",
      name: fileToUpload.name,
      progress: 0,
      data: ""
    });
    const formData = new FormData();
    const nameOfFile = (_fileToUpload$raw = fileToUpload.raw) === null || _fileToUpload$raw === void 0 ? void 0 : _fileToUpload$raw.name;
    const currentFile = this.proceededFile.find(el => {
      return fileToUpload.uid + "" === el.uid;
    });
    if (!currentFile) {
      return;
    }
    formData.append('file', fileToUpload.raw, nameOfFile);
    axios.post('/recorder/aiSpeachGenerator/uploadDocuments', formData, {
      onUploadProgress: progressEvent => {
        currentFile.progress = Math.round(progressEvent.loaded * 100 / progressEvent.total);
      }
    }).then(async resp => {
      currentFile.jobID = resp.data;
      await this.waitDocumentProceed(currentFile, resp.data, nameOfFile + "");
    }).catch(err => {
      currentFile.progress = -1;
      alertError(err);
    });
  }
  async waitDocumentProceed(currentFile, jobID, name) {
    try {
      const data = await waitingJob(jobID);
      currentFile.data = data + "";
    } catch (e) {
      currentFile.progress = -1;
      alertError("Something wrong on your file: " + name);
    }
  }
};
AISpeachGenerator = __decorate([Component({
  components: {
    WLink,
    WSelect,
    AIStepsGenerator,
    WInput,
    BigButton,
    StandardButton,
    Icon
  }
})], AISpeachGenerator);
export default AISpeachGenerator;