import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-65b69b4b"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "ut-gcu"
};
const _hoisted_2 = {
  class: "gcu"
};
const _hoisted_3 = {
  class: "gcu-form"
};
const _hoisted_4 = {
  class: "title"
};
const _hoisted_5 = {
  ref: "gcuContainer",
  class: "gcu-container ut-gcuContent"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_gcu_display = _resolveComponent("gcu-display");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('gcu.title')), 1), _createElementVNode("div", _hoisted_5, [_createVNode(_component_gcu_display)], 512)])])]);
}