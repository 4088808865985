import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import "core-js/modules/es.array.push.js";
import { __decorate } from "tslib";
import axios from "axios";
import GridLayout from "@/components/layout/GridLayout.vue";
import SidePanel from "@/components/layout/SidePanel.vue";
import NotificationParameters from "@/components/notifications/NotificationParameters.vue";
import NotificationList from "@/components/notifications/subcomponent/NotificationList.vue";
import WSelect from "@/components/form/w-select.vue";
import NotificationIcon from "@/components/notifications/subcomponent/NotificationIcon.vue";
import Icon from "@/components/icon/Icon.vue";
import WToolTip from "@/components/tooltip/w-toolTip.vue";
import { notificationTypeAsMap } from "@/models/notificationType";
import Loader from "@/components/loader/Loader.vue";
import { Component, Vue } from "vue-facing-decorator";
let NotificationPageApp = class NotificationPageApp extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "typeNotification", null);
    _defineProperty(this, "showParameters", false);
    _defineProperty(this, "typeNotificationChoiceList", []);
    _defineProperty(this, "loading", false);
  }
  getTypeNotificicationKey(type) {
    return notificationTypeAsMap[type];
  }
  computeTypeNotificationChoiceList(typeNotificationsForUser) {
    let translatedList = [];
    translatedList.push({
      id: "notification.filter.all",
      label: this.$t("notification.filter.all")
    });
    for (const key of typeNotificationsForUser) {
      translatedList.push({
        id: key,
        label: this.$t(this.getTypeNotificicationKey(key))
      });
    }
    this.typeNotificationChoiceList = translatedList;
  }
  mounted() {
    this.loading = true;
    axios.post("/api/v2/notifications/acquit");
    axios.get("/api/v2/notifications/parameters/types").then(response => {
      this.computeTypeNotificationChoiceList(response.data);
      this.loading = false;
    });
    // If the url includes the http parameter settings=open, then we need to open the pformationOnline/app/views/mail/Mails/sendNotification.htmlanel
    // with the parameter (url from an email)
    if (window.location.search.includes("settings=open")) {
      this.showParameters = true;
    }
  }
  destroyed() {
    document.body.removeEventListener("click", this.closeParameters);
  }
  openParameters() {
    document.body.addEventListener("click", this.closeParameters);
    this.showParameters = true;
  }
  closeParameters() {
    document.body.removeEventListener("click", this.closeParameters);
    this.showParameters = false;
  }
  typeNotificationChanged(param) {
    if (param === "notification.filter.all") {
      this.typeNotification = null;
    } else {
      this.typeNotification = param;
    }
  }
};
NotificationPageApp = __decorate([Component({
  components: {
    Loader,
    WToolTip,
    Icon,
    NotificationIcon,
    WSelect,
    NotificationList,
    NotificationParameters,
    SidePanel,
    GridLayout
  }
})], NotificationPageApp);
export default NotificationPageApp;