import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
let WDatePickerRange = class WDatePickerRange extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "calendarRange", void 0);
    _defineProperty(this, "separator", void 0);
    _defineProperty(this, "placement", void 0);
    _defineProperty(this, "calRange", null);
  }
  mounted() {
    this.calRange = this.calendarRange;
  }
  get defaultRanges() {
    return [{
      text: this.$t('datePicker.thisWeek'),
      value: () => {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        return [start, end];
      }
    }, {
      text: this.$t('datePicker.moreThan7days'),
      value: () => {
        const end = new Date();
        const start = new Date(0);
        end.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
        return [start, end];
      }
    }, {
      text: this.$t('datePicker.moreThan30days'),
      value: () => {
        const end = new Date();
        const start = new Date(0);
        end.setTime(end.getTime() - 3600 * 1000 * 24 * 30);
        return [start, end];
      }
    }, {
      text: this.$t('datePicker.moreThan3months'),
      value: () => {
        const end = new Date();
        const start = new Date(0);
        end.setTime(end.getTime() - 3600 * 1000 * 24 * 90);
        return [start, end];
      }
    }];
  }
  get dateFormat() {
    let dateFormat = 'M/D/YYYY';
    if (this.$i18n.locale.toLowerCase() === 'de') {
      dateFormat = 'D.M.YYYY';
    } else if (this.$i18n.locale.toLowerCase() === 'es') {
      dateFormat = 'D/M/YYYY';
    } else if (this.$i18n.locale.toLowerCase() === 'fr') {
      dateFormat = 'DD/MM/YYYY';
    }
    return dateFormat;
  }
  changeCalRange() {
    this.calRange = this.calendarRange;
  }
  onChangeCalendarRange(dates) {
    if (dates !== null && (dates === null || dates === void 0 ? void 0 : dates.length) > 0 && dates[0]['$d']) {
      // YTRCK-3550 [User Admin] Issue with date selection
      // todo check if an update of element plus fix this (>2.8.2)
      dates[0] = dates[0].$d;
      dates[1] = dates[1].$d;
    }
    this.$emit('changeCalendarRange', dates);
    return;
  }
};
__decorate([Prop({
  default: null
})], WDatePickerRange.prototype, "calendarRange", void 0);
__decorate([Prop({
  default: '-'
})], WDatePickerRange.prototype, "separator", void 0);
__decorate([Prop({
  default: 'bottom'
})], WDatePickerRange.prototype, "placement", void 0);
__decorate([Watch('calendarRange')], WDatePickerRange.prototype, "changeCalRange", null);
WDatePickerRange = __decorate([Component({
  emits: ['changeCalendarRange']
})], WDatePickerRange);
export default WDatePickerRange;