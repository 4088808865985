import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { htmliFy } from "@/util/stringUtils";
import { Component, Prop, Vue } from "vue-facing-decorator";
let TextHighlight = class TextHighlight extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "label", void 0);
    _defineProperty(this, "term", void 0);
  }
  get highlightTerm() {
    if (this.term === "") return this.label;
    let value = this.label;
    let index = 0;
    while (index !== 1) {
      index = value.toLowerCase().indexOf(this.term.toLowerCase(), index);
      if (index !== -1) {
        let capture = value.slice(index, index + this.term.length);
        value = value.slice(0, index) + "<b>" + value.slice(index);
        value = value.slice(0, index + capture.length + 3) + "</b>" + value.slice(index + capture.length + 3);
        index = index + capture.length + 6;
      } else {
        return value;
      }
    }
    // remove xss injection
    return htmliFy(this.label);
  }
};
__decorate([Prop({
  required: true
})], TextHighlight.prototype, "label", void 0);
__decorate([Prop({
  required: true
})], TextHighlight.prototype, "term", void 0);
TextHighlight = __decorate([Component({})], TextHighlight);
export default TextHighlight;