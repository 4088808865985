import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
let WScrollbar = class WScrollbar extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "maxHeight", void 0);
  }
};
__decorate([Prop({
  default: ''
})], WScrollbar.prototype, "maxHeight", void 0);
WScrollbar = __decorate([Component({})], WScrollbar);
export default WScrollbar;