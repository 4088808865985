import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
/** Show icon frome this website https://materialdesignicons.com/ */
import { Component, Prop, Vue } from "vue-facing-decorator";
let Icon = class Icon extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "icon", void 0);
    _defineProperty(this, "size", void 0);
    _defineProperty(this, "color", void 0);
  }
};
__decorate([Prop({
  required: true
})], Icon.prototype, "icon", void 0);
__decorate([Prop({
  default: ''
})], Icon.prototype, "size", void 0);
__decorate([Prop({
  default: ''
})], Icon.prototype, "color", void 0);
Icon = __decorate([Component({})], Icon);
export default Icon;