import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-b72b8e6c"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "pageContainer"
};
const _hoisted_2 = {
  class: "filterLabel"
};
const _hoisted_3 = {
  class: "settingLine"
};
const _hoisted_4 = {
  class: "selectBox"
};
const _hoisted_5 = {
  class: "settingIcon"
};
const _hoisted_6 = {
  key: 0,
  class: "loader"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_notification_parameters = _resolveComponent("notification-parameters");
  const _component_side_panel = _resolveComponent("side-panel");
  const _component_w_select = _resolveComponent("w-select");
  const _component_icon = _resolveComponent("icon");
  const _component_w_tool_tip = _resolveComponent("w-tool-tip");
  const _component_loader = _resolveComponent("loader");
  const _component_notification_list = _resolveComponent("notification-list");
  const _component_grid_layout = _resolveComponent("grid-layout");
  return _openBlock(), _createBlock(_component_grid_layout, {
    onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
  }, {
    default: _withCtx(() => [_createElementVNode("div", null, [_createVNode(_component_side_panel, {
      visible: _ctx.showParameters,
      onOpen: _ctx.openParameters,
      onClose: _ctx.closeParameters
    }, {
      default: _withCtx(() => [_ctx.showParameters ? (_openBlock(), _createBlock(_component_notification_parameters, {
        key: 0
      })) : _createCommentVNode("", true)]),
      _: 1
    }, 8, ["visible", "onOpen", "onClose"]), _createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("notification.filter.label")), 1), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createVNode(_component_w_select, {
      options: _ctx.typeNotificationChoiceList,
      "value-key": "id",
      value: "notification.filter.all",
      onChange: _ctx.typeNotificationChanged
    }, null, 8, ["options", "onChange"])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_w_tool_tip, {
      text: _ctx.$t('notification.settings')
    }, {
      default: _withCtx(() => [_createVNode(_component_icon, {
        onClick: _withModifiers(_ctx.openParameters, ["stop"]),
        color: "dark",
        icon: "cog-outline",
        size: "default"
      }, null, 8, ["onClick"])]),
      _: 1
    }, 8, ["text"])])]), _ctx.loading ? (_openBlock(), _createElementBlock("div", _hoisted_6, [_createVNode(_component_loader)])) : _createCommentVNode("", true), _createVNode(_component_notification_list, {
      "type-notification": _ctx.typeNotification
    }, null, 8, ["type-notification"])])])]),
    _: 1
  });
}