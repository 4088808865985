

export const getTooltip=(mode: string, title: string,$t:any, cumulative:boolean=false, label:string="", labelPlural:string="", showBody:boolean=true)=> {
  const tooltips = {
    borderColor: "rgb(221, 221, 246)",
    backgroundColor: "rgb(240, 240, 255)",
    bodyColor: "#0A082F",
    titleColor: "#0A082F",
    titleMarginBottom: 16,
    mode: mode,
    bodySpacing: 16,
    borderWidth: 1,
    displayColors: true,
    padding: 16,
    cornerRadius: 12,
    callbacks: {
      title: function(tooltipItem:any) {
          return title ? title : tooltipItem.dataset.label;
      },
      label: function(tooltipItem:any) {
        if(!label){
          label="admin.dashboard.graphes.tooltip.label";
        }
        else if(labelPlural&&tooltipItem.formattedValue>1){
          label=labelPlural;
        }
        return  $t(label, {
          name: showBody ? tooltipItem.dataset.label:tooltipItem.label,
          value: tooltipItem.formattedValue
        })

      },
      beforeBody: function(tooltipItem:any) {
        if(showBody) {
          const tt = tooltipItem[0];
          if(cumulative){
            return tt.dataset.tooltipSecondPeriodDates[tt.dataIndex];
          } else {
            return $t("admin.dashboard.graphes.tooltip.period", {
              firstPeriod: tt.dataset.tooltipFirstPeriodDates[tt.dataIndex],
              secondPeriod: tt.dataset.tooltipSecondPeriodDates[tt.dataIndex]
            });
          }
        }
      }
    }
  };
  return tooltips;
}

export const getLegend=(legends: string[], legendTooltipId: string) =>{
  return {
    align: "end",
    fullWidth: false,
    title:{
      color: "#0A082F",
      font:"Nunito Sans"
    },
    labels: {
      boxWidth: 15,
      padding: 32
    },
    onHover: function(event:any, legendItem:any) {
      const tooltip = document.getElementById(legendTooltipId) as HTMLElement;
      tooltip.style.display = "inherit";
      tooltip.style.background="rgb(240, 240, 255)";
      tooltip.style.color="#0A082F";
      tooltip.innerHTML = legends[legendItem.datasetIndex];
      tooltip.style.right = 0+ "px";
      tooltip.style.top =-45+ "px";
      tooltip.style.zIndex="1";
    },
    onLeave: function() {
      const tooltip = document.getElementById(legendTooltipId) as HTMLElement;
      tooltip.style.display = "none";
    },
    onClick:function(event:any){
      event.stopPropagation();
    }
  };
}

export const getRotation=(isRotation: boolean): {minRotation:number, maxRotation:number}=> {
  const value = isRotation ? 45 : 0;
  return { minRotation: value, maxRotation: value };
}
