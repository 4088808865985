import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
let wToolTip = class wToolTip extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "placement", void 0);
    _defineProperty(this, "title", void 0);
    _defineProperty(this, "width", void 0);
    _defineProperty(this, "text", void 0);
  }
};
__decorate([Prop({
  default: 'top-start'
})], wToolTip.prototype, "placement", void 0);
__decorate([Prop({
  default: ''
})], wToolTip.prototype, "title", void 0);
__decorate([Prop({
  default: 200
})], wToolTip.prototype, "width", void 0);
__decorate([Prop({
  required: true
})], wToolTip.prototype, "text", void 0);
wToolTip = __decorate([Component({})], wToolTip);
export default wToolTip;