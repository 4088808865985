import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-c38ff674"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "notificationIcon"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_icon, {
    icon: "bell-outline",
    size: "default",
    color: "dark"
  }), _createVNode(_Transition, {
    name: "zoom"
  }, {
    default: _withCtx(() => [_withDirectives(_createElementVNode("div", {
      class: "notificationNumber"
    }, _toDisplayString(_ctx.notificationNumberDisplay), 513), [[_vShow, _ctx.notificationNumber > 0]])]),
    _: 1
  })]);
}