import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
let WCheckboxGroup = class WCheckboxGroup extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "alreadyCheckedItems", void 0);
    _defineProperty(this, "items", void 0);
    _defineProperty(this, "valueKey", void 0);
    _defineProperty(this, "labelKey", void 0);
    _defineProperty(this, "checkedItems", []);
  }
  mounted() {
    this.checkedItems = this.alreadyCheckedItems;
  }
  onChangeOneItem(changed, label, value) {
    this.$emit('checkedOneItem', changed, label, value);
  }
  onChangeItems() {
    this.$emit('checkedItems', this.checkedItems);
  }
  onChangePropValue() {
    this.checkedItems = this.alreadyCheckedItems;
  }
};
__decorate([Prop({
  default: []
})], WCheckboxGroup.prototype, "alreadyCheckedItems", void 0);
__decorate([Prop({
  default: []
})], WCheckboxGroup.prototype, "items", void 0);
__decorate([Prop({
  default: "id"
})], WCheckboxGroup.prototype, "valueKey", void 0);
__decorate([Prop({
  default: "name"
})], WCheckboxGroup.prototype, "labelKey", void 0);
__decorate([Watch('checkedItems')], WCheckboxGroup.prototype, "onChangeItems", null);
__decorate([Watch('alreadyCheckedItems')], WCheckboxGroup.prototype, "onChangePropValue", null);
WCheckboxGroup = __decorate([Component({
  emits: ['checkedItems', 'checkedOneItem']
})], WCheckboxGroup);
export default WCheckboxGroup;