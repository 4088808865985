import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import axios from "axios";
import { Component, Vue } from "vue-facing-decorator";
let AccessibilityStatementVue = class AccessibilityStatementVue extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "accessibilityData", null);
  }
  mounted() {
    const iframe = document.getElementById("accessibility-statement-iframe");
    if (iframe) {
      axios.get('/api/v2/accessibility-data').then(({
        data
      }) => {
        this.accessibilityData = data;
        if (this.accessibilityData) {
          const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
          iframeDoc.body.innerHTML = this.accessibilityData.statement;
          // Adjusting the iframe height onload event
          iframe.style.height = iframeDoc.body.scrollHeight + 16 + 'px';
          // Apply Speach font
          iframeDoc.body.innerHTML += "<style> body { font-family: 'Nunito Sans', sans-serif, Avenir, Helvetica, Arial sans-serif;} </style>";
        }
      });
    }
  }
};
AccessibilityStatementVue = __decorate([Component({})], AccessibilityStatementVue);
export default AccessibilityStatementVue;