import { __decorate } from "tslib";
import Icon from "@/components/icon/Icon.vue";
import WToolTip from "@/components/tooltip/w-toolTip.vue";
import { Component, Vue } from "vue-facing-decorator";
let NotificationHeaderList = class NotificationHeaderList extends Vue {
  get settingsLabel() {
    return this.$t("notification.settings");
  }
  get notificationTitle() {
    return this.$t("notification.rubrique.title");
  }
  openParameters() {
    this.$emit("openParameters");
  }
};
NotificationHeaderList = __decorate([Component({
  components: {
    WToolTip,
    Icon
  }
})], NotificationHeaderList);
export default NotificationHeaderList;