import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-3648748e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "content"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _ctx$nameValues, _ctx$nameValues2, _ctx$nameValues3;
  const _component_dash_board_doughnut_chart = _resolveComponent("dash-board-doughnut-chart");
  const _component_empty_chart = _resolveComponent("empty-chart");
  return _openBlock(), _createElementBlock("div", null, [_withDirectives(_createElementVNode("div", _hoisted_1, [_withDirectives(_createVNode(_component_dash_board_doughnut_chart, {
    "name-values": _ctx.nameValues,
    title: _ctx.title,
    "label-tooltip": _ctx.labelTooltip,
    "label-tooltip-plural": _ctx.labelTooltipPlural
  }, null, 8, ["name-values", "title", "label-tooltip", "label-tooltip-plural"]), [[_vShow, (_ctx$nameValues = _ctx.nameValues) === null || _ctx$nameValues === void 0 ? void 0 : _ctx$nameValues.length]])], 512), [[_vShow, (_ctx$nameValues2 = _ctx.nameValues) === null || _ctx$nameValues2 === void 0 ? void 0 : _ctx$nameValues2.length]]), _withDirectives(_createVNode(_component_empty_chart, {
    loading: _ctx.loading,
    url: _ctx.big ? require('/public/images/charts/Arc-Large.png') : require('/public/images/charts/Arc-Small.png')
  }, null, 8, ["loading", "url"]), [[_vShow, !((_ctx$nameValues3 = _ctx.nameValues) !== null && _ctx$nameValues3 !== void 0 && _ctx$nameValues3.length)]])]);
}