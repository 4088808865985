import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-52abc860"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "dueDateSettings"
};
const _hoisted_2 = {
  class: "dueDateHeader"
};
const _hoisted_3 = {
  class: "dueDateHTitle"
};
const _hoisted_4 = {
  class: "dueDateHInfo"
};
const _hoisted_5 = {
  key: 0,
  class: "dueDateTips"
};
const _hoisted_6 = {
  class: "dueDateTipsContent"
};
const _hoisted_7 = {
  class: "dueDateContent"
};
const _hoisted_8 = {
  key: 0,
  class: "dueDateForm"
};
const _hoisted_9 = {
  class: "dueDateFormDate"
};
const _hoisted_10 = {
  key: 0,
  class: "dueDateFormAlert"
};
const _hoisted_11 = {
  class: "dueDateFormUnpublish"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon");
  const _component_w_date_picker = _resolveComponent("w-date-picker");
  const _component_w_toggle = _resolveComponent("w-toggle");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('speach.metadata.dueDate.title')), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('speach.metadata.dueDate.info')), 1)]), !_ctx.badgeSet ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_icon, {
    icon: "alert-outline",
    class: "dueDateTipsIconAlert"
  }), _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('speach.metadata.dueDate.tips.content')), 1)])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_7, [_ctx.loaded ? (_openBlock(), _createElementBlock("div", _hoisted_8, [_createElementVNode("div", _hoisted_9, [_createVNode(_component_w_date_picker, {
    disabled: !_ctx.badgeSet,
    class: "dueDateFormDateInput",
    placeholder: _ctx.$t('speach.metadata.dueDate.placeholder'),
    calendar: _ctx.dueDateSet,
    onChangeCalendar: _ctx.changeDueDate,
    separator: "-",
    placement: "bottom-end"
  }, null, 8, ["disabled", "placeholder", "calendar", "onChangeCalendar"]), _ctx.isDueDateInThePast ? (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t('speach.metadata.dueDate.alertDueDate')), 1)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_11, [_createVNode(_component_w_toggle, {
    disabled: !_ctx.badgeSet,
    value: _ctx.speach.unpublishAfterDueDate,
    onChange: _ctx.changeUnpublishAfterDueDate
  }, null, 8, ["disabled", "value", "onChange"]), _createTextVNode(" " + _toDisplayString(_ctx.$t('speach.metadata.dueDate.unpublish')), 1)])])) : _createCommentVNode("", true)])]);
}