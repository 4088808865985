import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_icon = _resolveComponent("icon");
  const _component_WToolTip = _resolveComponent("WToolTip");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_WToolTip, {
    text: _ctx.bookmarkedState ? _ctx.$t('thumbnail.speach.delete.bookmark') : _ctx.$t('thumbnail.speach.bookmark'),
    width: 100
  }, {
    default: _withCtx(() => [_createVNode(_component_icon, {
      class: "bookmarkButton",
      icon: _ctx.bookmarkedState ? 'bookmark' : 'bookmark-outline',
      onClick: _withModifiers(_ctx.toggleBookmarkState, ["stop"])
    }, null, 8, ["icon", "onClick"])]),
    _: 1
  }, 8, ["text"])]);
}