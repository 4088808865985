import { __decorate } from "tslib";
import PdpAgreementScreenForm from "@/components/pdp/PdpAgreementScreenForm.vue";
import { Component, Vue } from "vue-facing-decorator";
let PdpAgreementScreen = class PdpAgreementScreen extends Vue {
  userAccept() {
    window.location.assign("/pdp/acceptPdp");
  }
};
PdpAgreementScreen = __decorate([Component({
  components: {
    PdpAgreementScreenForm
  }
})], PdpAgreementScreen);
export default PdpAgreementScreen;