import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_date_picker = _resolveComponent("el-date-picker");
  return _openBlock(), _createBlock(_component_el_date_picker, {
    modelValue: _ctx.calRange,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.calRange = $event),
    class: "inputDatePickerWidth",
    type: "daterange",
    "unlink-panels": "",
    "range-separator": _ctx.separator,
    "start-placeholder": _ctx.$t('datePicker.since'),
    "end-placeholder": _ctx.$t('datePicker.until'),
    shortcuts: _ctx.defaultRanges,
    onChange: _ctx.onChangeCalendarRange,
    format: _ctx.dateFormat,
    "popper-options": {
      placement: _ctx.placement
    }
  }, null, 8, ["modelValue", "range-separator", "start-placeholder", "end-placeholder", "shortcuts", "onChange", "format", "popper-options"]);
}