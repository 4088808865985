import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_image = _resolveComponent("el-image");
  return _openBlock(), _createBlock(_component_el_image, {
    src: _ctx.src,
    fit: _ctx.fit,
    "hide-on-click-modal": _ctx.hideOnClickModal,
    loading: _ctx.loading,
    lazy: _ctx.lazy,
    alt: _ctx.alt
  }, null, 8, ["src", "fit", "hide-on-click-modal", "loading", "lazy", "alt"]);
}