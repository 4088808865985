import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import axios from "axios";
import WToggle from "@/components/form/w-toggle.vue";
import { Component, Prop, Vue } from "vue-facing-decorator";
let NotificationParametersTypeNotification = class NotificationParametersTypeNotification extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "keyLabel", void 0);
    _defineProperty(this, "active", void 0);
    _defineProperty(this, "type", void 0);
  }
  onChangeToggle(value) {
    if (value) {
      this.activate();
    } else {
      this.desactivate();
    }
  }
  activate() {
    axios.get("/api/v2/notifications/parameters/activate", {
      params: {
        dataType: "json",
        type: this.type
      }
    });
  }
  desactivate() {
    axios.get("/api/v2/notifications/parameters/desactivate", {
      params: {
        dataType: "json",
        type: this.type
      }
    });
  }
};
__decorate([Prop({
  required: true
})], NotificationParametersTypeNotification.prototype, "keyLabel", void 0);
__decorate([Prop({
  required: true
})], NotificationParametersTypeNotification.prototype, "active", void 0);
__decorate([Prop({
  required: true
})], NotificationParametersTypeNotification.prototype, "type", void 0);
NotificationParametersTypeNotification = __decorate([Component({
  components: {
    WToggle
  }
})], NotificationParametersTypeNotification);
export default NotificationParametersTypeNotification;