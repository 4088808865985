import {User} from "@/models/user.model";
import {UserInfos} from "@/models/speach.model";

export const userToUserInfos=(user: User): UserInfos=>{
  return {
    id: user.id, // unused fake data
    email: user.email,
    label: user.firstName + ' ' + user.lastName,
    imgUrl: user.img,
  };
}
