import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
import WToolTip from "@/components/tooltip/w-toolTip.vue";
import Rating from "@/components/rating/Rating.vue";
import EmptyChart from "@/components/dashboard/charts/EmptyChart.vue";
let DashboardTable = class DashboardTable extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "params", void 0);
    _defineProperty(this, "dataTable", void 0);
    _defineProperty(this, "big", void 0);
    _defineProperty(this, "loading", void 0);
  }
};
__decorate([Prop({
  required: true
})], DashboardTable.prototype, "params", void 0);
__decorate([Prop({
  required: true
})], DashboardTable.prototype, "dataTable", void 0);
__decorate([Prop({
  default: false
})], DashboardTable.prototype, "big", void 0);
__decorate([Prop({
  default: false
})], DashboardTable.prototype, "loading", void 0);
DashboardTable = __decorate([Component({
  components: {
    EmptyChart,
    Rating,
    WToolTip
  }
})], DashboardTable);
export default DashboardTable;