import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createBlock as _createBlock, withDirectives as _withDirectives, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _directive_infinite_scroll = _resolveDirective("infinite-scroll");
  return _openBlock(), _createBlock(_component_el_select, {
    class: "selectFullWidth selectWithoutTags",
    modelValue: _ctx.selectedUsers,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.selectedUsers = $event),
    "value-key": "userID",
    multiple: "",
    filterable: "",
    remote: "",
    "reserve-keyword": "",
    "fit-input-width": "",
    placeholder: "Select one or more users",
    "remote-method": _ctx.searchUsers,
    onChange: _ctx.pushChangedValues
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, user => {
      return _withDirectives((_openBlock(), _createBlock(_component_el_option, {
        key: user.userID,
        label: user.firstName + ' ' + user.lastName,
        value: user
      }, null, 8, ["label", "value"])), [[_directive_infinite_scroll, _ctx.throttledLoadMoreUsers]]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue", "remote-method", "onChange"]);
}