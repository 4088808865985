import axios from "axios";
import { formatDate } from "./stringUtils";
import dayjs from "dayjs";
import {
  NameValue,
  SpeachMakerNote, SpeachMakerValue,
  SpeachMakerWatch,
  SpeachRating, SpeachValue,
  SpeachWatch,
  TimeSerie
} from "@/models/dashboard.model";

const DASHBOARD_API="/api/v2/dashboard"
const USER_API="/api/v2/users"
const COURS_API="/api/v2/cours"
const WORKFLOW_API="/api/v2/workflow"
const COMMENT_API="/api/v2/comments"


const COUNT_USER=`${USER_API}/count`

export function getTimeSerie( metric:string, period:string, cumulative:boolean):Promise<TimeSerie[]>{
  return axios.get<TimeSerie[]>(`${DASHBOARD_API}/time-series`, {
    params:{
      metric,
      period,
      cumulative
    }
  }).then(res=>res.data).catch();
}

export function getSpeachMakerWatch( period:string):Promise<SpeachMakerWatch[]>{
  return axios.get<SpeachMakerWatch[]>(`${DASHBOARD_API}/speachmakers-by-watch`, {
    params: {
      period
    }
  }).then(res=>res.data).catch();
}

export function getTopSpeachMakerData<T extends SpeachMakerValue>(list: T[]):T[] {
  return list.map((elt:T):T => ({
    ...elt,
    speachmaker: `${elt.firstName} ${elt.lastName}`
  }));

}

export function getSpeachWatch( period:string):Promise<SpeachWatch[]>{
  return axios.get<SpeachWatch[]>(`${DASHBOARD_API}/speaches-by-watch`, {
    params: {
      period
    }
  }).then(res=>res.data).catch();
}

export function getTopSpeachData<T extends SpeachValue>(list: T[]):T[] {
  return list
      .map((data:T):T => {
        return {
          ...data,
          publishDateFormatted: formatDate(dayjs(data.publishDate).toDate())
        };
      });

}

export function getSpeachRating( period:string):Promise<SpeachRating[]>{
  return axios.get<SpeachRating[]>(`${DASHBOARD_API}/speaches-by-rating`, {
    params: {
      period
    }
  }).then(res=>res.data).catch();
}

export function getSpeachMakerNote( period:string):Promise<SpeachMakerNote[]>{
  return axios.get<SpeachMakerNote[]>(`${DASHBOARD_API}/speachmakers-by-rating`, {
    params: {
      period
    }
  }).then(res=>res.data).catch();
}

export function getSpeachesCategory(period:string):Promise<NameValue[]>{
  return axios.get<NameValue[]>(`${DASHBOARD_API}/speach-category`, {
    params:{
      period
    }
  }).then(res=>res.data).catch();
}

export function getPublishedSpeachs():Promise<number> {
  return axios.get<number>(`${COURS_API}/publishedSpeachs`).then(res=>res.data).catch();
}
export function getUnpublishedSpeachs():Promise<number> {
  return axios.get<number>(`${COURS_API}/unpublishedSpeachs`).then(res=>res.data).catch();
}
export function getViewedSpeachs():Promise<number> {
  return axios.get<number>(`${COURS_API}/viewedSpeachs`).then(res=>res.data).catch();
}

export function getUsers():Promise<number> {
  return axios.get<{ size:number }>(COUNT_USER,
  {
    params:{
      type:"users"
    }
  }).then(res=>res.data.size).catch();
}

export function getAdmins():Promise<number> {
  return axios.get<{ size:number }>(COUNT_USER,
      {
        params:{
          type:"admins"
        }
      }).then(res=>res.data.size).catch();
}

export function getSpeachMakers():Promise<number> {
  return axios.get<{ size:number }>(COUNT_USER,
      {
        params:{
          type:"speachmakers"
        }
      }).then(res=>res.data.size).catch();
}
export function isCommentsEnabled():Promise<boolean> {
  return axios.get<boolean>(`${COMMENT_API}/enabled`).then(res=>res.data).catch();
}

export function getApprovers():Promise<number> {
  return axios.get<any>(COUNT_USER,
      {
        params:{
          type:"approvers"
        }
      }).then(res=>res.data.size).catch();
}

export function isWorkflowEnable():Promise<boolean> {
  return axios.get<any>(`${WORKFLOW_API}/enable`).then(res=>res.data).catch();
}
