import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_doughnut_chart = _resolveComponent("doughnut-chart");
  return _ctx.loading ? (_openBlock(), _createBlock(_component_doughnut_chart, {
    key: 0,
    class: "content",
    ref: "doughnutchart",
    chartData: _ctx.dataGraph,
    options: _ctx.options,
    plugins: _ctx.plugins
  }, null, 8, ["chartData", "options", "plugins"])) : _createCommentVNode("", true);
}