import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-facing-decorator";
let WText = class WText extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "dataTest", void 0);
  }
};
__decorate([Prop()], WText.prototype, "dataTest", void 0);
WText = __decorate([Component({})], WText);
export default WText;