import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
let WInput = class WInput extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "icon", void 0);
    _defineProperty(this, "size", void 0);
    _defineProperty(this, "enabled", void 0);
    _defineProperty(this, "placeholder", void 0);
    _defineProperty(this, "clearable", void 0);
    _defineProperty(this, "value", void 0);
    _defineProperty(this, "required", void 0);
    _defineProperty(this, "type", void 0);
    // can be text, textarea or other
    _defineProperty(this, "maxlength", void 0);
    _defineProperty(this, "showWordLimit", void 0);
    _defineProperty(this, "rows", void 0);
    _defineProperty(this, "noPrefix", void 0);
    _defineProperty(this, "dataTest", void 0);
    _defineProperty(this, "realValue", "");
  }
  mounted() {
    this.realValue = this.value;
  }
  onChange() {
    this.$emit('changeInput', this.realValue);
  }
  onChangeValue() {
    this.$emit('input', this.realValue);
  }
  onChangePropValue() {
    this.realValue = this.value;
  }
};
__decorate([Prop({
  default: ''
})], WInput.prototype, "icon", void 0);
__decorate([Prop({
  default: 'default'
})], WInput.prototype, "size", void 0);
__decorate([Prop({
  default: true
})], WInput.prototype, "enabled", void 0);
__decorate([Prop({
  default: ''
})], WInput.prototype, "placeholder", void 0);
__decorate([Prop({
  default: false
})], WInput.prototype, "clearable", void 0);
__decorate([Prop({
  default: ''
})], WInput.prototype, "value", void 0);
__decorate([Prop({
  default: false
})], WInput.prototype, "required", void 0);
__decorate([Prop({
  default: 'text'
})], WInput.prototype, "type", void 0);
__decorate([Prop({
  default: ''
})], WInput.prototype, "maxlength", void 0);
__decorate([Prop({
  default: false
})], WInput.prototype, "showWordLimit", void 0);
__decorate([Prop({
  default: 2
})], WInput.prototype, "rows", void 0);
__decorate([Prop({
  default: false
})], WInput.prototype, "noPrefix", void 0);
__decorate([Prop()], WInput.prototype, "dataTest", void 0);
__decorate([Watch('realValue')], WInput.prototype, "onChangeValue", null);
__decorate([Watch('value')], WInput.prototype, "onChangePropValue", null);
WInput = __decorate([Component({
  emits: ['changeInput', 'input']
})], WInput);
export default WInput;