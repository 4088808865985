import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-df2cbfb6"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "alignRight"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_w_link = _resolveComponent("w-link");
  const _component_el_table_column = _resolveComponent("el-table-column");
  const _component_icon_button = _resolveComponent("icon-button");
  const _component_el_table = _resolveComponent("el-table");
  const _component_w_scrollbar = _resolveComponent("w-scrollbar");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_w_link, {
    onClick: _cache[0] || (_cache[0] = $event => this.downloadAll()),
    type: "primary",
    class: "linkPrimary"
  }, {
    default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('admin.dashboard.download.exportAll')), 1)]),
    _: 1
  })]), _createVNode(_component_w_scrollbar, {
    "max-height": _ctx.maxScroll
  }, {
    default: _withCtx(() => [_createVNode(_component_el_table, {
      data: _ctx.listTemplates,
      "highlight-current-row": true,
      "show-header": false
    }, {
      default: _withCtx(() => [_createVNode(_component_el_table_column, null, {
        default: _withCtx(template => [_createTextVNode(_toDisplayString(this.labelTemplate(template.row)), 1)]),
        _: 1
      }), _createVNode(_component_el_table_column, {
        width: "52px"
      }, {
        default: _withCtx(scope => [_createVNode(_component_icon_button, {
          class: "buttonNavigation",
          icon: "download-outline",
          color: "black",
          onClick: $event => _ctx.download(scope.row)
        }, null, 8, ["onClick"])]),
        _: 1
      })]),
      _: 1
    }, 8, ["data"])]),
    _: 1
  }, 8, ["max-height"])], 64);
}