import {Speach} from "@/models/speach.model";
import {Language} from "@/models/language.model";

export class SpeachSearchResultModel {
    public speachesFound:Speach[]= [];
    public totalNumberOfSpeachesFound:number=0;
    public currentSpeachPage:number= 0;
    public availableLanguages:Language[]= [];
    public sort:SEARCH_SORT=SEARCH_SORT.RELEVANT;
    public langFilter:string| null=null;
}

export enum SEARCH_SORT{
    RELEVANT="RELEVANT",
    RECENT="RECENT",
    TITRE="TITRE"
}