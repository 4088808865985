import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_date_picker = _resolveComponent("el-date-picker");
  return _openBlock(), _createBlock(_component_el_date_picker, {
    modelValue: _ctx.cal,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => _ctx.cal = $event),
    class: "inputDatePickerWidth",
    "unlink-panels": "",
    "range-separator": _ctx.separator,
    format: _ctx.dateFormat,
    onChange: _ctx.onChangeCalendar,
    "popper-options": {
      placement: _ctx.placement
    }
  }, null, 8, ["modelValue", "range-separator", "format", "onChange", "popper-options"]);
}