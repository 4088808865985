import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import Tile from "@/components/dashboard/tiles/Tile.vue";
import ContentTile from "@/components/dashboard/tiles/ContentTile.vue";
import { getPublishedSpeachs, getUnpublishedSpeachs, getViewedSpeachs } from "@/util/dashboardUtils";
import { Component, Vue } from "vue-facing-decorator";
let DashboardSpeachsTiles = class DashboardSpeachsTiles extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "publishedSpeachs", 0);
    _defineProperty(this, "unpublishedSpeachs", 0);
    _defineProperty(this, "viewedSpeachs", 0);
  }
  mounted() {
    getPublishedSpeachs().then(res => this.publishedSpeachs = res);
    getUnpublishedSpeachs().then(res => this.unpublishedSpeachs = res);
    getViewedSpeachs().then(res => this.viewedSpeachs = res);
  }
};
DashboardSpeachsTiles = __decorate([Component({
  components: {
    ContentTile,
    Tile
  }
})], DashboardSpeachsTiles);
export default DashboardSpeachsTiles;