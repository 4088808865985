import _defineProperty from "/builds/speachme/speachmeDesktop/speach-front/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { __decorate } from "tslib";
import { Component, Vue } from "vue-facing-decorator";
import StandardButton from "@/components/buttons/StandardButton.vue";
import GcuDisplay from "@/components/gcu/GcuDisplay.vue";
let GcuAgreementScreenForm = class GcuAgreementScreenForm extends Vue {
  constructor(...args) {
    super(...args);
    _defineProperty(this, "cguEntirelyScrolled", false);
  }
  handleScroll() {
    const gcuContainer = this.$refs.gcuContainer;
    if (gcuContainer.offsetHeight + gcuContainer.scrollTop >= gcuContainer.scrollHeight) {
      this.cguEntirelyScrolled = true;
    }
  }
  clicked() {
    if (this.cguEntirelyScrolled) {
      this.$emit("accept");
    }
  }
};
GcuAgreementScreenForm = __decorate([Component({
  components: {
    GcuDisplay,
    StandardButton
  }
})], GcuAgreementScreenForm);
export default GcuAgreementScreenForm;