import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-712a03dc"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = ["id"];
const _hoisted_3 = {
  key: 0,
  class: "titre"
};
const _hoisted_4 = {
  key: 1,
  class: "sous-titre"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
    id: _ctx.idDivBackground !== '' ? _ctx.idDivBackground : null,
    style: _normalizeStyle({
      'background-image': 'url(' + _ctx.imageUrl + ')',
      'width': _ctx.width,
      'height': _ctx.height,
      'border-radius': _ctx.radius,
      'background-size': _ctx.backgroundSize,
      'margin-top': _ctx.marginTop,
      'background-repeat': 'no-repeat',
      'margin-bottom': _ctx.titre !== '' && _ctx.sousTitre !== '' ? '24px' : 'unset'
    })
  }, null, 12, _hoisted_2), _ctx.titre !== '' ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.titre), 1)) : _createCommentVNode("", true), _ctx.sousTitre !== '' ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.sousTitre), 1)) : _createCommentVNode("", true)]);
}